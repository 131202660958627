import { FormEvent, Key, useEffect, useRef, useState } from "react";
import styles from "./Organization.module.scss";
import TreeStructure from "./TreeView";
import {
	IxButton,
	IxDivider,
	IxDrawer,
	IxIcon,
	IxSelect,
	IxSelectItem,
	IxUpload,
	showModal,
	showToast,
	IxIconButton,
	IxTooltip,
} from "@siemens/ix-react";
import { ToastMessage, ToastType } from "../../Config/constants/ToastMessage";
import ConfirmModal from "../../Shared/ConfirmModal/ConfirmModal";
import { useToastPosition } from "../../hooks/useToastPosition";
import { useInject } from "../../hooks/useInject";
import PlantService from "../../Services/PlantService";
import { setOrgTree, setSelectedPlantId } from "../../redux/slices/plantSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { OrganizationInfoInterface, PlantInterface } from "../../Config/interfaces/PlantInterface";
import { useNavigate } from "react-router-dom";
import PlantKpiService from "../../Services/PlantKpiService";
import BreadCrumb from "../../Shared/Breadcrumb/BreadCrumb";
import useSetUserAssignedPlants from "../../hooks/useSetUserAssignedPlants";
import { setDashboardNavigatedThrough } from "../../redux/slices/navigationSlice";
import { NavigationEnum } from "../../Config/enums/NavigationEnum";
import useResetPlantKpisData from "../../hooks/useResetKpiData";
import { PlantType } from "../../Config/enums/PlantEnum";
import { ManagementType, OrgManagementActions, PlantManagementActions } from "../../Config/enums/RolesEnum";
const createNewOrgText: string = "Create organization";
const subOrgFormMessage: string = "Set up a hierarchy for your org to control user access to STP plant";

const Organization = () => {
	const navigate = useNavigate();
	const plantService = useInject(PlantService);
	const orgTree = useAppSelector((state) => state.plantSlice.orgTree);
	const [orgDrawerShow, setOrgDrawerShow] = useState(false); //add org form
	const [openOrgDiv, setOpenOrgDiv] = useState(true); //tree view
	const [openOrgInit, setOpenOrgInit] = useState(true); //initial add screen
	const [createSubOrgDrawerShow, setCreateSubOrgDrawerShow] = useState(false); //add sub org form
	const [editSubOrgDrawerShow, setEditSubOrgDrawerShow] = useState(false); //edit sub org form
	const [addPlantDrawerShow, setAddPlantDrawerShow] = useState(false); //add sub plant form
	const [editPlantDrawerShow, setEditPlantDrawerShow] = useState(false); // edit plant data
	const [file, setFile] = useState("");
	const [treeNodeId, setTreeNodeId] = useState(0);
	let subOrgIdToBeDeleted: any = useRef(null);
	const allOrgData: any = useRef([]);
	const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfoInterface>({
		isPlant: false,
		icon: "hierarchy",
		id: 0,
		isActive: true,
		isDisabled: false,
		location: "",
		plantParent: "",
		subOrgName: "",
		totalPlants: 0,
		totalSubOrgs: 0,
	});
	const [isOrg, setIsOrg] = useState(false);
	const [isSubOrg, setIsSubOrg] = useState(false);
	const [isPlant, setIsPlant] = useState(false);
	const [values, setValues] = useState({
		organization: "",
	});
	const [subOrgValues, setSubOrgValues] = useState({
		OrgId: 1,
		SubOrgName: "",
		ParentId: 0,
		SuborgId: 0,
	});
	const [orgId, setOrgId] = useState(0);
	const [suborgId, setSuborgId] = useState("1");
	const [orgValue, setOrgValue] = useState([{ OrgName: "BWSSB" }]);
	const [plantValues, setPlantValues] = useState<PlantInterface[]>([
		{
			SuborgId: "1",
			OrgId: 1,
			SubOrgName: "",
			ParentId: treeNodeId,
			IsPlant: true,
			Location: "",
			PlantType: PlantType.STP,
			Capacity: 0,
			Group: "",
			Latitude: 0,
			Longitude: 0,
			Status: true,
			IsActive: false,
		},
	]);
	const dispatch = useAppDispatch();

	useToastPosition("top-right");
	const plantKpiService = useInject(PlantKpiService);
	const userAssignedPlants = useAppSelector((state) => state.keycloakSlice.userAssignedPlants);
	const [selectionPlantKPIs, setSelectionPlantKPIs] = useState(0);
	const [userHasAddOrgPerm, setUserHasAddOrgPerm] = useState(false);
	const [userHasViewOrgPerm, setUserHasViewOrgPerm] = useState(false);
	const [userHasDelOrgPerm, setUserHasDelOrgPerm] = useState(false);
	const [userHasAddPlantPerm, setUserHasAddPlantPerm] = useState(false);
	const [userHasDelPlantPerm, setUserHasDelPlantPerm] = useState(false);
	const loggedInUserPermission = useAppSelector(state => state.roleSlice.loggedInUserPermissions);

	useSetUserAssignedPlants();
	useResetPlantKpisData();

	useEffect(() => {
		setIsOrg(false);
		setIsPlant(false);
		setIsSubOrg(false);

		if (organizationInfo) {
			switch (organizationInfo.icon) {
				case "plant":
					updateOrganizationInfo(organizationInfo, false, true, false);
					getPlantKpis(organizationInfo.id);
					break;
				case "location":
					updateOrganizationInfo(organizationInfo, false, false, true);
					break;
				case "hierarchy":
					updateOrganizationInfo(organizationInfo, true, false, false);
					break;
				default:
					break;
			}

			if (orgTree.length) {
				const orgHierarchyResponse = getSelectedOrgHierarchy(orgTree, organizationInfo);
				organizationInfo.totalPlants = orgHierarchyResponse.plants || 0;
				organizationInfo.totalSubOrgs = orgHierarchyResponse.subOrgs;
				organizationInfo.plantParent = orgHierarchyResponse.parentPlant;
			}
		}
	}, [organizationInfo]);

	useEffect(() => {
		plantService
			.getOrganization()
			.then((res: any) => {
				setOrgValue(res);
				if (res.length > 0) {
					setOrgId(res[0].Id);
					setSuborgId(res[0]?.SuborgId);
				}
			})
			.catch((err: any) => {
				console.log("Could not retrieve messages", err);
			});
	}, [openOrgInit]);

	useEffect(() => {
		allOrgData.current = orgTree;
	}, [orgTree]);

	useEffect(() => {
		getRolesPermissions();
	}, [loggedInUserPermission]);

	const updateOrganizationInfo = (info: OrganizationInfoInterface, ...[isOrg, isPlant, isSubOrg]: boolean[]) => {
		info.isPlant = isPlant;
		setIsOrg(isOrg);
		setIsPlant(isPlant);
		setIsSubOrg(isSubOrg);
	};

	async function getRolesPermissions() {
		if (Object.keys(loggedInUserPermission).length) {
			setUserHasAddOrgPerm(loggedInUserPermission[ManagementType.Org][OrgManagementActions.Add]);
			setUserHasViewOrgPerm(loggedInUserPermission[ManagementType.Org][OrgManagementActions.View]);
			setUserHasDelOrgPerm(loggedInUserPermission[ManagementType.Org][OrgManagementActions.Del]);
			setUserHasAddPlantPerm(loggedInUserPermission[ManagementType.Plant][PlantManagementActions.Add]);
			setUserHasDelPlantPerm(loggedInUserPermission[ManagementType.Plant][PlantManagementActions.Del]);
		} else {
			return;
		}
	}

	const getSelectedOrgHierarchy = (array: PlantInterface[], organizationInfo: OrganizationInfoInterface) => {
		let OrgId = organizationInfo.id;
		const response = {
			OrgName: "",
			subOrgs: 0,
			plants: 0,
			parentPlantId: 0,
			parentPlant: "",
		};

		const orgs = array.filter((item: any) => !item.IsPlant);
		const plants: any = array.filter((item: any) => item.IsPlant);
		const subOrgGroups = plants.reduce((plant: any, object: any) => {
			const parentId = object.ParentId;
			if (!plant[parentId]) {
				plant[parentId] = [];
			}
			plant[parentId].push(object);
			return plant;
		}, {});

		if (OrgId === 0) {
			response.OrgName = array.filter((item: any) => item.SuborgId === OrgId)?.[0]?.SubOrgName;
			response.subOrgs = orgs.length;
			response.plants = plants.length;
		} else {
			response.OrgName = orgs.filter((item: any) => item.SuborgId === OrgId)?.[0]?.SubOrgName;
			response.subOrgs = orgs.filter((item: any) => item.ParentId === OrgId)?.length;
			response.parentPlantId = plants.filter((item: any) => item.SuborgId === OrgId)?.[0]?.ParentId;
			response.parentPlant = orgs.filter(
				(item: any) => item.SuborgId === response.parentPlantId
			)?.[0]?.SubOrgName;

			response.plants = subOrgGroups?.[OrgId]?.length;
		}
		return response;
	};

	async function getPlantKpis(plantId: number): Promise<void> {
		if (!plantId || isNaN(plantId)) {
			return;
		}
		const response = await plantKpiService.getPlantKpi(plantId);
		setSelectionPlantKPIs(response.length);
	}

	const goToDashboard = () => {
		plantService.setPlantDetails(organizationInfo);
		dispatch(setSelectedPlantId(organizationInfo?.id));
		dispatch(setDashboardNavigatedThrough(NavigationEnum.organization));
		navigate("/dashboard");
	};

	function updateTotalSubOrgsAndPlantsCount(orgTree: PlantInterface[]) {
		organizationInfo.subOrgName = subOrgValues.SubOrgName;
		let plantsCount = 0, subOrgsCount = 0;
		orgTree.forEach((element: PlantInterface) => {
			const getCounts = organizationInfo.id === 0 || element.ParentId === subOrgValues.SuborgId;
			if (getCounts) {
				if (element.IsPlant) {
					plantsCount++;
				} else {
					subOrgsCount++;
				}
			}
		});
		organizationInfo.totalPlants = plantsCount;
		organizationInfo.totalSubOrgs = subOrgsCount;
	}

	function getOrganizationData(): void {
		plantService.getOrgHierarchy().then((orgTree: PlantInterface[]) => {
			if (orgTree?.length) {
				updateTotalSubOrgsAndPlantsCount(orgTree);
				dispatch(setOrgTree(orgTree));
				setOrganizationInfo(organizationInfo)
			} else {
				dispatch(setOrgTree([]));
			}
		});
	}

	const subOrgIdFromTree = (treeVal: any) => {
		setTreeNodeId(treeVal);
		const treeElement = structuredClone(getTreeElementFromId(treeVal));

		if (treeElement?.IsPlant) {
			setPlantValues([
				{
					...plantValues[0],
					...{
						OrgId: orgId,
						SubOrgName: treeElement.SubOrgName,
						ParentId: treeNodeId,
						Location: treeElement.Location,
						PlantType: treeElement.PlantType,
						Capacity: treeElement.Capacity,
						Latitude: treeElement.Latitude,
						Longitude: treeElement.Longitude,
					},
				},
			]);
		}

		setSubOrgValues({
			...subOrgValues,
			...{
				OrgId: orgId,
				SubOrgName: treeElement?.SubOrgName ?? "",
				SuborgId: treeElement?.SuborgId ?? 0,
			},
		});
	};

	const getTreeElementFromId = (treeVal: any) => {
		if (!allOrgData?.current) {
			return;
		}
		return allOrgData.current.find((el: any) => {
			return el.SuborgId === treeVal;
		});
	};

	const handleChange = (name: any) => (e: any) => {
		setValues({ ...values, [name]: e.target.value });

		setSubOrgValues({
			...subOrgValues,
			["OrgId"]: orgId,
			["ParentId"]: treeNodeId,
			[name]: e.target.value,
		});
	};

	//-----------------------plant form--------------------//
	const handleChangePlant = (i: any, e: any, fieldName = '') => {
		const value = typeof e === 'string' ? e : e.target.value;
		fieldName = fieldName || e.target.name;

		let newFormValues: any = [...plantValues];
		newFormValues[i]["OrgId"] = orgId;
		newFormValues[i]["SuborgId"] = treeNodeId;
		newFormValues[i][fieldName] = value;
		setPlantValues(newFormValues);
	};

	// TODO: used to add more plant
	let addFormFields = () => {
		setPlantValues([
			...plantValues,
			{
				SuborgId: suborgId,
				OrgId: orgId,
				SubOrgName: "",
				ParentId: treeNodeId,
				IsPlant: true,
				Location: "",
				PlantType: "",
				Capacity: 0,
				Group: "",
				Latitude: 0,
				Longitude: 0,
				Status: true,
				IsActive: false,
			},
		]);
	};

	let removeFormFields = (i: any) => {
		let newFormValues = [...plantValues];
		newFormValues.splice(i, 1);
		setPlantValues(newFormValues);
	};

	const notifyUser = (type: string, action: string, newSubOrgValue: string, Category: string) => {
		const title = type === ToastType.success ? `Successful` : type === ToastType.error ? 'Error' : "Warning";
		onSaveNotification({
			type,
			title,
			description:
				type === "success"
					? `${Category} "${newSubOrgValue}" ${action} successfully`
					: action == "Duplicate plant name"
						? "Plant name already exists"
						: "Something went wrong!",
		});
	};

	function onSaveNotification(notification: any) {
		showToast({
			type: notification.type,
			title: notification.title,
			message: notification.description,
		});
	}

	//---------------------sub org form ----------------------//
	const handleSubOrgSubmit = (event: any) => {
		event.preventDefault();
		const response = plantService.addSubOrganization(subOrgValues);
		if (response) {
			getOrganizationData();
			setCreateSubOrgDrawerShow(false);
			showToast({
				type: ToastType.success,
				message: ToastMessage.subOrgCreated,
			});
		} else {
			console.error("There was an error!");
			showToast({
				type: ToastType.error,
				message: `Sub Org "${subOrgValues.SubOrgName}" failed to add`,
			});
		}
	};

	const handleSubOrgEdit = async (event: any) => {
		event.preventDefault();
		const response = await plantService.editSubOrganization(subOrgValues);
		if (response) {
			getOrganizationData();
			clearPlantForm();
			setEditSubOrgDrawerShow(false);
			showToast({
				type: ToastType.success,
				message: ToastMessage.subOrgUpdated,
			});
		} else {
			console.error("There was an error!");
			showToast({
				type: ToastType.error,
				message: `Sub Org "${subOrgValues.SubOrgName}" failed to update`,
			});
		}
	};

	//---------------------plant form ----------------------//
	const handleSubmit = (event: any) => {
		event.preventDefault();

		let reqOptions = new FormData();
		reqOptions.append("OrgName", values.organization);
		reqOptions.append("Image", file);
		plantService
			.addOrganization(reqOptions)
			.then(() => {
				setOpenOrgDiv(true);
				setOpenOrgInit(false);
				setFile("");
				setOrgDrawerShow(false);
				showToast({
					type: ToastType.success,
					message: "Organization successfully added",
				}).then((r) => r);
			})
			.catch((error: string | undefined) => {
				console.error("There was an error!!", error);
				throw Error(error);
			});
	};

	//====== submit plants ===========
	const handlePlantSubmit = (event: FormEvent) => {
		event.preventDefault();
		const isValid = validatePlantFormFields();

		if (!isValid) {
			return;
		}

		plantService
			.addPlants(plantValues[0])
			.then(() => {
				getOrganizationData();
				clearPlantForm();
				setAddPlantDrawerShow(false);
				notifyUser(ToastType.success, "added", plantValues[0].SubOrgName, "Plant");
			})
			.catch((error: any) => {
				console.error("There was an error!", error);
				notifyUser(ToastType.error, "add", plantValues[0].SubOrgName, "Plant");
			});
	};

	const checkDuplicatePlantName = (isEdit: boolean = false) => {
		let duplicatePlant: any = false;
		orgTree.map((ele: any) => {
			if ((ele.SubOrgName == plantValues[0].SubOrgName) && (ele.SuborgId != plantValues[0].SuborgId) && isEdit) { // on edit
				duplicatePlant = true;
				notifyUser(ToastType.warning, "Duplicate plant name", plantValues[0].SubOrgName, "Plant");
			} else if ((ele.SubOrgName == plantValues[0].SubOrgName) && !isEdit) { // on add
				duplicatePlant = true;
				notifyUser(ToastType.warning, "Duplicate plant name", plantValues[0].SubOrgName, "Plant");
			}
		});

		return duplicatePlant;
	}

	function handleUpload(event: any) {
		setFile(event.detail[0]);
	}

	const clearOrgForm = () => {
		setFile("");
	};

	function clearSubOrgForm() {
		setSubOrgValues({ OrgId: 1, SubOrgName: "", ParentId: 0, SuborgId: 0 });
	}

	function clearPlantForm(): void {
		setPlantValues([
			{
				SuborgId: "1",
				OrgId: 1,
				SubOrgName: "",
				ParentId: treeNodeId,
				IsPlant: true,
				Location: "",
				PlantType: "",
				Capacity: 0,
				Group: "",
				Latitude: 77.88,
				Longitude: 13.8,
				Status: true,
				IsActive: false,
			},
		]);
	}

	const handleEditPlant = async (event: any) => {
		event.preventDefault();
		const isValid = validatePlantFormFields(true);

		if (!isValid) {
			return;
		}

		const response = await plantService.editPlant(plantValues[0]);
		if (response) {
			getOrganizationData();
			clearPlantForm();
			setEditPlantDrawerShow(false);
			showToast({
				type: ToastType.success,
				message: ToastMessage.plantsUpdated,
			});
		} else {
			console.error("There was an error during plant info edit!");
			showToast({
				type: ToastType.error,
				message: `Plant "${plantValues[0].SubOrgName}" failed to update`,
			});
		}
	};

	function validatePlantFormFields(isEdit: boolean = false) {
		let isValid = true;

		plantValues.map((element: any) => {
			if (element.SubOrgName) {
				(document.querySelector('#SubOrgName') as HTMLElement)?.focus();
				isValid = !checkDuplicatePlantName(isEdit);
			}

			if (element.SubOrgName === "" && element.Location === "" && element.Capacity === 0 && element.PlantType === "") {
				isValid = false;
				showToast({
					type: ToastType.error,
					message: "Please fill all the required fields",
				});
			} else if (!element.SubOrgName) {
				isValid = false;
				(document.querySelector('#SubOrgName') as HTMLElement)?.focus();
				showToast({
					type: ToastType.error,
					message: "Please fill STP plant name",
				});
			} else if (element.Location === "") {
				isValid = false;
				(document.querySelector('#Location') as HTMLElement)?.focus();
				showToast({
					type: ToastType.error,
					message: "Please fill Location",
				});
			} else if (element.Latitude === 0 && element.Longitude === 0) {
				isValid = false;
				(document.querySelector('#Latitude') as HTMLElement)?.focus();
				showToast({
					type: ToastType.error,
					message: "Please fill Latitude and Longitude",
				});
			} else if (element.Latitude < -90 || element.Latitude > 90 || element.Longitude < -180 || element.Longitude > 180) {
				isValid = false;
				(document.querySelector('#Latitude') as HTMLElement)?.focus();
				showToast({
					type: ToastType.error,
					message: "Latitude and Longitude should be in range. Latitude: -90 to 90, Longitude: -180 to 180",
				});
			} else if (element.Capacity <= 0) {
				isValid = false;
				(document.querySelector('#Capacity') as HTMLElement)?.focus();
				showToast({
					type: ToastType.error,
					message: "Capacity should be greater than 0",
				});
			}
		});
		return isValid;
	}

	async function showConfirmBox(subOrgId: number) {
		subOrgIdToBeDeleted = subOrgId;
		const confirmationText = `Are you sure?`;
		const confirmationSubText = `Do you really want to delete permanently?`;
		await showModal({
			title: "deleteConfirmationModal",
			content: (
				<ConfirmModal
					onAction={confirmationHandler}
					confirmationText={confirmationText}
					confirmationSubText={confirmationSubText}
				/>
			),
			icon: "warning-filled",
			iconColor: "color-warning",
			size: "lg",
		});
	}

	const confirmationHandler = (actionType: string) => {
		if (!subOrgIdToBeDeleted) {
			showToast({
				type: "error",
				title: "Error",
				message: "Something went wrong",
			});
			return;
		}
		if (actionType === "yes") {
			if (organizationInfo?.isPlant) {
				deletePlant(subOrgIdToBeDeleted);
			} else {
				deleteSubOrganization(subOrgIdToBeDeleted);
			}
		}
	};

	const deletePlant = (plantId: number) => {
		const response = plantService.deletePlant(plantId);
		if (response) {
			getOrganizationData();
			showToast({
				type: ToastType.success,
				message: ToastMessage.plantsDeleted,
			});
		} else {
			console.error("There was an error while deleting the plant!");
			showToast({
				type: ToastType.error,
				message: `Plant "${plantValues[0].SubOrgName}" failed to delete`,
			});
		}
	};

	const deleteSubOrganization = (subOrgId: number) => {
		const response = plantService.deleteSubOrganization(subOrgId);
		if (response) {
			getOrganizationData();
			showToast({
				type: ToastType.success,
				message: ToastMessage.plantsDeleted,
			});
		} else {
			console.error("There was an error while deleting the Sub-organization!");
			showToast({
				type: ToastType.error,
				message: `SubOrg "${plantValues[0].SubOrgName}" failed to delete`,
			});
		}
	};

	const addPlants = (subOrgId: any) => {
		//setChildExpandedInTreeContext(subOrgId);
		setAddPlantDrawerShow(true);
		subOrgIdFromTree(subOrgId);
	};

	const editPlant = (subOrgId: any) => {
		//setChildExpandedInTreeContext(subOrgId);
		setEditPlantDrawerShow(true);
		subOrgIdFromTree(subOrgId);
	};

	const addSubOrg = (subOrgId: any) => {
		//setChildExpandedInTreeContext(subOrgId);
		setCreateSubOrgDrawerShow(true);
		subOrgIdFromTree(subOrgId);
	};

	const editSubOrg = (subOrgId: any) => {
		//setChildExpandedInTreeContext(subOrgId);
		setEditSubOrgDrawerShow(true);
		subOrgIdFromTree(subOrgId);
	};

	const deleteSubOrgOrPlant = (subOrgId: number) => {
		//setChildExpandedInTreeContext(subOrgId);
		showConfirmBox(subOrgId);
	};

	const redirectToConfigureKpis = (subOrgId: any) => {
		if (!subOrgId || isNaN(+subOrgId)) {
			return;
		}
		navigate("/kpis/" + subOrgId);
	};
	/**
	 * Component to display thumbnail of image.
	 */
	const ImageThumb = ({ image }: any) => {
		return (
			<div className={`mt-2 uploaded_org_img`}>
				<img src={URL.createObjectURL(image)} alt={image.name} />
			</div>
		);
	};

	const orgForm = () => {
		return (
			<div className={`d-flex flex-column`}>
				<header className={`text-l-title mx-3 my-2`}>{createNewOrgText}</header>
				<IxDivider></IxDivider>
				<section className={`mx-3`}>
					<form onSubmit={handleSubmit}>
						<div className={`form-inputs-block`}>
							<div className={`text-l mt-2`}>Setup organization for one time</div>
							<div className={`mt-2`}>
								<label htmlFor='organization' className={`mb-1 text-default-title is_required`}>
									Organization
								</label>
								<input
									id='organization'
									type='text'
									name='organization'
									required
									placeholder='Please enter organization name'
									className={`form-control`}
									onChange={handleChange("organization")}
								/>
							</div>
							<div className={`mt-3`}>
								<IxUpload accept={"image/*"} onFilesChanged={handleUpload} />
								{file && <ImageThumb image={file} />}
							</div>
						</div>
						<div className={`m-2 py-2`}>
							<div className={`d-flex flex-row justify-content-center`}>
								<IxButton
									class='m-1'
									outline
									onClick={() => {
										clearOrgForm();
										setOrgDrawerShow(false);
									}}>
									Cancel
								</IxButton>
								<IxButton type='submit' class='m-1' variant='Primary'>
									Save
								</IxButton>
							</div>
						</div>
					</form>
				</section>
			</div>
		);
	};

	const subOrgForm = () => {
		return (
			<div className={`d-flex flex-column`}>
				<header className={`text-l-title mx-3 my-2`}>{"Create Sub-organization"}</header>
				<IxDivider></IxDivider>
				<section className={`mx-3 my-2`}>
					<form onSubmit={handleSubOrgSubmit}>
						<div className={`form-inputs-block`}>
							<p className={`text-s`}>{subOrgFormMessage}</p>
							<div className={`mt-3`}>
								<label htmlFor='org' className={`mb-1 text-default-title`}>
									Organization
								</label>
								<input
									id='org'
									type='text'
									name='org'
									placeholder='Enter'
									className={`form-control`}
									disabled={true}
									required
									value={`${orgValue?.[0]?.OrgName}`}
								/>
							</div>
							<div className={`ms-5 mt-3`}>
								<label htmlFor='suborg' className={`mb-1 text-default-title is_required`}>
									Sub Organization
								</label>
								<input
									id='suborg'
									type='text'
									name='suborg'
									placeholder='Enter'
									className={`form-control`}
									value={subOrgValues?.SubOrgName}
									required
									onChange={handleChange("SubOrgName")}
								/>
							</div>
						</div>
						<div className={`m-2 py-2`}>
							<div className={`d-flex flex-row justify-content-center`}>
								<IxButton
									class='m-1'
									outline
									onClick={() => {
										clearSubOrgForm();
										setCreateSubOrgDrawerShow(false);
									}}>
									Cancel
								</IxButton>
								<IxButton type='submit' class='m-1' variant='Primary'>
									Save
								</IxButton>
							</div>
						</div>
					</form>
				</section>
			</div>
		);
	};

	const editSubOrgForm = () => {
		return (
			<div className={`d-flex flex-column`}>
				<header className={`text-l-title mx-3 my-2`}>{"Edit Sub-organization"}</header>
				<IxDivider></IxDivider>
				<section className={`mx-3 my-2`}>
					<form onSubmit={handleSubOrgEdit}>
						<div className={`form-inputs-block`}>
							<p className={`text-s`}>{subOrgFormMessage}</p>
							<div className={`mt-3`}>
								<label htmlFor='org' className={`mb-1 text-default-title`}>
									Organization
								</label>
								<input
									id='org'
									type='text'
									name='org'
									placeholder='Enter'
									className={`form-control`}
									disabled={true}
									required
									value={`${orgValue?.[0]?.OrgName}`}
								/>
							</div>
							<div className={`ms-5 mt-3`}>
								<label htmlFor='suborg' className={`mb-1 text-default-title is_required`}>
									Sub Organization
								</label>
								<input
									id='suborg'
									type='text'
									name='suborg'
									placeholder='Enter'
									className={`form-control`}
									value={subOrgValues?.SubOrgName}
									required
									onChange={handleChange("SubOrgName")}
								/>
							</div>
						</div>
						<div className={`m-2 py-2`}>
							<div className={`d-flex flex-row justify-content-center`}>
								<IxButton
									class='m-1'
									outline
									onClick={() => {
										clearSubOrgForm();
										setEditSubOrgDrawerShow(false);
									}}>
									Cancel
								</IxButton>
								<IxButton type='submit' class='m-1' variant='Primary'>
									Save
								</IxButton>
							</div>
						</div>
					</form>
				</section>
			</div>
		);
	};

	const addPlantForm = () => {
		return (
			<div className={`d-flex flex-column`}>
				<header className={`text-l-title mx-3 my-2`}>{"Add Plants"}</header>
				<IxDivider></IxDivider>
				<section className={`mx-3 my-2`}>
					<form onSubmit={handlePlantSubmit}>
						{
							getPlantFormBody()
						}
						<div className={`m-2 py-2`}>
							<div className={`d-flex flex-row justify-content-center`}>
								<IxButton
									class='m-1'
									outline
									onClick={() => {
										clearPlantForm();
										setAddPlantDrawerShow(false);
									}}>
									Cancel
								</IxButton>
								<IxButton type='submit' class='m-1' variant='Primary'>
									Save
								</IxButton>
							</div>
						</div>
					</form>
				</section>
			</div>
		);
	};

	const getPlantFormBody = () => {
		return (
			<div className={`form-inputs-block-org`}>
				<div className={`mt-1`}>
					<label htmlFor='organization' className={`mb-1 text-default-title`}>
						Organization
					</label>
					<input
						id='organization'
						type='text'
						name='organization'
						placeholder='Enter'
						className={`form-control`}
						disabled={true}
						required
						value={orgValue.length > 0 ? orgValue[0].OrgName : ""}
					/>
				</div>
				<div className={`mt-3 text-l`}>Plant details</div>
				<p className={`text-s`}>Start adding STP plants to the organization</p>
				{plantValues.map(
					(
						element: {
							SubOrgName: string;
							Location: string;
							Capacity: number;
							PlantType: string;
							Longitude: number;
							Latitude: number;
						},
						index: Key | null | undefined
					) => (
						<div key={index} className={`p-3 mb-4 ${styles.plant_details}`}>
							<div>
								<label htmlFor='SubOrgName' className={`mb-1 text-default-title is_required`}>
									STP plant name
								</label>
								<input
									id='SubOrgName'
									type='text'
									name='SubOrgName'
									placeholder='Enter'
									className={`form-control`}
									value={element?.SubOrgName || ""}
									onChange={(e) => handleChangePlant(index, e)}
								/>
							</div>
							<div className={`mt-3`}>
								<label htmlFor='Location' className={`mb-1 text-default-title is_required`}>
									Location line
								</label>
								<input
									id='Location'
									type='text'
									name='Location'
									placeholder='Enter'
									className={`form-control`}
									value={element?.Location}
									onChange={(e) => handleChangePlant(index, e)}
								/>
							</div>
							<div className={`mt-3`}>
								<div className='inputGroup'>
									<label htmlFor='Latitude' className={`mb-1 text-default-title is_required`}>
										Latitude
									</label>
									<input
										type='number'
										name='Latitude'
										id='Latitude'
										value={element?.Latitude || 0}
										placeholder='Enter'
										className={`form-control`}
										onChange={(e) => handleChangePlant(index, e)}
									/>
								</div>
								<div className='inputGroup'>
									<label htmlFor='Longitude' className={`mb-1 text-default-title is_required`}>
										Longitude
									</label>
									<input
										type='number'
										name='Longitude'
										id='Longitude'
										value={element?.Longitude || 0}
										placeholder='Enter'
										className={`form-control`}
										onChange={(e) => handleChangePlant(index, e)}
									/>
								</div>
							</div>
							<div className={`mt-3 d-flex flex-row justify-content-between`}>
								<div>
									<label htmlFor='Capacity' className={`mb-1 text-default-title is_required`}>
										Capacity
									</label>
									<input
										id='Capacity'
										type='number'
										name='Capacity'
										placeholder='Enter'
										className={`form-control`}
										value={element?.Capacity || 0}
										onChange={(e) => handleChangePlant(index, e)}
									/>
								</div>
								<div className={`ms-5`}>
									<label htmlFor='PlantType' className={`mb-1 text-default-title is_required`}>
										Plant type
									</label>
									<IxSelect
										mode="single"
										selected-indices={element.PlantType || PlantType.STP}
										onItemSelectionChange={(e) => handleChangePlant(index, e.detail.toString(), 'PlantType')}>
										<IxSelectItem label={PlantType.STP} value={PlantType.STP}></IxSelectItem>
										<IxSelectItem label={PlantType.FSTP} value={PlantType.FSTP}></IxSelectItem>
									</IxSelect>
								</div>
							</div>
							{index ? (
								<IxButton class='mt-3' variant='Secondary' onClick={() => removeFormFields(index)}>
									<IxIcon name='close-small'></IxIcon> Remove
								</IxButton>
							) : null}
						</div>
					)
				)}
			</div>
		);
	};

	const editPlantForm = () => {
		return (
			<div className={`d-flex flex-column`}>
				<header className={`text-l-title mx-3 my-2`}>{"Edit Plants"}</header>
				<IxDivider></IxDivider>
				<section className={`mx-3 my-2`}>
					<form onSubmit={handleEditPlant}>
						{
							getPlantFormBody()
						}
						<div className={`m-2 py-2`}>
							<div className={`d-flex flex-row justify-content-center`}>
								<IxButton
									class='m-1'
									outline
									onClick={() => {
										clearPlantForm();
										setEditPlantDrawerShow(false);
									}}>
									Cancel
								</IxButton>
								<IxButton type='submit' class='m-1' variant='Primary'>
									Save
								</IxButton>
							</div>
						</div>
					</form>
				</section>
			</div>
		);
	};

	function isUserAllowed() {
		return !userAssignedPlants?.includes(organizationInfo.subOrgName);
	}

	return (
		<div className={"m-3"}>
			<BreadCrumb itemString='Organization'></BreadCrumb>
			<section className='m-3 d-flex justify-content-between align-items-center'>
				{orgValue.length === 0 && openOrgInit && (
					<div className='text-default text-gray py-2'>
						Set up a hierarchy for your org to control user access to STP plants.
					</div>
				)}
				{orgValue.length > 0 && orgValue[0].OrgName?.length > 0 && openOrgDiv && (
					<div className='text-default text-gray py-2'>
						Hierarchy shows the list of Sub-Organizations and their Plants respectively.
					</div>
				)}
			</section>
			{orgValue.length === 0 && openOrgInit && (
				<section>
					<div className='create-default m-2'>
						<div className='vertical-center'>
							<div className='text-l-title'>No Organizations available</div>
							<div className='text-default text-gray'>
								There aren't any organizations created. Please add an organization
							</div>
							<IxButton
								disabled={orgDrawerShow}
								className='m-2'
								variant='Primary'
								onClick={() => setOrgDrawerShow(!orgDrawerShow)}>
								<IxIcon name='plus'></IxIcon>Create organization
							</IxButton>
						</div>
					</div>
				</section>
			)}

			{orgValue.length > 0 && orgValue[0].OrgName && openOrgDiv && (
				<div className={`${styles.organization_tab}`}>
					<section className='my-2'>
						<TreeStructure
							userHasViewOrgPerm={userHasViewOrgPerm}
							allOrgData={orgTree}
							setOrganizationInfo={setOrganizationInfo}></TreeStructure>
					</section>
					{(isOrg || isSubOrg || isPlant) && (
						<div className={`background_fade ${styles.layout}`}>
							{isOrg && (
								<section>
									<div className={`background_fade ${styles.infobar}`}>
										<div className={`${styles.infobarDiv}`}>
											<p>Organization Name</p>
											<p>{orgValue[0].OrgName}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Total Sub Organizations</p>
											<p>{organizationInfo.totalSubOrgs}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Total Plants</p>
											<p>{organizationInfo.totalPlants}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Actions</p>
											<div
												style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
												<IxIconButton
													disabled={!userHasAddOrgPerm}
													class='addSubOrg'
													className='me-1 mt-1'
													icon='tree'
													size='24'
													onClick={() => addSubOrg(organizationInfo.id)}></IxIconButton>
												<IxTooltip for='.addSubOrg' placement='bottom'>
													Add Sub-organization
												</IxTooltip>
											</div>
										</div>
									</div>
								</section>
							)}
							{isSubOrg && (
								<section>
									<div className={`background_fade ${styles.infobar}`}>
										<div className={`${styles.infobarDiv}`}>
											<p>Organization Name</p>
											<p>{orgValue[0].OrgName}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Sub Organization Name</p>
											<p>{organizationInfo.subOrgName}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Total Plants</p>
											<p>{organizationInfo.totalPlants}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Actions</p>
											<div
												style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
												<IxIconButton
													disabled={!userHasAddPlantPerm}
													class='addPlant'
													className='me-1 mt-1'
													icon='create-plant'
													size='24'
													onClick={() => addPlants(organizationInfo.id)}></IxIconButton>
												<IxTooltip for='.addPlant' placement='bottom'>
													Add Plant
												</IxTooltip>
												<IxIconButton
													disabled={!userHasAddOrgPerm}
													class='addSuborg'
													className='me-1 mt-1'
													icon='tree'
													size='24'
													onClick={() => addSubOrg(organizationInfo.id)}></IxIconButton>
												<IxTooltip for='.addSuborg' placement='bottom'>
													Add Sub-organization
												</IxTooltip>
												<IxIconButton
													disabled={!userHasDelOrgPerm}
													class='editSubOrg'
													className='me-1 mt-1'
													icon='pen'
													size='24'
													onClick={() => editSubOrg(organizationInfo.id)}></IxIconButton>
												<IxTooltip for='.editSubOrg' placement='bottom'>
													Edit Sub-organization
												</IxTooltip>
												<IxIconButton
													disabled={!userHasDelOrgPerm}
													class='deleteSubOrg'
													className='me-1 mt-1'
													icon='trashcan'
													size='24'
													onClick={() =>
														deleteSubOrgOrPlant(organizationInfo.id)
													}></IxIconButton>
												<IxTooltip for='.deleteSubOrg' placement='bottom'>
													Delete Sub-organization
												</IxTooltip>
											</div>
										</div>
									</div>
								</section>
							)}
							{isPlant && (
								<section>
									<div className={`background_fade ${styles.infobar}`}>
										<div className={`${styles.infobarDiv}`}>
											<p>Organization Name</p>
											<p>{orgValue[0].OrgName}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Sub Organization Name</p>
											<p>{organizationInfo.plantParent}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Plant Name</p>
											<p>{organizationInfo.subOrgName}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Configured Kpis</p>
											<p>{selectionPlantKPIs}</p>
										</div>
										<div className={`${styles.infobarDiv}`}>
											<p>Actions</p>
											<div
												style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
												<IxIconButton
													disabled={isUserAllowed()}
													class='goToDashboard'
													className='me-1 mt-1'
													icon='monitor-trend'
													size='24'
													onClick={() => goToDashboard()}></IxIconButton>
												<IxTooltip for='.goToDashboard' placement='bottom'>
													Go to Dashboard
												</IxTooltip>
												<IxIconButton
													disabled={isUserAllowed() && !userHasAddPlantPerm}
													class='editPlant'
													className='me-1 mt-1'
													icon='edit-plant'
													size='24'
													onClick={() => editPlant(organizationInfo.id)}></IxIconButton>
												<IxTooltip for='.editPlant' placement='bottom'>
													Edit Plant
												</IxTooltip>
												<IxIconButton
													disabled={isUserAllowed() && !userHasDelPlantPerm}
													class='deleteSubOrg'
													className='me-1 mt-1'
													icon='trashcan'
													size='24'
													onClick={() =>
														deleteSubOrgOrPlant(organizationInfo.id)
													}></IxIconButton>
												<IxTooltip for='.deleteSubOrg' placement='bottom'>
													Delete Plant
												</IxTooltip>
												<IxIconButton
													disabled={isUserAllowed()}
													class='configKPI'
													className='me-1 mt-1'
													icon='project-configuration'
													size='24'
													onClick={() =>
														redirectToConfigureKpis(organizationInfo.id)
													}></IxIconButton>
												<IxTooltip for='.configKPI' placement='bottom'>
													Configure KPIs
												</IxTooltip>
											</div>
										</div>
									</div>
								</section>
							)}
						</div>
					)}
				</div>
			)}

			<IxDrawer
				closeOnClickOutside={true}
				fullHeight={true}
				width={28}
				show={orgDrawerShow}
				onDrawerClose={() => setOrgDrawerShow(false)}>
				{orgValue.length === 0 && openOrgInit && orgForm()}
			</IxDrawer>

			<IxDrawer
				closeOnClickOutside={true}
				fullHeight={true}
				width={28}
				show={createSubOrgDrawerShow}
				onDrawerClose={() => {
					clearSubOrgForm();
					setCreateSubOrgDrawerShow(false);
				}}>
				{orgValue.length > 0 && subOrgForm()}
			</IxDrawer>

			<IxDrawer
				closeOnClickOutside={true}
				fullHeight={true}
				width={28}
				show={editSubOrgDrawerShow}
				onDrawerClose={() => {
					clearSubOrgForm();
					setEditSubOrgDrawerShow(false);
				}}>
				{orgValue.length > 0 && editSubOrgForm()}
			</IxDrawer>

			<IxDrawer
				closeOnClickOutside={true}
				fullHeight={true}
				width={28}
				show={addPlantDrawerShow}
				onDrawerClose={() => {
					setAddPlantDrawerShow(false);
					clearPlantForm();
				}}>
				{addPlantForm()}
			</IxDrawer>

			<IxDrawer
				closeOnClickOutside={true}
				fullHeight={true}
				width={28}
				show={editPlantDrawerShow}
				onDrawerClose={() => {
					setEditPlantDrawerShow(false);
					clearPlantForm();
				}}>
				{editPlantForm()}
			</IxDrawer>
		</div>
	);
};

export default Organization;
