import { useEffect, useState, useRef } from "react";
import styles from "./Userlist.module.scss";
import UserForm from "../../UserForm/UserForm";
import {
    IxDivider,
    IxDrawer,
    IxIconButton,
    IxPill, showModal,
    showToast
} from "@siemens/ix-react";
import { useToastPosition } from "../../../hooks/useToastPosition";
import ConfirmModal from "../../../Shared/ConfirmModal/ConfirmModal";
import UsersService from "../../../Services/UsersService";
import { useInject } from "../../../hooks/useInject";
import { ToastMessage } from "../../../Config/constants/ToastMessage";
import { ManagementType, RolesEnum, UserManagementActions } from "../../../Config/enums/RolesEnum";
import { reload } from "../../../utilities/utils";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setUsersProfile } from "../../../redux/slices/userSlice";
import { UserProfileInterface } from "../../../Config/interfaces/UserInterface";

const userEmail = process.env.REACT_APP_USER_EMAIL;

/**
  * Calls the user list table component to display based on keycloak user data
  *
  * @param  userDataArray
*/
export default function Userlist({ realmID }: { realmID: any }): JSX.Element {
    let deleteId: any = useRef(undefined);
    const usersService = useInject(UsersService);
    const dispatch = useAppDispatch();
    const baseURL = process.env.REACT_APP_KEYCLOAK_BASEURL;// 'https://129.214.104.28:8443/admin/realms/STP/';
    const usersProfile = useAppSelector(state => state.userSlice.usersProfile);
    const [editId, seteditId] = useState();
    const [userDataArray, setUserDataArray] = useState<UserProfileInterface[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [userDrawerShow, setUserDrawerShow] = useState(false);
    const loggedInUserRolePermissions = useAppSelector(state => state.roleSlice.loggedInUserPermissions);

    useToastPosition('top-right');

    useEffect(() => {
        if (!realmID) {
            return;
        }
        usersService.getUsersProfile(realmID).then((usersProfile: any) => {
            usersProfile?.length ? dispatch(setUsersProfile(usersProfile)) : dispatch(setUsersProfile([]));
        });
    }, [realmID]);

    useEffect(() => {
        const sortedUserArr = usersProfile.filter((user: any) => user.email !== userEmail);
        setUserDataArray(sortedUserArr);
    }, [usersProfile]);


    /**
      * Function to edit the user details.
      * 
      * @param user
    */
    function editUser(user: any) {
        seteditId(user.id);
        setIsEditMode(true);
        setUserDrawerShow(true);
    }

    const confirmationHandler = (actionType: string) => {
        if (actionType === "yes") {
            deleteUser().then(r => r);
        } else {
            deleteId = undefined;
        }
    };

    /**
      * Delete function to delete the user details from the keycloak DB
      *
      * @param id
     */
    async function deleteUser() {
        if (!deleteId) {
            await showToast({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong'
            });
            return;
        }

        let bodyContent = "";

        try {
            const response = await fetch(baseURL + "users/" + deleteId, {
                method: "DELETE",
                body: bodyContent,
                headers: usersService.headersList
            });
            if (response.ok) {
                const deletedUser: any = userDataArray.find((user: any) => user.id === deleteId);
                await showToast({
                    type: 'success',
                    title: 'Deleted',
                    message: `User "${deletedUser?.username}" has been deleted successfully`
                });
                const usersProfile = await usersService.getUsersProfile(realmID);
                usersProfile.length ? dispatch(setUsersProfile(usersProfile)) : dispatch(setUsersProfile([]));
            } else {
                await showToast({
                    type: 'error',
                    message: ToastMessage.unknown
                });
                reload();
            }
        } catch (err) {
            await showToast({
                type: 'error',
                message: ToastMessage.unknown
            });
            console.error('API call failed: ', err);
            reload();
        }
    }

    /**
     * Function confirmation to delete the user details from the keycloak DB
     *
     * @param user
     */
    function deleteUserConfirmation(user: any) {
        if (user.rolename?.toLowerCase() === RolesEnum.admin || !loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Del]) {
            return;
        }
        deleteId = user?.id;
        userDataArray.some((user: any) => {
            if (user.id === deleteId) {
                showConfirmBox(user.username).then(r => r);
                return true;
            }
        });
    }

    const updateNoteHandler = async (notification: any) => {
        setIsEditMode(false);
        setUserDrawerShow(false);
        await showToast(notification);
    };

    async function showConfirmBox(deletingUsername: string) {
        const confirmationText = `Are you sure?`;
        const confirmationSubText = `Do you really want to delete the user "${deletingUsername}" permanently?`;
        await showModal({
            title: 'deleteConfirmationModal',
            content: <ConfirmModal key={deletingUsername} onAction={confirmationHandler} confirmationText={confirmationText}
                confirmationSubText={confirmationSubText} />,
            icon: 'warning-filled',
            iconColor: 'color-warning',
            size: 'lg'
        });
    }

    /**
      * Returns HTML DOM elements
      *
      * @param None
    */
    const tabularView = () => {
        return <tbody>
            {
                userDataArray?.length ? (
                    userDataArray.map((user: any) => {
                        return <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.first_name} {user.last_name}</td>
                            <td>
                                {user.email}
                            </td>
                            <td>
                                {(user.roles?.map((role: string) => (role !== null ? <IxPill outline className={'m-1 p-2'} icon="" key={role}>{role}</IxPill> : '')))}
                            </td>
                            <td>
                                {
                                    (user.plants) ? (
                                        <div className={`${styles.assigned_plants}`}>
                                            {
                                                user.plants.split(",").map((plant: any) => (
                                                    <IxPill outline className={`m-1 p-2`} icon="" key={plant}>{plant}</IxPill>
                                                ))
                                            }
                                        </div>
                                    ) : "No plants assigned"
                                }
                            </td>
                            <td className={`action_space`}>
                                <IxIconButton className="mx-1"
                                    disabled={user.username.toLowerCase() === 'admin' || !loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add]}
                                    onClick={() => editUser(user)}
                                    icon="pen"></IxIconButton>

                                <IxIconButton className="mx-1"
                                    disabled={user.username.toLowerCase() === 'admin' || !loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Del]}
                                    onClick={() => deleteUserConfirmation(user)} icon="trashcan"></IxIconButton>
                            </td>
                        </tr>
                    })
                ) : null
            }
        </tbody>
    }

    /**
      * Returns HTML DOM elements
      *
      * @param None
    */
    return (
        <>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Name</th>
                        <th scope="col">E-Mail</th>
                        <th scope="col">Role</th>
                        <th scope="col">Plants assigned</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                {userDataArray && tabularView()}
            </table>
            <IxDrawer closeOnClickOutside={true}
                fullHeight={true}
                width={28}
                show={loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add] && userDrawerShow}
                onDrawerClose={() => {
                    setUserDrawerShow(false);
                    setIsEditMode(false);
                }}>
                <div className={`d-flex flex-column`}>
                    <header className={`text-l-title mx-3 my-2`}>{isEditMode ? "Update User" : "Create new User"}</header>
                    <IxDivider></IxDivider>
                    <section className={`mx-3`}>
                        <UserForm
                            closeDialog={() => {
                                setUserDrawerShow(false);
                                setIsEditMode(false);
                            }}
                            userDrawerShow={userDrawerShow}
                            onSaveNotification={(type) => updateNoteHandler(type)}
                            editMode={isEditMode}
                            editId={editId}
                            usersProfile={userDataArray}
                        />
                    </section>
                </div>
            </IxDrawer>
        </>
    );

}


