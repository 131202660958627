import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { IxIcon } from "@siemens/ix-react";
import styles from "./AlarmBadge.module.scss";
import { setAlarmNavigatedThrough } from "../../redux/slices/navigationSlice";

const AlarmBadge = ({ navigatedThrough }: { navigatedThrough: string }) => {
  const dispatch = useAppDispatch();
  const alarmCount = useAppSelector((state) => state.alarmSlice.alarmCount);

  const setParentNav = () => {
    dispatch(setAlarmNavigatedThrough(navigatedThrough));
  };

  return (
    <Link
      to={
        {
          pathname: "/Alarms",
          state: { from: { pathname: window.location.pathname } },
        } as { pathname: string; state: { from: { pathname: string } } }
      }
      onClick={() => setParentNav()}
    >
      <div className="position-relative me-3 mt-3">
        <IxIcon name="alarm-bell" size="24"></IxIcon>
        {!!alarmCount && (
          <span
            className={`badge position-absolute start-50 ${styles.badge_position}`}
          >
            {alarmCount}
          </span>
        )}
      </div>
    </Link>
  );
};

export default AlarmBadge;
