import { useEffect, useState } from "react";
import { IxTabItem, IxTabs } from "@siemens/ix-react";
import Detailedview from "../Detailedview/Detailedview";
import Correlation from "../Correlation/Correlation";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import BreadCrumb from "../Shared/Breadcrumb/BreadCrumb";
import usePollAlarmsOnInterval from "../hooks/usePollAlarmsOnInterval";
import AlarmBadge from "../Shared/AlarmBadge/AlarmBadge";
import useSetPlant from "../hooks/useSetPlant";
import { NavigationEnum } from "../Config/enums/NavigationEnum";
import { setSelectedCategoryId, setSelectedKpiId } from "../redux/slices/plantSlice";
import { useParams } from "react-router-dom";

const KPIView = () => {
  const { idd: selectedCategoryId = 0, id: selectedKpiId = 0 } = useParams();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const changeTab = (tabId: number) => setSelectedTab(tabId);
  const selectedPlant = useAppSelector(state => state.plantSlice.selectedPlant);
  const selectedPlantStatus = useAppSelector(state => state.plantSlice.selectedPlantStatus);
  const navigatedThrough = useAppSelector(state => state.navigationSlice.dasboardNavigatedThrough);

  useSetPlant();
  usePollAlarmsOnInterval();

  useEffect(() => {
		dispatch(setSelectedCategoryId(+selectedCategoryId));
	}, [selectedCategoryId]);

  useEffect(() => {
    dispatch(setSelectedKpiId(+selectedKpiId));
  }, [selectedKpiId]);

  return (
    <div className={"mx-3"}>
      <div
        className={`d-flex flex-row flex-wrap justify-content-start align-items-center`}
      >
        <BreadCrumb
          itemString={`${navigatedThrough},${selectedPlant} - ${NavigationEnum.dashboard},${NavigationEnum.analytics}`}
        ></BreadCrumb>
        <div className="ms-auto">
          <div className="text-l-title d-flex flex-row align-items-center text-center">
            {selectedPlantStatus ? (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="color-success me-1"
                  viewBox="0 0 16 16"
                >
                  <circle cx="6" cy="6" r="6" />
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="color-alarm me-1"
                  viewBox="0 0 16 16"
                >
                  <circle cx="6" cy="6" r="6" />
                </svg>
              </div>
            )}
            <div className={`text-default-title`}>{selectedPlant}</div>
          </div>
        </div>
      </div>

      <div className={`analytics_view`}>
        <div className="d-flex justify-content-between align-items-center">
          <IxTabs selected={selectedTab}>
            <IxTabItem onClick={() => changeTab(0)}>KPI details</IxTabItem>
            <IxTabItem onClick={() => changeTab(1)}>Insights</IxTabItem>
          </IxTabs>
          <AlarmBadge navigatedThrough={NavigationEnum.analytics}/>
        </div>
        {selectedTab === 0 ? <Detailedview /> : null}
        {selectedTab === 1 ? <Correlation /> : null}
      </div>
    </div>
  );
};

export default KPIView;