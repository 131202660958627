import React, { FC, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalRef,
  IxIconButton,
  IxTabs,
  IxTabItem,
  IxButton,
  IxDivider,
  IxIcon,
} from "@siemens/ix-react";
import DateTimePickerInput from "../../Shared/UI-Elements/DateTimePickerInput/DateTimePickerInput";
import { PlantKpiInterface } from "../../Config/interfaces/PlantInterface";
import { compareStartAndEndDate } from "../../utilities/utils";
import { getFormattedStartEndDatesTimestamp } from "../../utilities/converters";

interface ExportKPIModalProps {
  onAction: (
    actionStatus: boolean,
    startDate: { date: string; time: string },
    endDate: { date: string; time: string },
    selectedKPIs: number[]
  ) => void;
  plantKPIs: PlantKpiInterface[] | [];
}

const ExportKPIModal: FC<ExportKPIModalProps> = ({ onAction, plantKPIs }) => {
  const modalRef = useRef<ModalRef>(null);
  const defaultStartDate = { date: '', time: '00:00:00' };
  const defaultEndDate = { date: '', time: '23:59:59' };
  const [startDateTime, setStartDateTime] = useState(defaultStartDate);
  const [endDateTime, setEndDateTime] = useState(defaultEndDate);
  const [kpiCategories, setkpiCategories] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedKPIs, setSelectedKPIs] = useState<number[]>([]);
  const [dateTimeError, setDateTimeError] = useState(false);

  function select(dat: any) {
    const { id, displayNameForType, type } = dat;
    return { id, displayNameForType, type };
  }

  useEffect(() => {
    getKPICategories();
  }, [plantKPIs]);

  function getKPICategories() {
    const editedJson: any[] = plantKPIs.map(select);
    //removing the duplicates
    const kpiCategories = editedJson.filter(
      (item, i, ar) => ar.findIndex((item2: any) => item2.type === item.type) === i
    );
    setkpiCategories(kpiCategories);

    // Initialize selectedKPIs with all KPI IDs
    const allKPIIds = (plantKPIs.map((kpi) => Number(kpi.id)));
    setSelectedKPIs(allKPIIds);
  }

  const changeTab = (tabId: number) => {
    setSelectedTab(tabId);
  };

  const dismiss = (): void => {
    modalRef.current?.dismiss("dismiss payload");
    sendActionStatus(false);
  };

  const sendActionStatus = (actionStatus: boolean) => {
    onAction(actionStatus, startDateTime, endDateTime, selectedKPIs);
  };

  const OnExport = () => {
    modalRef.current?.dismiss("close payload");
    sendActionStatus(true);
  };

  const handleKPICheckBoxChange = (isChecked: boolean, kpiId: number) => {
    if (isChecked) {
      setSelectedKPIs((prevSelectedKPIs) => [...prevSelectedKPIs, kpiId]);
    } else {
      setSelectedKPIs((prevSelectedKPIs) =>
        prevSelectedKPIs.filter((id) => id !== kpiId)
      );
    }
  };

  const handleStartDateTimeChange =  (newDateTime: { date: string; time: string } | null)  => {
    if (newDateTime){
      setStartDateTime(newDateTime);
    
      const startEndDateTimestamps = getFormattedStartEndDatesTimestamp(
        newDateTime,
        endDateTime
      );

      if (!startEndDateTimestamps) {
        return;
      }
  
    // Check if the start date and time is greater than the end date and time
    if ( !compareStartAndEndDate(startEndDateTimestamps, false) ){ 
      setDateTimeError(true); // Reset end date and time if start is greater than end
    }
    else{
      setDateTimeError(false);
    }
  }
  };

  const handleEndDateTimeChange =  (newDateTime: { date: string; time: string } | null)  => {
    if (newDateTime){
      setEndDateTime(newDateTime);
      const startEndDateTimestamps = getFormattedStartEndDatesTimestamp(
        startDateTime,
        newDateTime
      );

      if (!startEndDateTimestamps) {
        return;
      }
  
    // Check if the start date and time is greater than the end date and time
    if ( !compareStartAndEndDate(startEndDateTimestamps, false) ){ 
      setDateTimeError(true); // Reset end date and time if start is greater than end
    }
    else{
      setDateTimeError(false);
    }       
  }
  };

  return (
    <div>
      <Modal ref={modalRef}>
        <div className="modal-header">
          <span className={`text-l-title`}>Export KPIs</span>
          <IxIconButton
            data-button-close
            ghost
            icon="close"
            onClick={() => dismiss()}
          ></IxIconButton>
        </div>
        <IxDivider></IxDivider>
        <div className="modal-body">
          <div className={`d-flex flex-column`}>
            <div>
              <DateTimePickerInput
                disabled={false}
                type={`startDate`}
                dateTimePlaceholder={`Start date & time`}
                setDateTime={handleStartDateTimeChange}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>           
              <DateTimePickerInput
                disabled={false}
                type={`endDate`}
                dateTimePlaceholder={`End date & time`}
                setDateTime={handleEndDateTimeChange}
              />
              {dateTimeError && (
                <div className="field_hasError">
                  <IxIcon name = "error"></IxIcon>
                  <span style={{ marginLeft: '5px' }}>End date cannot be less than the Start date</span>
                </div>
              )} 
            </div>
            <IxTabs>
              <>
                {kpiCategories?.map((category: any, index: number) => {
                  return (
                    <IxTabItem
                      key={category?.id}
                      onClick={() => changeTab(index)}
                    >
                      {category.displayNameForType}
                    </IxTabItem>
                  );
                })}
              </>
            </IxTabs>
            {kpiCategories?.map((category: any, index: number) => (
              <div
                key={index}
                style={{ display: selectedTab === index ? "block" : "none" }}
              >
                {plantKPIs
                  ?.filter((plantKPI: any) => category.type === plantKPI.type)
                  .map((filteredPlantKPI: any, idx: number) => (
                    <div
                      key={idx}
                      style={{ marginTop: "8px", marginBottom: "5px" }}
                    >
                      <input
                        type="checkbox"
                        id={`chk_${filteredPlantKPI.id}`}
                        onChange={(event) =>
                          handleKPICheckBoxChange(
                            event.target.checked,
                            filteredPlantKPI.id
                          )
                        }
                        checked={selectedKPIs.includes(filteredPlantKPI.id)}
                      />
                      <label
                        className={``}
                        htmlFor={`chk_${filteredPlantKPI.id}`}
                      >
                        {filteredPlantKPI?.IotData.displayName}
                      </label>
                    </div>
                  ))}
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <IxButton class="m-1" variant="Primary" onClick={() => OnExport()}>
                Export
              </IxButton>
              <IxButton class="m-1" variant="Primary" onClick={() => dismiss()}>
                Cancel
              </IxButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExportKPIModal;
