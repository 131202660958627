import { useRef, useState } from "react";
import { IxButton, IxDivider, IxDrawer, IxIcon, IxIconButton, showModal, showToast } from "@siemens/ix-react";
import ConfirmModal from "../../../Shared/ConfirmModal/ConfirmModal";
import { ToastMessage, ToastType } from "../../../Config/constants/ToastMessage";
import { useInject } from "../../../hooks/useInject";
import RolesService from "../../../Services/RolesService";
import { RolesEnum, ManagementType, UserManagementActions, OrgManagementActions, RoleManagementActions, PlantManagementActions } from "../../../Config/enums/RolesEnum";
import { useAppSelector } from "../../../redux/hooks";
import { defaultPermissions, RoleInterface, RolesActionInterface } from "../../../Config/interfaces/RoleInterface";
import styles from './Roleslist.module.scss';
const createNewRoleText: string = 'Create new Role';

/**
  * Calls the user list table component to display based on keycloak user data
  *
  * @param  rolesArray
  * @param  updateRoles
*/
export default function Roleslist({ rolesArray, updateRoles }: { rolesArray: RoleInterface[], updateRoles: Function }): JSX.Element {
    let deleteId: any = useRef(undefined);
    const rolesService = useInject(RolesService);
    const realmID = useAppSelector(state => state.keycloakSlice.realmId);
    const usersProfile = useAppSelector(state => state.userSlice.usersProfile);
    const [roleValues, setRoleValues] = useState({
        rolename: '',
        description: '',
        permissions: { ...defaultPermissions }
    });
    const [createUpdateRoleShow, setCreateUpdateRoleShow] = useState(false);
    const [editId, setEditRoleId] = useState('');
    const loggedInUserRolePermissions = useAppSelector(state => state.roleSlice.loggedInUserPermissions);


    /**
     * initialize the permissions states
     *
    */
    const [permissions, setPermissions] = useState<RolesActionInterface>({
        [ManagementType.User]: {
            [UserManagementActions.Add]: false,
            [UserManagementActions.Del]: false,
            [UserManagementActions.View]: false,
            [UserManagementActions.Full]: false
        },
        [ManagementType.Roles]: {
            [RoleManagementActions.Add]: false,
            [RoleManagementActions.Del]: false,
            [RoleManagementActions.View]: false,
            [RoleManagementActions.Full]: false
        },
        [ManagementType.Org]: {
            [OrgManagementActions.Add]: false,
            [OrgManagementActions.Del]: false,
            [OrgManagementActions.View]: false,
            [OrgManagementActions.Full]: false
        },
        [ManagementType.Plant]: {
            [PlantManagementActions.Add]: false,
            [PlantManagementActions.Del]: false,
            [PlantManagementActions.View]: false,
            [PlantManagementActions.Full]: false
        }
    });

    const attributes: { [key: string]: boolean[] } = {
        [UserManagementActions.View]: [permissions[ManagementType.User][UserManagementActions.View]],
        [UserManagementActions.Add]: [permissions[ManagementType.User][UserManagementActions.Add]],
        [UserManagementActions.Del]: [permissions[ManagementType.User][UserManagementActions.Del]],
        [UserManagementActions.Full]: [permissions[ManagementType.User][UserManagementActions.Full]],

        [RoleManagementActions.View]: [permissions[ManagementType.Roles][RoleManagementActions.View]],
        [RoleManagementActions.Add]: [permissions[ManagementType.Roles][RoleManagementActions.Add]],
        [RoleManagementActions.Del]: [permissions[ManagementType.Roles][RoleManagementActions.Del]],
        [RoleManagementActions.Full]: [permissions[ManagementType.Roles][RoleManagementActions.Full]],

        [OrgManagementActions.View]: [permissions[ManagementType.Org][OrgManagementActions.View]],
        [OrgManagementActions.Add]: [permissions[ManagementType.Org][OrgManagementActions.Add]],
        [OrgManagementActions.Del]: [permissions[ManagementType.Org][OrgManagementActions.Del]],
        [OrgManagementActions.Full]: [permissions[ManagementType.Org][OrgManagementActions.Full]],

        [PlantManagementActions.View]: [permissions[ManagementType.Plant][PlantManagementActions.View]],
        [PlantManagementActions.Add]: [permissions[ManagementType.Plant][PlantManagementActions.Add]],
        [PlantManagementActions.Del]: [permissions[ManagementType.Plant][PlantManagementActions.Del]],
        [PlantManagementActions.Full]: [permissions[ManagementType.Plant][PlantManagementActions.Full]]
    };

    const handleRoleInfo = (name: string) => (e: any) => {
        if (e.type === 'blur') {
            setRoleValues({ ...roleValues, [name]: e.target.value.trim() });
        } else {
            setRoleValues({ ...roleValues, [name]: e.target.value });
        }
    };

    const handleChange = (type: 'User' | 'Org' | 'Roles' | 'Plant', action: 'Add' | 'View' | 'Del' | 'Full') => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        const newPermissions: any = structuredClone(permissions);
        newPermissions[type.toLocaleLowerCase()][`${type.toLocaleLowerCase()}_${action.toLocaleLowerCase()}`] = checked;
        setPermissions(checkForDependentPermission(newPermissions, type, action, checked));
    };

    function sortObjectByKeys(obj: any) {
        const sortedKeys = Object.keys(obj).sort();
        const sortedObj: { [key: string]: [boolean] } = {};
        sortedKeys.forEach(key => {
            sortedObj[key] = obj[key];
        });
        return sortedObj;
    }

    function isUniqueRole(payload: { name: string, attributes: any }): RoleInterface | undefined {
        const nameExists = rolesArray.find(role => role.id !== editId && (role.name.toLocaleLowerCase() === payload.name.toLocaleLowerCase()));
        const permissionsExist = rolesArray.find(role => role.id !== editId && JSON.stringify(sortObjectByKeys(role.permissions)) === JSON.stringify(sortObjectByKeys(payload.attributes)));

        if (nameExists) return nameExists;
        if (permissionsExist) return permissionsExist;

        if (editId) {
            const nameChanged = rolesArray.some(role => role.id === editId && role.name.toLocaleLowerCase() !== payload.name.toLocaleLowerCase());
            const permissionsChanged = rolesArray.some(role => role.id === editId && JSON.stringify(sortObjectByKeys(role.permissions)) !== JSON.stringify(sortObjectByKeys(payload.attributes)));

            if (nameChanged || permissionsChanged) return undefined;
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        let response: any;
        let message = [];
        const payload = {
            "name": roleValues.rolename,
            "description": roleValues.description,
            "attributes": attributes
        };
        if (!Object.keys(attributes).some(key => attributes[key][0])) {
            showToast({
                type: ToastType.error,
                message: ['Please select at least one permission']
            });
            return;
        }
        const bodyContent = JSON.stringify(payload);
        const roleExist = isUniqueRole(payload);
        if (roleExist) {
            showToast({
                type: ToastType.error,
                message: [`Role already exists by name `, <strong>{roleExist.name}</strong>]
            });
            return;
        }
        if (editId) {
            response = await rolesService.updateRole(realmID, editId, bodyContent);
            message = ['Role ', <strong>{roleValues.rolename}</strong>, ' updated successfully'];
        } else {
            response = await rolesService.createRole(realmID, bodyContent);
            message = ['Role ', <strong>{roleValues.rolename}</strong>, ' created successfully'];
        }
        if (response.ok) {
            showToast({
                type: ToastType.success,
                message: message
            });
            resetRoleForm();
            updateRoles(await rolesService.getRolesData(realmID));
            setCreateUpdateRoleShow(false);
        } else {
            showToast({
                type: ToastType.error,
                message: ToastMessage.unknown
            });
        }
    }

    /**
      * Function to close the edit role dilogue layout
      * @param None
    */
    const resetRoleForm = () => {
        setEditRoleId('');
        setRoleValues({ description: '', permissions: { ...defaultPermissions }, rolename: '' });
        setPermissions({ ...defaultPermissions });
    };

    function checkForDependentPermission(newPermissions: any, type: string, action: string, checked: boolean) {

        const allActionsTrue = (type: string, actions: string[]) => actions.every(action => newPermissions[type][action]);
        const setActionsTrue = (type: string, actions: string[]) => {
            actions.forEach(action => {
                newPermissions[type][action] = true;
            });
        };
        const setActionsFalse = (type: string, actions: string[]) => {
            actions.forEach(action => {
                newPermissions[type][action] = false;
            });
        }

        const userActions = [UserManagementActions.Add, UserManagementActions.View, UserManagementActions.Del];
        const orgActions = [OrgManagementActions.Add, OrgManagementActions.View, OrgManagementActions.Del];
        const rolesActions = [RoleManagementActions.Add, RoleManagementActions.View, RoleManagementActions.Del];
        const plantActions = [PlantManagementActions.Add, PlantManagementActions.View, PlantManagementActions.Del];

        const constIndex = ['userActions', 'orgActions', 'rolesActions', 'plantActions'].findIndex((actionName: string) => actionName.includes(type.toLocaleLowerCase()));
        const clickedAction = [userActions, orgActions, rolesActions, plantActions][constIndex];

        if (action !== 'Full') {
            if (allActionsTrue(type.toLocaleLowerCase(), clickedAction)) {
                newPermissions[type.toLocaleLowerCase()][`${type.toLocaleLowerCase()}_full`] = true;
            } else {
                newPermissions[type.toLocaleLowerCase()][`${type.toLocaleLowerCase()}_full`] = false;
            }
        } else if (action === 'Full') {
            checked ? setActionsTrue(type.toLocaleLowerCase(), clickedAction) : setActionsFalse(type.toLocaleLowerCase(), clickedAction);
        }

        if (type === 'Roles') {
            newPermissions[ManagementType.User][UserManagementActions.View] = rolesActions.some(actionIterator => newPermissions[ManagementType.Roles][actionIterator]);
        } else if (type === 'Plant') {
            newPermissions[ManagementType.Org][OrgManagementActions.View] = plantActions.some(actionIterator => newPermissions[ManagementType.Plant][actionIterator]);
        }
        return newPermissions;
    }

    /**
      * Function to edit the role details.
      *
      * @param editRoleId
    */
    function editRole(editRoleId: any) {
        if (editRoleId === RolesEnum.adminRoleId || !loggedInUserRolePermissions[ManagementType.Roles][RoleManagementActions.Add]) {
            return;
        }
        setCreateUpdateRoleShow(true);
        setEditRoleId(editRoleId);
        rolesArray.map((data: RoleInterface) => {
            if (data.id == editRoleId) {
                roleValues.rolename = data.name;
                roleValues.description = data.description;
                roleValues.permissions = structuredClone(data.permissions);
                return;
            }
        });
        const existingPermissions: RolesActionInterface | any = { ...roleValues.permissions };
        if (existingPermissions) {
            let newPermissions: any = structuredClone(permissions);
            Object.keys(existingPermissions).forEach((type: string) => {
                if (!existingPermissions[type]) return -1;

                Object.keys(existingPermissions[type]).forEach((action: string) => {
                    if (!attributes.hasOwnProperty(action)) return -1;

                    const val = existingPermissions[type][action];
                    newPermissions[type][action] = val;
                    attributes[action] = [val];
                    newPermissions = checkForDependentPermission(newPermissions, type.charAt(0).toUpperCase() + type.slice(1), action.charAt(0).toUpperCase() + action.slice(1), val);
                });
            });
            setPermissions(newPermissions);
        };
    }


    /**
      * Delete function to delete the role details from the keycloak DB
      *
      * @param
     */
    async function deleteRole() {

        let response = await rolesService.deleteRole(realmID, deleteId);
        if (response.status === 204) {
            showToast({
                type: ToastType.success,
                message: [`Role `, <strong>{deleteId}</strong>, ` deleted successfully`]
            }).finally(async () => {
                updateRoles(await rolesService.getRolesData(realmID));
            });
        } else {
            showToast({
                type: ToastType.error,
                title: ToastMessage.roleDeleteErrorTitle,
                message: [ToastMessage.unknown, 'while deleting role', <strong>{deleteId}</strong>]
            });
        }
    }

    const confirmationHandler = (actionType: string) => {
        if (actionType === "yes") {
            deleteRole().then(r => r);
        } else {
            deleteId = undefined;
        }
    };

    async function showConfirmBox(roleName: string) {
        const confirmationText = `Are you sure?`;
        const confirmationSubText = [
            <div key="outer_div">
                <div key="inner_div">
                    Do you really want to delete the role <strong>{roleName}</strong> permanently?
                </div>
                <div className={`m-3`} key="warning">
                    <p key="first_statement"> 1. The role will be permanently deleted and is not recoverable.</p>
                    <p key="second_statement"> 2. The permissions for the users under this role will be invalidated, and the user will not be assigned to any roles. </p>
                </div>
            </div>
        ];
        await showModal({
            title: 'deleteConfirmationModal',
            content: <ConfirmModal onAction={confirmationHandler} confirmationText={confirmationText}
                confirmationSubText={confirmationSubText} />,
            icon: 'warning-filled',
            iconColor: 'color-warning',
            size: 'lg'
        });
    }

    /**
     * Delete confirmation pop up function to delete the role details from the keycloak DB
     *
     * @param roleName
     */
    function deleteRoleConfirmation(roleName: string) {
        if (roleName?.toLocaleLowerCase() === RolesEnum.admin.toLocaleLowerCase() || !loggedInUserRolePermissions[ManagementType.Roles][RoleManagementActions.Del] || checkRoleInUse(roleName)) {
            return;
        }

        deleteId = roleName;
        rolesArray.map((role: any) => {
            if (role.name === roleName) {
                showConfirmBox(role.name).then(r => r);
            }
        });
    }

    function checkRoleInUse(roleName: string): boolean {
        const roleInUse = usersProfile.some(user => user.roles.includes(roleName));
        if (roleInUse) {
            showToast({
                type: ToastType.error,
                title: `${ToastMessage.cannotDeleteRoleTitle}`,
                message: ['Role ', <strong>{roleName}</strong>, ` ${ToastMessage.cannotDeleteRoleMessage}`]
            });
            return true;
        }
        return false;
    }

    function generatePill(pName: string) {
        return `<ix-pill outline key=${pName}>${pName}</ix-pill>`;
    }

    function appendPermissions(managementType: string, permissions: string[], simplifiedPermissions: any, spanIndex: number) {
        const majorKey = ['roles', 'user', 'org', 'plant'][spanIndex];
        const isOdd = spanIndex % 2 !== 0;
        const managementIcon = isOdd ? `<ix-icon name="prio-low" size="24"></ix-icon>\t ${managementType}:` : `${managementType}:`;
        const allTrue = permissions.every(p => simplifiedPermissions[majorKey][p]);
        const noneTrue = permissions.every(p => !simplifiedPermissions[majorKey][p]);
        const pills = permissions.filter(p => simplifiedPermissions[majorKey][p]).map(p => {
            p = p.includes('_del') ? p.replace('_del', '_delete') : p;
            const pName = p.split('_')[1].charAt(0).toUpperCase() + p.split('_')[1].slice(1);
            return generatePill(pName);
        }).join('');

        const spanContent = allTrue ? generatePill('Full Control') : noneTrue ? generatePill('None') : pills;
        return `<span class="${styles.permission_column}">${managementIcon}${spanContent}</span>${isOdd ? '<br>' : ''}`;
    }

    function convertIntoReadableFormat(permissions: RolesActionInterface) {
        const simplifiedPermissions = structuredClone(permissions);
        const managementTypes = [
            ['Roles Management', [RoleManagementActions.View, RoleManagementActions.Add, RoleManagementActions.Del]],
            ['User Management', [UserManagementActions.View, UserManagementActions.Add, UserManagementActions.Del]],
            ['Organization Management', [OrgManagementActions.View, OrgManagementActions.Add, OrgManagementActions.Del]],
            ['Plant Management', [PlantManagementActions.View, PlantManagementActions.Add, PlantManagementActions.Del]],
        ];

        const finalStr = managementTypes.reduce((acc, [type, perms], index) => {
            const newSection = index % 2 === 0 && index > 0 ? `</p><ix-divider class="hydrated ${styles.permission_column_divider_width}"></ix-divider><p class="${styles.second_para}">` : '';
            return `${acc}${newSection}${appendPermissions(type.toString(), (perms as string[]), simplifiedPermissions, index)}`;
        }, `<p class="${styles.first_para}">`) + '</p>';

        return { __html: finalStr };
    }

    function getCreatePermission() {
        return (loggedInUserRolePermissions[ManagementType.Roles][RoleManagementActions.Add] && createUpdateRoleShow);
    }

    return (
        <>
            <section className="m-3 d-flex justify-content-between align-items-center" style={{ overflowY: 'hidden' }}>
                <div className="text-default text-gray py-2">See and manage roles and associated permissions.</div>
                {
                    <IxButton disabled={getCreatePermission()} onClick={() => setCreateUpdateRoleShow(true)}>
                        <IxIcon name="plus"></IxIcon>{createNewRoleText}
                    </IxButton>
                }
            </section>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Role</th>
                        <th scope="col">Description</th>
                        <th scope="col">Permissions</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rolesArray && rolesArray.length > 0 && (
                            rolesArray.map((data: any, i: number) => {
                                return <tr key={data.id}>
                                    <td>{data.name}</td>
                                    <td>{data.description}</td>
                                    <td dangerouslySetInnerHTML={convertIntoReadableFormat(data.permissions)} className="my-1"></td>
                                    <td className={`action_space`}>
                                        <IxIconButton className="mx-1"
                                            disabled={data.name?.toLocaleLowerCase() === RolesEnum.admin.toLocaleLowerCase() || !loggedInUserRolePermissions[ManagementType.Roles][RoleManagementActions.Add]}
                                            onClick={() => editRole(data.id)}
                                            icon="pen"></IxIconButton>
                                        <IxIconButton className="mx-1"
                                            disabled={data.name?.toLocaleLowerCase() === RolesEnum.admin.toLocaleLowerCase() || !loggedInUserRolePermissions[ManagementType.Roles][RoleManagementActions.Del]}
                                            onClick={() => deleteRoleConfirmation(data.name)} icon="trashcan"></IxIconButton>
                                    </td>
                                </tr>
                            })
                        )
                    }
                </tbody>
            </table>

            <IxDrawer closeOnClickOutside={true} fullHeight={true} width={28} show={createUpdateRoleShow}
                onDrawerClose={() => { setCreateUpdateRoleShow(false); resetRoleForm(); }}>
                <div className={`d-flex flex-column`}>
                    <header className={`text-l-title mx-3 my-2`}>{editId ? 'Update role' : createNewRoleText}</header>
                    <IxDivider></IxDivider>
                    <section className={`mx-3`}>
                        <form>
                            <div className={`form-inputs-block`}>
                                <div className={`text-l mt-2`}>Role Information</div>
                                <div className={`mt-2`}>
                                    <label htmlFor="roleName" className={`mb-1 text-default-title is_required`}>Role name</label>
                                    <input id="roleName" type="text" name="rolename" required
                                        value={roleValues.rolename}
                                        placeholder='Please enter role'
                                        className={`form-control`}
                                        onChange={handleRoleInfo('rolename')}
                                        onBlur={handleRoleInfo('rolename')} />
                                </div>
                                <div className={`mt-3`}>
                                    <label htmlFor="desc" className={`mb-1 text-default-title is_required`}>Description</label>
                                    <textarea id="desc" name="description" required
                                        value={roleValues.description}
                                        placeholder='Please enter role description'
                                        className={`form-control`}
                                        onChange={handleRoleInfo('description')}
                                        onBlur={handleRoleInfo('description')}></textarea>
                                </div>
                                <IxDivider className={`mt-4 mb-3`}></IxDivider>
                                <div className={`text-l is_required`}>Permissions</div>
                                <p className={`text-s`}>Decide which permissions to grant to this role</p>
                                <div className={`mt-3`}>
                                    <div className={`mt-3 text-default-title`}>Roles Management</div>
                                    <p className={`text-s`}>Permissions that cover creating and managing Roles.</p>
                                    <div className={`d-flex flex-row justify-content-around`}>
                                        <Checkbox id="roles_full_control_edit" label="Full Control" name="role_full"
                                            value={permissions[ManagementType.Roles][RoleManagementActions.Full]}
                                            onChange={handleChange('Roles', 'Full')}
                                        />
                                        <IxIcon name="prio-low" size="24"></IxIcon>
                                        <Checkbox id="roles_view_edit" label="View"
                                            value={permissions[ManagementType.Roles][RoleManagementActions.View]}
                                            onChange={handleChange('Roles', 'View')}
                                        />
                                        <Checkbox id="roles_add_edit" label="Add"
                                            value={permissions[ManagementType.Roles][RoleManagementActions.Add]}
                                            onChange={handleChange('Roles', 'Add')}
                                        />
                                        <Checkbox id="roles_delete_edit" label="Delete"
                                            value={permissions[ManagementType.Roles][RoleManagementActions.Del]}
                                            onChange={handleChange('Roles', 'Del')}
                                        />
                                    </div>
                                </div>
                                <div className={`mt-3`}>
                                    <div className={`text-default-title`}>User Management</div>
                                    <p className={`text-s`}>Permissions related to adding users, modifying their roles and deleting them from organizations</p>
                                    <div className={`d-flex flex-row justify-content-around`}>
                                        <Checkbox id="user_full_control_edit" label="Full Control" name="user_full"
                                            value={permissions[ManagementType.User][UserManagementActions.Full]}
                                            onChange={handleChange('User', 'Full')}
                                        />
                                        <IxIcon name="prio-low" size="24"></IxIcon>
                                        <Checkbox id="user_view_edit" label="View"
                                            value={permissions[ManagementType.User][UserManagementActions.View]}
                                            onChange={handleChange('User', 'View')} />
                                        <Checkbox id="user_add_edit" label="Add"
                                            value={permissions[ManagementType.User][UserManagementActions.Add]}
                                            onChange={handleChange('User', 'Add')} />
                                        <Checkbox id="user_delete_edit" label="Delete"
                                            value={permissions[ManagementType.User][UserManagementActions.Del]}
                                            onChange={handleChange('User', 'Del')}
                                        />
                                    </div>
                                </div>
                                <IxDivider className={`mt-3`}></IxDivider>
                                <div className={`mt-3`}>
                                    <div className={`mt-3 text-default-title`}>Organization Management</div>
                                    <p className={`text-s`}>Permissions related to adding and managing organizational structure within application</p>
                                    <div className={`d-flex flex-row justify-content-around`}>
                                        <Checkbox id="org_full_control_edit" label="Full Control" name="org_full"
                                            value={permissions[ManagementType.Org][OrgManagementActions.Full]}
                                            onChange={handleChange('Org', 'Full')}
                                        />
                                        <IxIcon name="prio-low" size="24"></IxIcon>
                                        <Checkbox id="org_view_edit" label="View"
                                            value={permissions[ManagementType.Org][OrgManagementActions.View]}
                                            onChange={handleChange('Org', 'View')}
                                        />
                                        <Checkbox id="org_add_edit" label="Add"
                                            value={permissions[ManagementType.Org][OrgManagementActions.Add]}
                                            onChange={handleChange('Org', 'Add')}
                                        />
                                        <Checkbox id="org_delete_edit" label="Delete"
                                            value={permissions[ManagementType.Org][OrgManagementActions.Del]}
                                            onChange={handleChange('Org', 'Del')}
                                        />
                                    </div>
                                </div>
                                <div className={`mt-3`}>
                                    <div className={`mt-3 text-default-title`}>Plant Management</div>
                                    <p className={`text-s`}>Permissions related to adding and managing plants within organization</p>
                                    <div className={`d-flex flex-row justify-content-around`}>
                                        <Checkbox id="plant_full_control_edit" label="Full Control" name="plant_full"
                                            value={permissions[ManagementType.Plant][PlantManagementActions.Full]}
                                            onChange={handleChange('Plant', 'Full')}
                                        />
                                        <IxIcon name="prio-low" size="24"></IxIcon>
                                        <Checkbox id="plant_view_edit" label="View"
                                            value={permissions[ManagementType.Plant][PlantManagementActions.View]}
                                            onChange={handleChange('Plant', 'View')}
                                        />
                                        <Checkbox id="plant_add_edit" label="Add"
                                            value={permissions[ManagementType.Plant][PlantManagementActions.Add]}
                                            onChange={handleChange('Plant', 'Add')}
                                        />
                                        <Checkbox id="plant_delete_edit" label="Delete"
                                            value={permissions[ManagementType.Plant][PlantManagementActions.Del]}
                                            onChange={handleChange('Plant', 'Del')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                    <div className={`m-2 py-2`}>
                        <div className={`d-flex flex-row justify-content-center`}>
                            <IxButton class="m-1" outline onClick={() => {
                                resetRoleForm();
                                setCreateUpdateRoleShow(false);
                            }}>
                                Cancel
                            </IxButton>
                            <IxButton type="submit" onClick={handleSubmit} class="m-1" variant="Primary">
                                Save
                            </IxButton>
                        </div>
                    </div>
                </div>
            </IxDrawer>
        </>
    );

}

const Checkbox = ({ id, label, value, onChange }: any) => {
    return (
        <div className={`d-flex align-items-center`}>
            <input id={id} type="checkbox" className={`form-control`} checked={value} onChange={onChange} />
            <label htmlFor={id} className={`text-default-title`}>{label}</label>
        </div>
    );
};

