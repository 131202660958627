import { Navigate, Route, Routes as Routers } from "react-router-dom";
import { IxBasicNavigation } from "@siemens/ix-react";
import SideBar from "../Sidebar/Sidebar";
import Dashboard from "../../Dashboard/Dashboard";
import UserManagement from "../../UserManagement/UserManagement";
import Organization from "../../UserManagement/Organization/Organization";
import Settings from '../../Settings/settings';
import Kpis from "../../Components/Kpis/Kpis";
import KPIView from "../../KPIView/KPIView";
import Alarms from "../../Components/Alarms/Alarms";
import styles from "./TopBar.module.scss";
import Maps from "../../Mapview/Maps";
import { useAppSelector } from "../../redux/hooks";
import { ManagementType, OrgManagementActions, PlantManagementActions, RolesEnum, UserManagementActions } from "../../Config/enums/RolesEnum";
import { useInject } from "../../hooks/useInject";
import RolesService from "../../Services/RolesService";

const TopBar = (): JSX.Element => {
  const loggedInUserRoles = useInject(RolesService).getLoggedInUserRole();
  const loggedInUserPermissions = useAppSelector(state => state.roleSlice.loggedInUserPermissions);
  return (
    <IxBasicNavigation className="header-background" applicationName="STP Analytics">
      <div className="placeholder-logo" slot="logo">
        <div className={`${styles.logo_color}`} >SIEMENS</div>
      </div>
      <SideBar />
      <div className={`w-100`}>
        <Routers>
          <Route path="/" element={<Maps />}></Route>
          <Route path="/Maps" element={<Maps />}></Route>
          {
            loggedInUserPermissions[ManagementType.Plant][PlantManagementActions.View]
              ? <Route path="/dashboard" element={<Dashboard />}></Route>
              : <Route path="*" element={<Navigate to="/" replace />}></Route>
          }
          {
            loggedInUserPermissions[ManagementType.Plant][PlantManagementActions.View]
              ? <Route path="dashboard/:idd/:id" element={<KPIView />}></Route>
              : <Route path="*" element={<Navigate to="/" />}></Route>
          }
          {
            loggedInUserPermissions[ManagementType.User][UserManagementActions.View]
              ? <Route path="/userManagement" element={<UserManagement />}></Route>
              : <Route path="*" element={<Navigate to="/" replace />}></Route>
          }
          {
            loggedInUserPermissions[ManagementType.Plant][PlantManagementActions.View]
              ? <Route path="/kpis/:plantId" element={<Kpis />}></Route>
              : <Route path="*" element={<Navigate to="/" replace />}></Route>
          }
          {
            loggedInUserPermissions[ManagementType.Plant][PlantManagementActions.View]
              ? <Route path="/Alarms" element={<Alarms />}></Route>
              : <Route path="*" element={<Navigate to="/" replace />}></Route>
          }
          {
            loggedInUserPermissions[ManagementType.Org][OrgManagementActions.View]
              ? <Route path="/Organization" element={<Organization />}></Route>
              : <Route path="*" element={<Navigate to="/" replace />}></Route>
          }
          {
            loggedInUserRoles.includes(RolesEnum.admin)
              ? <Route path="/settings" element={<Settings />}></Route>
              : <Route path="*" element={<Navigate to="/" replace />}></Route>
          }
          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routers>
      </div>
    </IxBasicNavigation>
  );
};

export default TopBar;
