import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavigationStateInterface {
    dasboardNavigatedThrough: string;
    alarmNavigatedThrough: string;
}

const initialState: NavigationStateInterface = {
    dasboardNavigatedThrough: 'Map',
    alarmNavigatedThrough: 'Dashboard'
}

const NavigaitonSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setDashboardNavigatedThrough: (state, action: PayloadAction<string>) => {
            state.dasboardNavigatedThrough = action.payload;
        },
        setAlarmNavigatedThrough: (state, action: PayloadAction<string>) => {
            state.alarmNavigatedThrough = action.payload;
        }
    }
});

export const { setDashboardNavigatedThrough, setAlarmNavigatedThrough } = NavigaitonSlice.actions;
export default NavigaitonSlice.reducer;