import {IxIcon, IxIconButton, IxInputGroup} from '@siemens/ix-react';
import React, {JSX} from 'react';

interface SearchProps {
    searchText: string;
    setSearchText: (searchText: React.SetStateAction<string>) => void;
}

export default (props: SearchProps): JSX.Element => {
    const {searchText, setSearchText} = props;

    function reset() {
        setSearchText('');
    }

    let display = searchText === '' ? 'none' : 'block';

    const handleChange = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setSearchText(event.target.value);
    };

    return (
        <form className="needs-validation">
            <IxInputGroup>
                <span slot="input-start">
                  <IxIcon name="search" size="16"></IxIcon>
                </span>
                <input
                    autoComplete="off"
                    spellCheck="false"
                    className={`form-control`}
                    id="input-string"
                    type="string"
                    onChange={handleChange}
                    value={searchText}
                />
                <span slot="input-end">
                    <IxIconButton
                        onClick={reset}
                        id="clear-button"
                        icon="clear"
                        ghost
                        size="16"
                        style={{display: display}}
                    ></IxIconButton>
                </span>
            </IxInputGroup>
        </form>
    );
};
