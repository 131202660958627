import { JSX, useEffect, useState } from "react";
import usePlantDetails from "../../hooks/usePlantDetails";
import Info from "./Info/Info";
import InfoType from "../../utilities/interfaces";
import styles from "./Infobar.module.scss";
import { useInject } from "../../hooks/useInject";
import PlantService from "../../Services/PlantService";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PlantInterface } from "../../Config/interfaces/PlantInterface";
import { setPlants } from "../../redux/slices/plantSlice";
import usePollAlarmsOnInterval from "../../hooks/usePollAlarmsOnInterval";
import AlarmBadge from "../../Shared/AlarmBadge/AlarmBadge";
import { NavigationEnum } from "../../Config/enums/NavigationEnum";

const Infobar = (): JSX.Element => {
  const plantService = useInject(PlantService);
  const plantList = useAppSelector((state) => state.plantSlice.plants);
  let selectedPlantName = localStorage.getItem("plantName") || "";
  const dispatch = useAppDispatch();
  const [userSelectedPlant, setUserSelectedPlant] = useState<PlantInterface | any>([]);
  const selectedPlantStatus = useAppSelector((state) => state.plantSlice.selectedPlantStatus);

  usePollAlarmsOnInterval();

  useEffect(() => {
    plantService.getPlants().then((plants: PlantInterface[]) => {
      plants?.length ? dispatch(setPlants(plants)) : dispatch(setPlants([]));
    });
  }, []);

  useEffect(() => {
    let selectedPlantObj: any = plantList.filter((plant: any) => plant.SubOrgName === selectedPlantName);
    setUserSelectedPlant(selectedPlantObj);
  }, [plantList]);

  let details: InfoType;
  details = { IotData: { value: "", name: "", displayName: "" }, id: 0 };

  const [plantDetails, setPlantDetails] = useState([details]),
    { isLoading, error }: any = usePlantDetails(setPlantDetails);    

  return (
    <section className="info-bar">
      <div
        className={`${styles.info_bar_content} d-flex flex-row justify-content-between flex-wrap p-3 mx-3 mt-1 mb-2`}
      >
        {!isLoading &&
          !error &&
          plantDetails &&
          userSelectedPlant &&
          userSelectedPlant?.[0] &&
          plantDetails.map((details) => {
            if (details.IotData.name === "STP_Name") {
              details.IotData.value = userSelectedPlant[0]?.SubOrgName;
            }
            if (details.IotData.name === "STP_PlantLocation") {
              details.IotData.value = userSelectedPlant[0]?.Location;
            }
            if (details.IotData.name === "STP_Type") {
              details.IotData.value = userSelectedPlant[0]?.PlantType;
            }
            if (details.IotData.name === "MLD") {
              details.IotData.value = userSelectedPlant[0]?.Capacity;
            }
            if (details.IotData.name === "STP_PlantStatus") {
              if (selectedPlantStatus) {
                details.IotData.value = "Active";
              } else details.IotData.value = "Inactive";
            }
            return <Info key={details.id} {...details} />;
          })}
        <div className={`${styles.alarms}`} >
          <AlarmBadge navigatedThrough={NavigationEnum.dashboard} />
        </div>
      </div>
    </section>
  );
};

export default Infobar;
