type plantObj = {
    ParentId: number;
    SuborgId: number;
    SubOrgName: string | number;
    IsPlant: boolean;
    IsActive: boolean;
    Location: string;
}

export function ixTreeHierarchyFormatter(apiData: any[], userAssignedPlants: string[], disablePlants: boolean = true): [string[], any] {
    const plantList: any = {};
    const parent: string[] = [];

    apiData.forEach((data: plantObj) => {
        const subOrgId = data?.SuborgId.toString();

        // parent level plants
        if (0 === data?.ParentId) {
            parent.push(subOrgId);
        }

        // find children
        const children: string[] = [];
        apiData.forEach((row: {
            SuborgId: any;
            ParentId: any; }) => {
            if (row?.ParentId === data?.SuborgId) {
                children.push(row?.SuborgId.toString());
            }
        });

        // prepare tree list
        if (typeof data?.SubOrgName === "string") {
            const enablePlantCondition = data?.IsPlant ?
                Array.isArray(userAssignedPlants) &&
                !!userAssignedPlants.length &&
                !userAssignedPlants.includes(data?.SubOrgName) : false;
            plantList[subOrgId] = {
                id: data?.SuborgId,
                data: {
                    id: data?.SuborgId,
                    subOrgName: data?.SubOrgName,
                    icon: data?.IsPlant ? 'plant' : 'location',
                    isPlant: data?.IsPlant,
                    isActive: data?.IsActive,
                    location: data?.Location,
                    isDisabled: !disablePlants ? disablePlants : enablePlantCondition
                },
                hasChildren: !!children.length,
                children: children
            };
        }
    });
    return [parent, plantList]
}

export function findParentsOfChildPlant(data: any[], childId: number) {
    const parentIds: number[] = [];

    const child = data.find((item) => item.SuborgId === childId);
    if (!child) {
        return parentIds;
    }

    let parentId = child?.ParentId;
    while(parentId !== 0) {
        parentIds.push(parentId);
        const parent = data.find((item => item.SuborgId === parentId));
        parentId = parent.ParentId;
    }

    return parentIds;
}

export function getContextTreeToExpand(parents: any[], childExpandedInTreeContext: number): any {
    if (childExpandedInTreeContext !== 0) {
        parents.unshift(childExpandedInTreeContext);
    }

    const contextTreeToExpand: any = {
        plants: {
            isExpanded: true,
            isSelected: false,
        }
    };

    parents.reverse().forEach(item => {
        contextTreeToExpand[item] = {
            isExpanded: true,
            isSelected: false,
        };
    });

    return contextTreeToExpand;
}