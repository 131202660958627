import styles from "./Tabulardata.module.scss";
import { getQualityAttributeResult } from "../../utilities/converters";

export default function Tabulardata({ data, searchText, tabularData }: { data: any; searchText: any; tabularData:any }): JSX.Element {
  
  const getClassName = (val: any): string =>{
    const valueUpdatedData = JSON.parse(JSON.stringify(data));
    valueUpdatedData.IotData.value = val;
    return getQualityAttributeResult(valueUpdatedData, true);
  }

  const filtered = tabularData.length ? tabularData.filter((data: any) => searchText == null || searchText === '' || data[1] == searchText) : [];

  return (
    <div className={`m-3 ${styles.tabular_view}`}>
      <table className="table">
        <thead>
        <tr>
          <th scope="col">
            <div className={`text-l`}>Date & time</div>
          </th>
          <th scope="col" className={`d-flex flex-row align-items-baseline`}>
            <div className={`text-l`}>{data?.["IotData"]?.["displayName"]}</div>
            <div className={`text-s ms-2`}>{data?.measurement}</div>
          </th>
        </tr>
        </thead>
        <tbody>
        {
          filtered?.length > 0 ?
              filtered.map((dat: any, i: number) => {
                let date = new Date(dat[0]);
                return (
                    <tr key={i}>
                      <td>{date.toLocaleString()}</td>
                      <td className={`${getClassName(dat?.[1])}`}>{dat?.[1]?.toFixed(2)}</td>
                    </tr>
                );
              }).reverse()
              :
              <tr>
                <td colSpan={2}>No Results found</td>
              </tr>
        }
        </tbody>
      </table>
    </div>
  );
}