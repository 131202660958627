// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alarms_alarms_table__Mx5D2 {
  height: 75vh;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/Components/Alarms/Alarms.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,cAAc,EAAA","sourcesContent":[".alarms_table {\n    height: 75vh;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alarms_table": `Alarms_alarms_table__Mx5D2`
};
export default ___CSS_LOADER_EXPORT___;
