import { FC, useRef } from "react";
import { IxButton, IxIconButton, Modal, ModalRef } from "@siemens/ix-react";

const ConfirmModal: FC<{
  id?: number;
  index?: number;
  params?: any;
  onAction: (actionType: string, index?: number, id?: number, params?: any) => void;
  confirmationText: string | (string | JSX.Element)[];
  confirmationSubText?: string | (string | JSX.Element)[];
}> = (props): JSX.Element => {
  const modalRef = useRef<ModalRef>(null);

  const close = () => {
    modalRef.current?.close('close payload!');
    props.onAction('yes', props?.index, props?.id, props?.params);
  };
  const dismiss = () => {
    modalRef.current?.dismiss('dismiss payload');
    props.onAction('no');
  };

  return (
    <div>
      <Modal ref={modalRef}>
        <div className="modal-header">
          <span>{props.confirmationText}</span>
          <IxIconButton
              data-button-close
              ghost
              icon="close"
              onClick={() => dismiss()}
          ></IxIconButton>
        </div>
        <div className="modal-body" dangerouslySetInnerHTML={{__html: props.confirmationSubText || "Are you sure you want to delete?"}}></div>
        <div className="modal-footer">
          <IxButton outline onClick={() => dismiss()}>
            Cancel
          </IxButton>
          <IxButton onClick={() => close()}>Yes</IxButton>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
