import { useEffect, useState } from 'react';
import { IxIcon, IxTree } from '@siemens/ix-react';
import {
    findParentsOfChildPlant,
    getContextTreeToExpand,
    ixTreeHierarchyFormatter
} from "../../utilities/ixTreeHierarchyFormatter";
import styles from "./TreeView.module.scss";
import { useInject } from "../../hooks/useInject";
import PlantService from "../../Services/PlantService";
import { useAppSelector } from '../../redux/hooks';

const defaultTreeModel = {
    root: {
        id: 'root',
        data: {
            subOrgName: '',
            icon: ''
        },
        hasChildren: true,
        children: ['plants']
    },
    plants: {
        id: 'plants',
        data: {
            id: 0,
            subOrgName: 'Organization', // Parent Org is set here dynamically
            icon: 'hierarchy'
        },
        hasChildren: true,
        children: [] // Keys are added dynamically here
    }
};

const TreeStructure = ({
    userHasViewOrgPerm,
    allOrgData,
    setOrganizationInfo
}) => {
    const plantService = useInject(PlantService);
    const userAssignedPlants = useAppSelector(state => state.keycloakSlice.userAssignedPlants);
    const [context, setContext] = useState();
    const [model, setModel] = useState(defaultTreeModel);
    const [parents, setParents] = useState([]);
    const [childExpandedInTreeContext] = useState(0);

    useEffect(() => {
        plantService.getOrganization()
            .then(res => {
                defaultTreeModel.plants.data.subOrgName = res[0].OrgName;
            })
            .catch(err => {
                console.log("Could not retrieve messages", err)
            });
    }, []);

    useEffect(() => {
        const plantsTreeData = ixTreeHierarchyFormatter(allOrgData, userAssignedPlants, false);
        defaultTreeModel.plants.children = plantsTreeData[0];
        const treeData = { ...defaultTreeModel, ...plantsTreeData[1] };
        setModel(treeData);
    }, [allOrgData, userAssignedPlants]);

    useEffect(() => {
        collapseTree();
        const timeoutId = setTimeout(() => {
            expandTreeAndSelect();
        }, 20);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [model]);

    useEffect(() => {
        const parents = findParentsOfChildPlant(allOrgData, childExpandedInTreeContext);
        setParents(parents);
    }, [childExpandedInTreeContext]);

    function expandTreeAndSelect() {
        const contextTreeToExpand = getContextTreeToExpand(parents, childExpandedInTreeContext);

        setContext(contextTreeToExpand);
    }

    function collapseTree() {
        setContext({
            plants: {
                isExpanded: false,
                isSelected: true,
            }
        });
    }

    return !Object.keys(model).length  || !userHasViewOrgPerm? (
        <div className="default-text m-3">Organization details not available.</div>) : (
        <div
            className={`ms-3 mt-2 d-flex flex-row justify-content-start`}
            style={{
                display: 'block',
                position: 'relative',
                width: '400px',
                height: '48vh',
            }}
        >
            <IxTree
                id={`org_tree_view`}
                root="root"
                model={model}
                context={context}
                onContextChange={({ detail }) => {
                    setContext(detail);
                }}
                renderItem={(plantData) => (
                    <div onClick={() => setOrganizationInfo(plantData)}
                        className={`d-flex align-items-center justify-content-between ${styles.tree_item} ${plantData.isDisabled ? styles.plant_disabled : ''}`}>
                        <div className={`d-flex align-items-center`}>
                            <IxIcon name={plantData.icon} size="16" className="me-2" /> {plantData.subOrgName}
                        </div>
                    </div>
                )}
            ></IxTree>
        </div>
    );
}

export default TreeStructure;