import ConfigService from "./ConfigService";

class InsightsService extends ConfigService {
    async getGraphs(selectedPlantId: number | undefined): Promise<any> {
        if (!selectedPlantId) {
            return false;
        }
        const url = this.serverBaseUrl + '/api/trendData/' + selectedPlantId;

        try {
            const response = await fetch(url, {
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getScatterPlotData(parameters: any, startTime: any, endTime: any): Promise<any> {
        if (!parameters || !startTime || !endTime) {
            return false;
        }
        const url = this.serverBaseUrl + '/api/actualData';
        const payload = {parameters, startDate: startTime, endDate: endTime};

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: this.headersList,
                body: JSON.stringify(payload),
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getHeatMapData(payload: any) {
        if (!Object.keys(payload).length) {
            return false;
        }
        const url = this.serverBaseUrl + '/api/correlation';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: this.headersList,
                body: JSON.stringify(payload)
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async createInsightsGraph(payload: any) {
        const url: string = this.serverBaseUrl + "/api/trendData";

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: this.headersList
            });

            if (response.status === 500) {
                // Handle the 500 Internal Server Error
                throw new Error('Internal Server Error');
            }

            if (!response.ok) {
                // Handle other non-500 HTTP errors
                throw new Error('Request failed with status: ' + response.status);
            }

            return await response.json();
        } catch (error: any) {
            console.error('API call failed:', error);
        }
    }

    async updateInsightsGraph(id: number, payload: any) {
        const url: string = this.serverBaseUrl + "/api/trendData/" + id;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: this.headersList
            });

            if (response.status === 500) {
                // Handle the 500 Internal Server Error
                throw new Error('Internal Server Error');
            }

            if (!response.ok) {
                // Handle other non-500 HTTP errors
                throw new Error('Request failed with status: ' + response.status);
            }

            return response;
        } catch (error: any) {
            console.error('API call failed:', error);
        }
    }
}

export default InsightsService;