// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Organization_uploaded_org_img__YpBog img {
  height: auto;
  width: 150px;
  box-shadow: var(--theme-shadow-3); }

.Organization_plant_details__Zq5t3 {
  border: var(--theme-weak-bdr-1); }

.Organization_organization_tab__xiiF- {
  display: flex !important; }

.Organization_layout__b0ysA {
  width: 100%;
  margin: 10px; }

.Organization_buttonsBar__OT01j {
  display: flex;
  float: right;
  padding: 10px; }

.Organization_infobar__oARHF {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 30px;
  margin-bottom: 50px;
  padding: 10px;
  display: flex;
  width: 96%; }

.Organization_infobarDiv__EA4kW {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 10px 30px;
  /* Adjust the margin as needed for spacing */ }

.Organization_infobarDiv__EA4kW p {
  font-weight: bold;
  font-size: 15px;
  /* Adjust the font size as needed */
  margin: 0;
  /* Remove default margin */
  margin-bottom: 10px; }

.Organization_infobarDiv__EA4kW p:last-child {
  font-weight: normal;
  font-size: 14px;
  /* Adjust the font size as needed */ }
`, "",{"version":3,"sources":["webpack://./src/UserManagement/Organization/Organization.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,iCAAiC,EAAA;;AAGnC;EACE,+BAA+B,EAAA;;AAEjC;EACE,wBAAwB,EAAA;;AAE1B;EACE,WAAW;EACX,YAAY,EAAA;;AAEd;EACE,aAAa;EACb,YAAY;EACZ,aAAa,EAAA;;AAEf;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,UAAU,EAAA;;AAEZ;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,iBAAiB;EAAG,4CAAA,EAA6C;;AAGnE;EACE,iBAAiB;EACjB,eAAe;EAAG,mCAAA;EAClB,SAAS;EAAG,0BAAA;EACZ,mBAAmB,EAAA;;AAGrB;EACE,mBAAmB;EACnB,eAAe;EAAG,mCAAA,EAAoC","sourcesContent":[".uploaded_org_img img {\n  height: auto;\n  width: 150px;\n  box-shadow: var(--theme-shadow-3);\n}\n\n.plant_details {\n  border: var(--theme-weak-bdr-1);\n}\n.organization_tab {\n  display: flex !important;\n}\n.layout {\n  width: 100%;\n  margin: 10px;\n}\n.buttonsBar {\n  display: flex;\n  float: right;\n  padding: 10px;\n}\n.infobar {\n  margin-top: 50px;\n  margin-left: 20px;\n  margin-right: 30px;\n  margin-bottom: 50px;\n  padding: 10px;\n  display: flex;\n  width: 96%;\n}\n.infobarDiv {\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  margin: 10px 30px;  /* Adjust the margin as needed for spacing */\n}\n\n.infobarDiv p {\n  font-weight: bold;\n  font-size: 15px;  /* Adjust the font size as needed */\n  margin: 0;  /* Remove default margin */\n  margin-bottom: 10px; \n}\n\n.infobarDiv p:last-child {\n  font-weight: normal;\n  font-size: 14px;  /* Adjust the font size as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploaded_org_img": `Organization_uploaded_org_img__YpBog`,
	"plant_details": `Organization_plant_details__Zq5t3`,
	"organization_tab": `Organization_organization_tab__xiiF-`,
	"layout": `Organization_layout__b0ysA`,
	"buttonsBar": `Organization_buttonsBar__OT01j`,
	"infobar": `Organization_infobar__oARHF`,
	"infobarDiv": `Organization_infobarDiv__EA4kW`
};
export default ___CSS_LOADER_EXPORT___;
