import { configureStore } from "@reduxjs/toolkit";
import keycloakReducer from "./slices/keycloakSlice";
import roleReducer from "./slices/roleSlice";
import userReducer from "./slices/userSlice";
import plantReducer from "./slices/plantSlice";
import kpiReducer from "./slices/kpiSlice";
import timeSeriesSlice from "./slices/timeSeriesSlice";
import alarmSlice from "./slices/alarmSlice";
import insightsReducer from './slices/insightsSlice';
import navigationSlice from "./slices/navigationSlice";
import settingsReducer from "./slices/settingsSlice";

export const store = configureStore({
  reducer: {
    keycloakSlice: keycloakReducer,
    roleSlice: roleReducer,
    userSlice: userReducer,
    plantSlice: plantReducer,
    kpiSlice: kpiReducer,
    insightsSlice: insightsReducer,
    timeseriesSlice: timeSeriesSlice,
    alarmSlice: alarmSlice,
    navigationSlice: navigationSlice,
    settingsSlice: settingsReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
