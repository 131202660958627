import {CSSProperties, ReactElement, useState} from "react";
import {IxPill} from "@siemens/ix-react";

type statusType = {
    isActive: boolean;
    textStyleClass?: string;
    textColorClass?: string;
}

const ShowStatus = (props: statusType): ReactElement => {
    const styled: CSSProperties = {
        width: '4rem',
    };

    const [variant] = useState(props.isActive ? 'success' : 'alarm');

    return (
        <div>
            {
                props.isActive ?
                    <IxPill key={1} variant={'success'} style={styled}>Active</IxPill>
                    : <IxPill key={2} variant={'alarm'} style={styled}>Inactive</IxPill>
            }
        </div>
    );
}

export default ShowStatus;