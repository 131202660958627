// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_tabBox__BJyoG {
  min-height: 5vh; }
`, "",{"version":3,"sources":["webpack://./src/Dashboard/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAe,EAAA","sourcesContent":[".tabBox {\n    min-height: 5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabBox": `Tabs_tabBox__BJyoG`
};
export default ___CSS_LOADER_EXPORT___;
