// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TreeView_plant_disabled__uxWoW {
  opacity: 0.5;
  cursor: default; }

.TreeView_tree_item__i4qNm {
  width: 100%;
  border: 1px solid var(--theme-critical-bdr-2); }
`, "",{"version":3,"sources":["webpack://./src/UserManagement/Organization/TreeView.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe,EAAA;;AAGjB;EACE,WAAW;EACX,6CAA6C,EAAA","sourcesContent":[".plant_disabled {\n  opacity: 0.5;\n  cursor: default;\n}\n\n.tree_item {\n  width: 100%;\n  border: 1px solid var(--theme-critical-bdr-2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plant_disabled": `TreeView_plant_disabled__uxWoW`,
	"tree_item": `TreeView_tree_item__i4qNm`
};
export default ___CSS_LOADER_EXPORT___;
