import { FC, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { IxSpinner } from '@siemens/ix-react';
import { IxBlind, IxDropdown, IxDropdownItem, IxIconButton, showModal, showToast, IxButton, IxIcon } from "@siemens/ix-react";
import uniqueId from "../../utilities/uniqueId";
import {useInject} from "../../hooks/useInject";
import InsightsService from "../../Services/InsightsService";
import {useAppSelector} from "../../redux/hooks";
import { ToastType } from "../../Config/constants/ToastMessage";

const HeatMap: FC<{
    graphDetails: any;
    deleteGraph: (id: number, graphName: string) => void;
    editGraph: (id: number) => void;
    createNewTrend: (payload: any) => void;
}> = (props) => {
    const insightsService = useInject(InsightsService);
    const parametersList = useAppSelector(state => state.insightsSlice.parametersList);
    const parameters = props.graphDetails.parameters;
    
    //const liveData = props.graphDetails.liveData;
    const startTime = props.graphDetails.timeRange.startDateTime;
    const endTime = props.graphDetails.timeRange.endDateTime;
    const chartTitle = props.graphDetails.graphName;

    const [selectedKpiLabels, setSelectedKpiLabels] = useState<{id: number, name: string}[]>([]);
    const [seriesData, setSeriesData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uniqueChartId] = useState(uniqueId());
    const selectedPlantId = useAppSelector(state => state.plantSlice.selectedPlantId);
    const [showScatterPlotButton, setShowScatterPlotButton] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    const [clickedPosition, setClickedPosition] = useState({x: 0, y: 0});
    const themeMode = useAppSelector(state => state.settingsSlice.theme);

    const loadingOption = {
        text: 'Loading...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        zlevel: 0
    }

    useEffect(() => {
        fetchCorrelationData().then(r => r);
    }, [parameters, startTime, endTime]);

    const fetchCorrelationData = async () => {
        try {
            let payload;
            // if(liveData){
            //     payload = {
            //         plantId: deviceId,
            //         tags: parameterList,
            //     }
            // } else {
            payload = {
                parameters,
                fromTime: startTime,
                toTime: endTime,
            }
            // }
            setIsLoading(true);
            const dbResponse = await insightsService.getHeatMapData(payload);

            const heatMapData = dbResponse?.data?.length ? dbResponse?.data : [];
            const labelsWithKeys = dbResponse?.tags?.length ? dbResponse?.tags.map((kpi: number) => {
                return {id: kpi, name: parametersList[kpi]};
            }) : [];

            setSelectedKpiLabels(labelsWithKeys);
            setSeriesData(heatMapData);
        }
        catch (error) {
            console.error('Error fetching trend data', error);
        } finally {
            setIsLoading(false);
        }
    }

    
    const handleClick = async (params: any) => {
        if (params.componentType === 'series') {
            const { offsetX, offsetY } = params.event;
            setButtonPosition({
                top: offsetY - 70, 
                left: offsetX - 80,
            });
            
            if (params?.data && params.data.length && (params.data[0] !== params.data[1])) {
                const pos = {x : 0,y: 0};
                pos.x = params.data[0];
                pos.y = params.data[1]; 
                setClickedPosition(pos)
            }
            setShowScatterPlotButton(true);
          }
    };

    const onGenerateScatterPlot = async () => {
        if (!selectedKpiLabels?.length) {
            return;
        }

        const xParam = selectedKpiLabels?.[clickedPosition?.x];
        const yParam = selectedKpiLabels?.[clickedPosition?.y];

        if (!xParam?.id || !yParam?.id) {
            return;
        }

        try {
            const timestamp = new Date();
            const graphName = `${xParam?.name} vs ${yParam?.name}`;
            const trendMetaInfo = {
                graphName : graphName,
                graphType : 'scatter',
                timeRange : {
                    startDateTime : startTime,
                    endDateTime : endTime
                },
                xParameter : [xParam.id],
                yParameter : [yParam.id]
            }
            const payload = {
                plantId : selectedPlantId,
                trendMetaInfo : trendMetaInfo,
                timestamp : timestamp
            }            
            props.createNewTrend(payload);
        } catch (error) {
            showToast({
                type: ToastType.success,
                title: '',
                message: "Failed"
            }).then(r => r);
            console.error("Error in createNewTrend", error);
        }
        setShowScatterPlotButton(false);
    }

    const onCloseButton = () => {
        setShowScatterPlotButton(false);
    }

    const options = {
        backgroundColor: '#ffffff00',
        tooltip: {
            position: 'top',
            formatter: (params: any) => {
                return 'Correlation: ' + params.value[2].toFixed(2);
            }
        },
        grid: {
            height: '80%',
            top: '0%',
            left: '5%',
            right: '5%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0,
                rotate: 30
            },
            data: selectedKpiLabels?.map(kpi => kpi?.name),
        },
        yAxis: {
            type: 'category',
            data: selectedKpiLabels?.map(kpi => kpi?.name),
            splitArea:
            {
                show: true,
            },
        },
        toolbox: {
            right: 10,
            itemSize: 20,
            itemGap: 10,
            top: 0,
            iconStyle: {
                color: "rgba(255, 255, 255, 0)",
                borderColor: "#0cc",
                borderWidth: 2
            },
            feature: {
                saveAsImage: {show: true},
            },
        },
        visualMap: {
            orient: 'horizontal',
            left: 'center',
            bottom: 20,
            dimension: 2,
            min: -1,
            max: 1,
            text: ['HIGH', 'LOW'],
            calculable: true,
            inRange: {
                color: ['#d94e5d', '#eac736', '#50a3ba', '#1d4877', '#1b8a5a']
            }
        },
        series: [{
            name: 'HeatMap',
            type: 'heatmap',
            data: seriesData,
            label: {
                show: true
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
        },
        ],
    };

    return (
        <div className={`mt-2 mb-2`}>
            <IxBlind label={chartTitle} className={`w-100`}>
                <IxIconButton
                    id={uniqueChartId}
                    slot="header-actions"
                    ghost
                    icon="context-menu"
                ></IxIconButton>
                <span className={isLoading ? "center-align": 'normal'} style={{width: '100%', height: '65vh', display: 'grid', alignItems: 'center', position:'relative'}}>
                    {isLoading ? (
                        <IxSpinner size="large"></IxSpinner>
                    ):(
                        <ReactEcharts
                            option={options}
                            style={{ height: '65vh' }}
                            lazyUpdate={true}
                            loadingOption={loadingOption}
                            onEvents={{click: handleClick}}
                            theme={themeMode}
                        />
                    )}
                    {showScatterPlotButton && (
                    <div
                        style={{
                            position: 'absolute',
                            top: buttonPosition.top,
                            left: buttonPosition.left,
                            zIndex: 2,
                        }}>
                        <IxButton className="m-1" onClick={onGenerateScatterPlot} variant="Secondary" outline>
                            <IxIcon name="add"></IxIcon>Generate Scatter Plot
                        </IxButton>
                        <IxIconButton onClick={onCloseButton} class="m-1" variant="Secondary" icon="close-small" outline></IxIconButton>
                    </div>
              )}
                </span>
            </IxBlind>
            <IxDropdown className={`me-4`} trigger={uniqueChartId}>
                <IxDropdownItem icon="pen" onClick={() => props.editGraph(props?.graphDetails?.id)}>Edit Graph</IxDropdownItem>
                <IxDropdownItem icon="trashcan" onClick={() => props.deleteGraph(props?.graphDetails?.id, props?.graphDetails?.graphName)}>Delete</IxDropdownItem>
            </IxDropdown>
        </div>
    )

}

export default HeatMap;