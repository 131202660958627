import ConfigService from "./ConfigService";
import { KpisConstant } from "../Config/constants/KpisConstant";
import {Categories, PlantKpisFormInterface} from "../Config/interfaces/KpiInterface";

class KpiService extends ConfigService {
    setIsLoading: (isLoading: boolean) => {};

    constructor(setIsLoading?: any) {
        super();
        this.setIsLoading = setIsLoading;
    }

    async getKpiCategories(selectedPlantId: number = 0): Promise<any> {
        const url: string = this.serverBaseUrl + KpisConstant.getKpiCategoriesApi + '/' + selectedPlantId;
        try {
            this.setIsLoading(true);
            const response = await fetch(url, {
                headers: this.headersList
            });

            return await response?.json();
        } catch (err) {
            console.error('API call failed: ', err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async addKpiCategory(categoryDetails: Categories): Promise<any> {
        if (!categoryDetails?.name || !categoryDetails?.code) {
            return false;
        }

        const url: string = this.serverBaseUrl + KpisConstant.addKpiCategoryApi;
        try {
            this.setIsLoading(true);
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(categoryDetails),
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async getKpis(plantId: number | null): Promise<any> {
        if (!plantId || isNaN(plantId)) {
            return [];
        }

        const url: string = this.serverBaseUrl + KpisConstant.getKpisApi + '/' + plantId;
        try {
            this.setIsLoading(true);
            const response = await fetch(url, {
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async saveKpis(formData: PlantKpisFormInterface): Promise<any> {
        const url: string = this.serverBaseUrl + KpisConstant.saveKpisApi;

        if (!formData?.plantId) {
            return null;
        }

        this.setIsLoading(true);

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: this.headersList
            });

            if (response.status === 500) {
                // Handle the 500 Internal Server Error
                throw new Error('Internal Server Error');
            }

            if (!response.ok) {
                // Handle other non-500 HTTP errors
                throw new Error('Request failed with status: ' + response.status);
            }

            return await response.json();
        } catch (error: any) {
            throw new Error(error.message);
        } finally {
            this.setIsLoading(false);
        }
    }

    async deleteKpi(kpiId: number | null): Promise<any> {
        if (kpiId === null || isNaN(kpiId)) {
            return false;
        }

        const url: string = this.serverBaseUrl + KpisConstant.deleteKpisApi + kpiId;
        try {
            this.setIsLoading(true);
            const response = await fetch(url, {
                method: 'DELETE',
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async getTagNamesForKPIs(plantName : string) : Promise<any> {
        if(!plantName){
            return null;
        }

        const url: string = this.serverBaseUrl + '/api/getTagsForKPIs/' + plantName;
        try {
            this.setIsLoading(true);
            const response = await fetch(url, {
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        } finally {
            this.setIsLoading(false);
        }
    }
}

export default KpiService;