export const optionsData = {
    backgroundColor: '#ffffff00',
    grid: {top: 80, right: 0, bottom: 90, left: 60, width: "99%"},
    xAxis: {
        type: "category",
        boundaryGap: false,
        scale: true,
        axisLabel: {
            show: true,
            hideOverLap: true,
            margin: 12,
        },
        splitLine: {
            show: false
        },
        name: "Timestamp",
        nameLocation: "middle",
        nameTextStyle: {
            verticalAlign: "top",
            lineHeight: 35,
            fontWeight: "bold"
        },
        lineStyle: {
            width: 1.5
        },
        axisTick: {
            alignWithLabel: true
        },
    },
    yAxis: {
        type: "value",
        axisLine: {
            show: true
        },
        lineStyle: {
            width: 1.5
        }
    },
    legend: {
        show: true,
        orient: "horizontal",
        left: "center",
        top: "6%",
        padding: [10, 10, 10, 5],
        icon: "roundRect",
        selectedMode: true,
        textStyle: {
            color: '#afafac',
            lineHeight: 14,
            overflow: "break",
            fontSize: 12
        }
    },
    toolbox: {
        left: "right",
        itemSize: 20,
        itemGap: 10,
        top: 0,
        iconStyle: {
            color: "rgba(255, 255, 255, 0)",
            borderColor: "#0cc",
            borderWidth: 1.5
        },
        feature: {
            dataZoom: {
                show: false,
                yAxisIndex: "none",
            },
            magicType: {
                type: ["line", "bar"],
            },
            saveAsImage: {show: true},
        },
    },
    dataZoom: [
        {
            type: "inside",
            start: 0,
            end: 100,
        },
        {
            start: 0,
            end: 100,
        },
    ],
    series: [],
    tooltip: {
        trigger: "axis",
    },
};