// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards_filter_box__9Pit0 {
  height: 70vh;
  width: 23vw;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/Dashboard/Cards/Cards.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,cAAc,EAAA","sourcesContent":[".filter_box {\n  height: 70vh;\n  width: 23vw;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter_box": `Cards_filter_box__9Pit0`
};
export default ___CSS_LOADER_EXPORT___;
