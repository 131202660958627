import { IxDivider, IxIcon } from "@siemens/ix-react";
import styles from "./Alarms.module.scss";
import { useAppSelector } from "../../redux/hooks";
import BreadCrumb from "../../Shared/Breadcrumb/BreadCrumb";
import useSetPlant from "../../hooks/useSetPlant";
import { getFormattedDateTimeReadable } from "../../utilities/converters";
import { AlarmEnum } from "../../Config/enums/AlarmsEnum";
import usePollAlarmsOnInterval from "../../hooks/usePollAlarmsOnInterval";
import { NavigationEnum } from "../../Config/enums/NavigationEnum";

const Alarms = () => {
  const alarms = useAppSelector(state => state.alarmSlice.alarms);
  const selectedPlant = useAppSelector(state => state.plantSlice.selectedPlant);
  const selectedPlantStatus = useAppSelector(state => state.plantSlice.selectedPlantStatus);
  const alarmNavigatedThrough = useAppSelector(state => state.navigationSlice.alarmNavigatedThrough);
  const dashboardNavigatedThrough = useAppSelector(state => state.navigationSlice.dasboardNavigatedThrough);

  useSetPlant();
  usePollAlarmsOnInterval();

  return (
    <div className={`mx-3`}>
      <div className={`d-flex flex-row flex-wrap justify-content-start align-items-center w-100`}>
        <BreadCrumb itemString={`${dashboardNavigatedThrough},${selectedPlant} - ${alarmNavigatedThrough}, ${NavigationEnum.alarms}`}></BreadCrumb>
        <div className="ms-auto me-3">
          <div className="text-l-title d-flex flex-row align-items-center text-center">
            {selectedPlantStatus ? (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="color-success me-1"
                  viewBox="0 0 16 16"
                >
                  <circle cx="6" cy="6" r="6" />
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="color-alarm me-1"
                  viewBox="0 0 16 16"
                >
                  <circle cx="6" cy="6" r="6" />
                </svg>
              </div>
            )}
            <div className={`text-default-title`}>{selectedPlant}</div>
          </div>
        </div>
      </div>
      <section
        className={`d-flex flex-row justify-content-between align-items-center`}
      >
        <h2 className="text-h2">
          <span>Alarms</span>
        </h2>
      </section>

      <div className="d-flex flex-row">
      {
        !!Object.keys(alarms)?.length && Object.keys(alarms).map((alarm: string, i: number) => (
          <section key={alarm} className={`mt-2 me-3 w-50`}>
            {
              alarm === AlarmEnum.actual ? (
                <span className={`d-flex align-items-center text-xl color-alarm`}>
                  <IxIcon className="me-1" name="alarm-bell" size="24"></IxIcon>
                  <span>Actual</span>
                </span>
              ) : (
                <span className={`d-flex align-items-center text-xl color-warning`}>
                  <IxIcon className="me-1" name="alarm-clock" size="24"></IxIcon>
                  <span>Forecasted</span>
                </span>
              )
            }

            <IxDivider></IxDivider>
            {
              !!alarms[alarm]?.length ? (
                <div className={`${styles.alarms_table}`}>
                  <table className={`mt-2 table`}>
                    <thead>
                      <tr>
                        <th scope="col">Timestamp</th>
                        <th scope="col">Message</th>
                      </tr>
                    </thead>
                    <tbody className={``}>
                      {alarms[alarm]?.map((message: any) => (
                        <tr key={message?.id}>
                          <td>
                            {message?.timestamp &&
                              getFormattedDateTimeReadable(message.timestamp)}
                          </td>

                          <td className={`d-flex flex-row align-items-center`}>
                            <IxIcon name="alarm" size="16"></IxIcon>
                            <span className={`ms-2`}>{message?.message}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={`text-default text-gray py-2`}>
                  {
                    i.toString() === AlarmEnum.actual ? (
                      <span>Alarms not found!</span>
                    ) : (
                      <span>Forecasted warnings not found!</span>
                    )
                  }
                </div>
              )
            }
          </section>
        ))
      }
      </div>
    </div>
  );
};

export default Alarms;
