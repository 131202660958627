import { useEffect } from "react";

export function useDatePickerDetectClick(
    inputRef: any,
    datePickerRef: any,
    setShowDatePicker: (arg0: boolean) => void): void {

    useEffect(() => {
        const detectClickOutsideDatePickerDiv = (e: MouseEvent) => {
            if (inputRef.current && (inputRef.current.contains(e.target as Node))) {
                return;
            }

            if (!datePickerRef.current) {
                return;
            }

            if (!datePickerRef.current.contains(e.target as Node)) {
                setShowDatePicker(false);
            }
        };

        window.addEventListener('click', detectClickOutsideDatePickerDiv);

        return () => {
            window.removeEventListener('click', detectClickOutsideDatePickerDiv);
        }
    }, [datePickerRef, inputRef, setShowDatePicker]);
}