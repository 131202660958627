export const heatMapFormDefaultValues = {
    graphName: '',
    graphType: 'heatmap',
    // livedata: false,
    timeRange: {
        startDateTime: '',
        endDateTime: ''
    },
    parameters: []
}

export const heatMapFormConstant = [
    /*{
        type: "checkbox",
        label: "Live Data",
        name: "livedata",
        readonly: false,
        rules: {required: false},
    },*/
    {
        type: "dateRangePicker",
        placeholder: "",
        label: "Time Range",
        name: "timeRange",
        fields: [
            {
                type: "startDate",
                datePlaceholder: "Start date",
                timePlaceholder: "Start time",
                label: "",
                name: "startDateTime",
                rules: {required: true}
            },
            {
                type: "endDate",
                datePlaceholder: "End date",
                timePlaceholder: "End time",
                label: "",
                name: "endDateTime",
                rules: {required: true}
            }
        ]
    },
    {
        type: "select",
        placeholder: "",
        label: "Parameters",
        name: "parameters",
        rules: {required: true},
        options: [],
        mode: "multiple",
        readonly: false
    }
];