import Service from "./Service";

const Routes = (): JSX.Element => {
  return (
    <>
      <Service />
    </>
  );
};

export default Routes;
