import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useInject } from '../../hooks/useInject';
import { IxPill } from '@siemens/ix-react';
import ReactMarkdown from 'react-markdown';
import PlantKpiService from '../../Services/PlantKpiService';
import styles from './OpenAI.module.scss';
import Spinner from '../../Shared/Spinner/Spinner';

const OpenAIAnalyzer = (chartData: any) => {
    const plantKpiService = useInject(PlantKpiService);
    const [data, setData] = useState(chartData);
    const [screenshot, setScreenshot] = useState('');
    const [file, setFile] = useState<File>();
    const [response, setResponse] = useState('');
    const [isLoading, setLoader] = useState(false);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
        },
        onDrop: (acceptedFiles: any) => {
            const file = acceptedFiles[0];
            if (file) {
                setFile(undefined);
                setResponse('');
            }
            setFile(file);
            setScreenshot(file);
        }
    });

    const handleSubmit = async () => {
        setLoader(true);
        let newData = new FormData();
        if (screenshot) {
            newData.append('image', screenshot);
        }

        try {
            const res = await plantKpiService.analyzeChartData(screenshot ? newData : JSON.stringify(data));
            setResponse((prevResponse) => prevResponse + res);
        } catch (error) {
            console.error('Error:', error);
        }
        setLoader(false);
        setScreenshot('');
        setFile(undefined);
    };

    return (
        <div className={`${styles.openaiAnalysis} col-12`}>
            <div className={`col-6`}>
                {
                    !file && (
                        <textarea
                            readOnly
                            rows={10}
                            cols={70}
                            placeholder="Enter data here..."
                            value={JSON.stringify(data)}
                            onChange={(e) => setData(e.target.value)}
                        />
                    )
                }
                <br />
                <div {...getRootProps()}>
                    <input type='file' {...getInputProps()} />
                    <p>
                        <IxPill outline key="drag-drop">Drag 'n' drop or click to select a screenshot here</IxPill>
                    </p>
                </div>
                {
                    file && (
                        <div>
                            <img src={URL.createObjectURL(file)} className='col-9' height={300} alt='screenshot' /><br />
                            <IxPill className='ps-2 pe-2 mt-2' outline variant='neutral' key={file.name} onClick={() => { setFile(undefined); setScreenshot(''); }} defaultValue={file.name}>{file.name}</IxPill>
                        </div>
                    )
                }
                <br />
                <button className='btn btn-primary' onClick={handleSubmit}>Get summary</button>
            </div>
            <div className='col-6'>
                {
                    isLoading ? <Spinner /> :
                        response && (
                            <div>
                                <h4>Analysis Summary by OpenAI:</h4>
                                {
                                    <div className={`${styles.responseDiv} mt-3`}>
                                        <ReactMarkdown>{response}</ReactMarkdown>
                                    </div>
                                }
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default OpenAIAnalyzer;
