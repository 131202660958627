import InfoType from '../../../utilities/interfaces';
import styles from './Info.module.scss';
import { ReactElement } from "react";
import ShowStatus from "../../../Shared/UI-Elements/ShowStatus/ShowStatus";

const Info = (details: InfoType): ReactElement => {
    return (
        <div className="d-flex flex-column">
            <div className="text-default-title mb-1 info-heading">
                {details.IotData.displayName}
            </div>
            {
                details.IotData.name === 'STP_PlantStatus' ?
                    <ShowStatus isActive={details.IotData.value === 'Active'}/>
                    :
                    <div title={details?.IotData?.value}
                         className={`text-l-title ${styles.info_bar_value}`}>{details?.IotData?.value ? details.IotData.value : '-'}</div>
            }
        </div>
    );
};

export default Info;