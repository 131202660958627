import React, {FC, JSX, Key, useEffect, useRef, useState} from "react";
import {
    IxButton,
    IxEventList,
    IxEventListItem,
    IxIcon,
    IxIconButton,
    IxTooltip,
    Modal,
    ModalRef
} from "@siemens/ix-react";
import styles from "./FormulaEditorModal.module.scss";
import {KpisConstant} from "../../../Config/constants/KpisConstant";
import {FormulaEditorModalInterface, KpiTag} from "../../../Config/interfaces/KpiInterface";
import {FormulaCharRemovalKeys, KpiFields} from "../../../Config/enums/KpisEnum";

const FormulaEditorModal: FC<FormulaEditorModalInterface> = ({onAction, kpi, kpiIndex, kpiTags}): JSX.Element => {
    const modalRef = useRef<ModalRef>(null);
    const formulaBasedCheckboxRef = useRef<HTMLInputElement>(null);
    const [formulaBased, setFormulaBased] = useState<boolean>(false);
    const formulaTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [formula, setFormula] = useState<string>(kpi[KpiFields.formula]);
    const [error, setError] = useState<boolean>(false); // TODO: need to handle it

    useEffect(() => {
        setInitialValueForFormulaBased();
    }, []);

    const close = (): void => {
        if (!formula) {
            return;
        }
        modalRef.current?.close('close payload!');
        sendActionStatus(true);
    };

    const dismiss = (): void => {
        modalRef.current?.dismiss('dismiss payload');
        sendActionStatus(false);
    };

    const sendActionStatus = (actionStatus: boolean) => {
        onAction(actionStatus, formula, formulaBased, kpiIndex, kpiTags);
    }

    const setInitialValueForFormulaBased = () => {
        if (typeof kpi[KpiFields.formulaBased] === 'string') {
            setFormulaBased(kpi[KpiFields.formulaBased] === 'true');
        } else {
            setFormulaBased(kpi[KpiFields.formulaBased])
        }
    }

    const clearText = (): void => {
        if (formulaTextareaRef.current) {
            formulaTextareaRef.current.value = '';
            setFormula(formulaTextareaRef.current.value);
            formulaTextareaRef.current.focus();
        }
    }

    const removeSpecificTagOrDelimiterFromCursorPosition = (char: string) => {
        if (!formulaTextareaRef.current) {
            return;
        }
        const currentValue = formulaTextareaRef.current.value;
        const {selectionStart} = formulaTextareaRef.current;
        const noCharRemoval = (selectionStart === 0 && char === FormulaCharRemovalKeys.Backspace)
            ||
            (selectionStart === currentValue.length && char === FormulaCharRemovalKeys.Delete);
        if (noCharRemoval) {
            return;
        }
        const valuesArr = getTagsAndDelimitersArr(formulaTextareaRef.current.value);

        // Determine the element in the array that corresponds to the cursor position
        const elementIndex = findElementIndex(selectionStart, valuesArr);
        if (elementIndex !== -1) {
            // Remove the element at that position from the array
            const newArray = [...valuesArr];
            newArray.splice(elementIndex, 1);

            // Update the textarea value with the modified array
            formulaTextareaRef.current.value = newArray.join('');

            // Set the cursor position in the textarea
            if (valuesArr?.length) {
                let cursorPosition = selectionStart - valuesArr[elementIndex].length;
                cursorPosition = cursorPosition >= 0 ? cursorPosition : 0;
                formulaTextareaRef.current.selectionStart = cursorPosition;
                formulaTextareaRef.current.selectionEnd = cursorPosition;
            }

            formulaTextareaRef.current.focus();
        }
    }

    const checkAllowedCharacters = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        const char = event.key;
        if (KpisConstant.formulaCharRemovalKeys.includes(char)) {
            event.preventDefault();
            removeSpecificTagOrDelimiterFromCursorPosition(char);
            return;
        }

        if (!formulaBased || (!KpisConstant.formulaAllowedKeys.includes(char) && !KpisConstant.formulaRegex.test(char))) {
            event.preventDefault();
        }
    }

    const findElementIndex = (cursorPosition: number, splitArray: string[]): number => {
        let currentPosition = 0;
        for (let i = 0; i < splitArray.length; i++) {
            currentPosition += splitArray[i].length;
            if (currentPosition >= cursorPosition) {
                return i;
            }
        }
        return -1; // Cursor position is outside the array
    };

    const getTagsAndDelimitersArr = (text: string): string[] => {
        return text.split(KpisConstant.formulaDelimiterRegex).filter(Boolean);
    }

    const appendTagInFormula = (tagValue: string): void => {
        if (!formulaTextareaRef.current) {
            return;
        }
        
        if (formulaBased){
            const {selectionStart, selectionEnd} = formulaTextareaRef.current;
            const currentValue = formulaTextareaRef.current.value;
    
            formulaTextareaRef.current.value = currentValue.substring(0, selectionStart) + tagValue + currentValue.substring(selectionEnd);
            setFormula(formulaTextareaRef.current.value);
    
            // Restore cursor position
            const cursorPosition = selectionStart + tagValue.length;
            formulaTextareaRef.current.selectionStart = cursorPosition;
            formulaTextareaRef.current.selectionEnd = cursorPosition;
        }
        else{
            formulaTextareaRef.current.value = tagValue;
            setFormula(tagValue);
        }


        // Focus the textarea to ensure the cursor stays there
        formulaTextareaRef.current.focus();
    }

    const getTagTitleWithJSX = (kpiTag: string, index: Key): JSX.Element => {
        let kpiTagFound = kpiTags.find((tag: KpiTag) => tag.name === kpiTag);
        let tag = kpiTagFound?.name ?? `Error`;

        return (
            <>
                {
                    tag === 'Error' ? (
                        <span key={`failure` + index} className={`color-alarm`}>{` ${tag}`}</span>
                    ) : (
                        <span key={`success` + index} className={`color-primary`}>{` ${tag}`}</span>
                    )
                }
            </>
        );
    }

    const getPrettierFormulaJSX = (): JSX.Element => {
        if (!formulaTextareaRef.current) {
            return <></>;
        }
        const valuesArr = getTagsAndDelimitersArr(formulaTextareaRef.current.value);
        return (
            <div>
                {
                    !!valuesArr.length && valuesArr.map((val: string, index: Key) => (
                        !!val?.length && isNaN(Number(val)) && val?.length === 1 ? ( // when delimiter
                            <span key={index} className={`text-default-title color-indicator`}>{` ${val}`}</span>
                        ) : (
                            isNaN(Number(val)) && val?.length > 1 ? ( // when tag
                                <span key={index} className={`color-primary`}>{getTagTitleWithJSX(val, index)}</span>
                            ) : ( // when number
                                <span key={index} className={`color-warning`}>{` ${val}`}</span>
                            )
                        )
                    ))
                }
            </div>
        );
    }

    return (
        <Modal ref={modalRef}>
            <div className="modal-header">
                <span className={`text-l-title`}>KPI - {kpi?.[KpiFields.name]}</span>
                <IxIconButton
                    data-button-close
                    ghost
                    icon="close"
                    onClick={() => dismiss()}
                ></IxIconButton>
            </div>
            <div className="modal-body">
                <div className={`d-flex flex-row justify-content-start w-100`}>
                    <section className={`m-3 p-3 w-50`}>
                        <div className={`mb-3 text-default-title-single`}>Tags</div>
                        {
                            !kpiTags.length &&
                            <div className={`mb-3 text-default-title-single`}>No Tags Available</div>
                        }
                        <div className={`${styles.tag_list}`}>
                            <IxEventList itemHeight={60}>
                                {
                                    !!kpiTags?.length && kpiTags.map((tag: KpiTag, index: number) => (
                                        !!tag?.name?.length &&
                                        <IxEventListItem key={index} color="color-primary"
                                                         onClick={() => appendTagInFormula(tag.name)}>
                                            <IxIcon name={`tag`} className={`me-2`}></IxIcon>
                                            <div className={`d-flex flex-column`}>
                                                {/*<div className={`${styles.tag_label}`}>{tag}</div>*/}
                                                <div>{tag.name}</div>
                                            </div>
                                        </IxEventListItem>
                                    ))
                                }
                            </IxEventList>
                        </div>
                    </section>
                    <section className={`m-3 p-3 w-50`}>
                        <div
                            className={`mb-3 text-default-title-single d-flex flex-row justify-content-between align-items-center`}>
                            <div className={`d-flex align-items-center`} onChange={clearText}>
                                <input ref={formulaBasedCheckboxRef} id={'formulaBased'} type="checkbox"
                                       className={`form-control`}
                                       checked={formulaBased}
                                       onChange={() => formulaBasedCheckboxRef.current && setFormulaBased(formulaBasedCheckboxRef.current.checked)}/>
                                <label htmlFor={'formulaBased'} className={`text-default-title`}>Formula Based?</label>
                            </div>
                            <IxIconButton onClick={() => clearText()} class="m-1 clear-button" icon="clear" size="16"
                                          oval variant="Secondary"></IxIconButton>
                            <IxTooltip for=".clear-button">Clear editor</IxTooltip>
                        </div>
                        <div className={` d-flex flex-column`}>
                            <textarea ref={formulaTextareaRef} rows={10}
                                      placeholder={formulaBased ? KpisConstant.formulaEditorFormulaBasedPlaceholder : KpisConstant.formulaEditorPlaceholder}
                                      onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => checkAllowedCharacters(event)}
                                      onKeyUp={() => setFormula(formulaTextareaRef?.current ? formulaTextareaRef?.current?.value : '')}
                                      defaultValue={formula}
                                      autoComplete="off"
                                      spellCheck="false"
                                      className={`h-50 text-default-single ${styles.textarea_color}`}></textarea>
                            <div className={`w-100 h-25 mt-2 text-break overflow-auto`}>
                                {getPrettierFormulaJSX()}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="modal-footer">
                <IxButton outline onClick={() => dismiss()}>Cancel</IxButton>
                <IxButton onClick={() => close()} disabled={!formula.length || error}>Done</IxButton>
            </div>
        </Modal>
    );
}

export default FormulaEditorModal;