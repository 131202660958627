import { useState } from "react";
import { IxTabItem, IxTabs } from "@siemens/ix-react";
import Users from "./Users/Users";
import Roles from "./Roles/Roles";
import BreadCrumb from "../Shared/Breadcrumb/BreadCrumb";

const UserManagement = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const changeTab = (tabId: number) => setSelectedTab(tabId);

    return (
        <div className={'m-3'}>
            <BreadCrumb itemString="User Management"></BreadCrumb>
            <IxTabs selected={selectedTab}>
                <IxTabItem onClick={() => changeTab(0)}>Users</IxTabItem>
                <IxTabItem onClick={() => changeTab(1)}>Roles</IxTabItem>
            </IxTabs>
            {selectedTab === 0 ? <Users/> : null}
            {selectedTab === 1 ? <Roles/> : null}
        </div>
    );
}

export default UserManagement;