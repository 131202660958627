// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Infobar_info_bar_content__68FtA {
  height: 5rem;
  overflow: auto; }

.Infobar_show_alarm_dot__dmd5x {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  right: 33px;
  color: white;
  font-size: 10px;
  text-align: center; }

.Infobar_alarm_dot__5czgj {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  right: 17px; }

.Infobar_alarm_dot__5czgj p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* Change the text color as needed */
  font-size: 1.2em; }
`, "",{"version":3,"sources":["webpack://./src/Dashboard/Infobar/Infobar.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc,EAAA;;AAGhB;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB,EAAA;;AAGpB;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,WAAW,EAAA;;AAEb;EACE,kBAAkB;EAChB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,oCAAA;EACA,gBAAgB,EAAA","sourcesContent":[".info_bar_content {\n  height: 5rem;\n  overflow: auto;\n}\n\n.show_alarm_dot {\n  width: 15px;\n  height: 15px;\n  background-color: red;\n  border-radius: 50%;\n  position: absolute;\n  top: 1px;\n  right: 33px;\n  color: white;\n  font-size: 10px;\n  text-align: center;\n}\n\n.alarm_dot {\n  width: 6px;\n  height: 6px;\n  background-color: transparent;\n  border-radius: 50%;\n  position: absolute;\n  top: 11px;\n  right: 17px;\n}\n.alarm_dot p{\n  position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: white;\n    /* Change the text color as needed */\n    font-size: 1.2em;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info_bar_content": `Infobar_info_bar_content__68FtA`,
	"show_alarm_dot": `Infobar_show_alarm_dot__dmd5x`,
	"alarm_dot": `Infobar_alarm_dot__5czgj`
};
export default ___CSS_LOADER_EXPORT___;
