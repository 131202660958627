// key/values in camelCase
export enum KpiInputTypesEnum {
    hidden = 'hidden',
    text = 'text',
    number = 'number',
    select = 'select',
    checkbox = 'checkbox',
    textarea = 'textarea'
}

// key/values in camelCase
// It is used to maintain field names // TODO: Info: Maintain add / remove fields here
export enum KpiFields {
    id = 'kpiId', // keep 'kpiId' as value here, saveKpis API has dependency on it
    name = 'name',
    formulaBased = 'formulaBased',
    formula = 'formula',
    categoryId = 'categoryId',
    lowerLimit = 'lowerLimit',
    upperLimit = 'upperLimit',
    measurement = 'measurement',
    description = 'description'
}

export enum FormulaCharRemovalKeys {
    Backspace = 'Backspace',
    Delete = 'Delete'
}

export enum FormulaAllowedKeys {
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    Home = 'Home',
    End = 'End'
}