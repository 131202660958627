const keycloakConfig = {
    realm: process.env.REACT_APP_REALM || '',
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    url: process.env.REACT_APP_KEYCLOAK_SERVER_URL || '',
    sslRequired: process.env.REACT_APP_SSL_REQUIRED || 'external',
    resource: process.env.REACT_APP_RESOURCE || 'stp-app',
    publicClient: process.env.REACT_APP_PUBLIC_CLIENT || true,
    enableCors: process.env.REACT_APP_ENABLE_CORS || true,
    confidentialPort: process.env.REACT_APP_CONFIDENTIAL_PORT || 0,
};

export default keycloakConfig;