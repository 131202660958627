// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kpis_kpi_table__kVSYl {
  height: 80vh;
  overflow: auto; }

.Kpis_custom_width_number__6fK51 {
  width: 6vw; }

.Kpis_custom_width_select__dWLjt {
  width: 13vw; }
`, "",{"version":3,"sources":["webpack://./src/Components/Kpis/Kpis.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc,EAAA;;AAGhB;EACE,UAAU,EAAA;;AAGZ;EACE,WAAW,EAAA","sourcesContent":[".kpi_table {\n  height: 80vh;\n  overflow: auto;\n}\n\n.custom_width_number {\n  width: 6vw;\n}\n\n.custom_width_select {\n  width: 13vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kpi_table": `Kpis_kpi_table__kVSYl`,
	"custom_width_number": `Kpis_custom_width_number__6fK51`,
	"custom_width_select": `Kpis_custom_width_select__dWLjt`
};
export default ___CSS_LOADER_EXPORT___;
