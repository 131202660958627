import Card from "./Card/Card";
import { JSX, useEffect, useState } from "react";
import PlantKpiService from "../../Services/PlantKpiService";
import { useInject } from "../../hooks/useInject";
import Filter from "./Filter/Filter";
import styles from './Cards.module.scss';
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { PlantKpiInterface } from "../../Config/interfaces/PlantInterface";
import IxSearchInput from "../../Shared/UI-Elements/IxSearchInput/IxSearchInput";
import { IxButton, IxIcon } from "@siemens/ix-react";
import { KpisConstant } from "../../Config/constants/KpisConstant";
import useGetKpisInitialData from "../../hooks/useGetKpisInitialData";

const Cards = (): JSX.Element => {
    const plantKpiService = useInject(PlantKpiService);
    const selectedCategoryId = useAppSelector(state => state.plantSlice.selectedCategoryId);
    const plantKpis = useAppSelector(state => state.plantSlice.plantKpis);
    const [filteredKpis, setFilteredKpis] = useState<PlantKpiInterface[] | []>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedPlantKpis, setSearchedPlantKpis] = useState<PlantKpiInterface[] | []>([]);

    useGetKpisInitialData();

    useEffect(() => {        
        filterKpis();
    }, [plantKpis, selectedCategoryId]);

    useEffect(() => {
        setSearchText('');
    }, [selectedCategoryId]);

    useEffect(() => {
        searchKpis();
    }, [searchText, filteredKpis]);

    const filterKpis = () => {
        const plantKpisFiltered = plantKpiService.filterKpis(plantKpis, selectedCategoryId);
        setFilteredKpis(plantKpisFiltered);
    }

    const searchKpis = () => {
        if (searchText) {
            const filteredPlantKpis = plantKpiService.searchPlantKpis(searchText, filteredKpis);
            setSearchedPlantKpis(filteredPlantKpis);
        } else {
            setSearchedPlantKpis(filteredKpis);
        }
    }

    return (
        <div className={`d-flex flex-row justify-content-between`}>

            <div className="w-75">
                <div className={`d-flex flex-row flex-wrap justify-content-between`}>
                    <div className={`w-25 my-3 ms-4`}>
                        <IxSearchInput searchText={searchText} setSearchText={setSearchText} />
                    </div>
                    <div className={`my-3 me-3`}>
                        {
                            selectedCategoryId && !!filteredKpis.length ? (
                                <Link to={`/dashboard/${selectedCategoryId}/${filteredKpis[0]?.id}`}>
                                    <IxButton>
                                        <IxIcon name={`start-data-analysis`} />{KpisConstant.kpiAnalyticsButton}
                                    </IxButton>
                                </Link>
                            ) : (
                                <IxButton disabled={true}>
                                    <IxIcon name={`start-data-analysis`} />{KpisConstant.kpiAnalyticsButton}
                                </IxButton>
                            )
                        }
                    </div>
                </div>

                <div className="cards-section d-flex flex-row flex-wrap justify-content-start">
                    {
                        !searchedPlantKpis?.length &&
                        <div className={`text-default ms-4`}>{KpisConstant.kpisNotFound}</div>
                    }
                    {
                        !!searchedPlantKpis?.length && searchedPlantKpis.map(
                            (kpi: any) =>
                                kpi.isShown && (
                                    <Card
                                        key={kpi.id}
                                        kpi={kpi}
                                    />
                                )
                        )
                    }
                </div>
            </div>

            <div className={`${styles.filter_box}`}>
                <Filter />
            </div>

        </div>
    );
};

export default Cards;
