import {JSX, useEffect, useState} from "react";
import IxSearchInput from "../../../Shared/UI-Elements/IxSearchInput/IxSearchInput";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {PlantKpiInterface} from "../../../Config/interfaces/PlantInterface";
import {KpisConstant} from "../../../Config/constants/KpisConstant";
import {useInject} from "../../../hooks/useInject";
import PlantKpiService from "../../../Services/PlantKpiService";
import {setPlantKpis} from "../../../redux/slices/plantSlice";
import usePushDataIntoPlantKpis from "../../../hooks/usePushDataIntoPlantKpis";
import { PlantKpisDataInterface } from "../../../Config/interfaces/TimeseriesInterface";
import TimeSeriesService from "../../../Services/TimeSeriesService";

const Filter = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const selectedCategoryId = useAppSelector(state => state.plantSlice.selectedCategoryId);
    const plantKpiService = useInject(PlantKpiService);
    const timeSeriesService = useInject(TimeSeriesService);
    const selectedPlantId = useAppSelector((state) => state.plantSlice.selectedPlantId);
    const plantKpis = useAppSelector((state) => state.plantSlice.plantKpis);
    const [categorisedPlantKpis, setCategorisedPlantKpis] = useState<PlantKpiInterface[] | []>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedPlantKpis, setSearchedPlantKpis] = useState<PlantKpiInterface[] | []>([]);
    const [kpisData, setKpisData] = useState<Record<number, PlantKpisDataInterface[]>>({});

    usePushDataIntoPlantKpis(kpisData);

    useEffect(() => {
        filterKpisByCategory();
    }, [plantKpis, selectedCategoryId]);

    useEffect(() => {
        setSearchText('');
    }, [selectedCategoryId]);

    useEffect(() => {
        searchKpis(searchText);
    }, [searchText, categorisedPlantKpis]);

    const filterKpisByCategory = () => {
        const plantKpisFilteredByCategory = plantKpis.filter((kpi: any) => kpi['categoryId'] === selectedCategoryId);
        setCategorisedPlantKpis(plantKpisFilteredByCategory);
    }

    const searchKpis = (searchText: string) => {
        if (searchText) {
            const filteredPlantKpis = plantKpiService.searchPlantKpis(searchText, categorisedPlantKpis);
            setSearchedPlantKpis(filteredPlantKpis);
        } else {
            setSearchedPlantKpis(categorisedPlantKpis);
        }
    }

    const handleChange = async (shownStatus: boolean, kpi: PlantKpiInterface) => {
        try {
            const plantKpis = await plantKpiService.updateShownStatusOfKpi(shownStatus, kpi.plantId, kpi?.id);
            plantKpis?.length ? dispatch(setPlantKpis(plantKpis)) : dispatch(setPlantKpis([]));
            const kpiResponse = await timeSeriesService.getKpisData(selectedPlantId, KpisConstant.kpiRowNumber);
            setKpisData(kpiResponse?.kpisData);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={`d-flex flex-column background_fade px-2 py-3 h-100`}>
            <section className={`mx-2`}>
                <IxSearchInput searchText={searchText} setSearchText={setSearchText}/>
            </section>
            <section>
                {
                    !searchedPlantKpis?.length &&
                    <div className={`text-default ms-2 mt-2`}>{KpisConstant.kpisNotFound}</div>
                }
                {
                    !!searchedPlantKpis.length && searchedPlantKpis.map((kpi: any) => {
                        return (
                            <div key={kpi.id} className={`mx-2 mt-3 d-flex flex-column`}>
                                <input type="checkbox" id={`checkbox_${kpi.id}`}
                                       onChange={(val) => handleChange(val.target.checked, kpi)}
                                       checked={kpi.isShown} name={kpi.IotData.displayName}/>
                                <label className={``} htmlFor={`checkbox_${kpi.id}`}>{kpi.IotData.displayName}</label>
                            </div>
                        )
                    })
                }
            </section>
        </div>
    );
};
export default Filter;