// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Spinner_spinner__w9fhM {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.Spinner_loadingspinner__lDbV2 {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: Spinner_spinner__w9fhM 1.5s linear infinite; }

.Spinner_spinnercontainer__R0rH5 {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px; }
`, "",{"version":3,"sources":["webpack://./src/Shared/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA;EACI;IACE,uBAAuB,EAAA;EAEzB;IACE,yBAAyB,EAAA,EAAA;;AAG7B;EACE,WAAW;EACX,YAAY;EACZ,0BAA0B;EAAE,eAAA;EAC5B,8BAA8B;EAAE,SAAA;EAChC,kBAAkB;EAClB,sDAAuC,EAAA;;AAGzC;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAA","sourcesContent":["@keyframes spinner {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  .loadingspinner {\n    width: 50px;\n    height: 50px;\n    border: 10px solid #f3f3f3; /* Light grey */\n    border-top: 10px solid #383636; /* Blue */\n    border-radius: 50%;\n    animation: spinner 1.5s linear infinite;\n  }\n  \n  .spinnercontainer {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    height: 350px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingspinner": `Spinner_loadingspinner__lDbV2`,
	"spinner": `Spinner_spinner__w9fhM`,
	"spinnercontainer": `Spinner_spinnercontainer__R0rH5`
};
export default ___CSS_LOADER_EXPORT___;
