export class ToastType {
    static readonly success = 'success';
    static readonly error = 'error';
    static readonly warning = 'warning';
}

export const ToastTitle = {
    success: "Success",
    error: "Error",
    warning: "Warning"
};

export class ToastMessage {
    // TODO: Feature related messages need to move to specific features' constant files
    //  Common messages should be a part of this file
    static readonly unknown = 'Something went wrong';
    static readonly subOrgCreated = 'Sub-organization created successfully';
    static readonly subOrgUpdated = 'Sub-organization updated successfully';
    static readonly plantsAdded = 'Plant(s) added successfully';
    static readonly plantsUpdated = 'Plant(s) updated successfully';
    static readonly plantsDeleted = 'Sub-Org / Plant deleted successfully';
    static readonly startDateGreater = 'Start date cannot be greater than end date';
    static readonly selectTimeRange = 'Please select a time range';
    static readonly trendSaved = 'saved';
    static readonly trendUpdated = 'updated';
    static readonly trendFailed = 'failed';
    static readonly roleDeleteErrorTitle = 'Failed to delete the role';
    static readonly sessionExpired = 'Your session has expired';
    static readonly loginAgain = 'Please login and try again!';
    static readonly rolesAssignedToUser = 'role assigned to the user successfully';
    static readonly notifiedNewUserMessage = 'Email sent to the newly created user successfully';
    static readonly cannotDeleteRoleTitle = "Role cannot be deleted";
    static readonly cannotDeleteRoleMessage = "is already in use";

    static getTrendMessage (graphName: string, action: string, parameterLength: number): string {
        let message = '';
        if (action !== ToastMessage.trendFailed) {
            message = graphName ? `"${graphName}" ${action} with ${parameterLength} parameters successfully` : 'Something went wrong';
        } else {
            message = graphName ? `"${graphName}" ${action} to save` : 'Something went wrong';
        }

        return message;
    }
}