import { useEffect } from "react";

export function useDetectTheme(callback: (themeClassName: string, observer: any) => void) {
    useEffect(() => {
        const targetElement = document.body;

        if (!targetElement) {
            console.error(`Body element not found`);
            return;
        }

        const observer = new MutationObserver((mutationList, observer) => {
            const classMutations = mutationList.filter((mutation) => mutation.type === 'attributes' && mutation.attributeName === 'class');

            if (classMutations.length > 0 && targetElement?.className) {
                const themeClassName = targetElement.className;
                callback(themeClassName, observer);
            }
        });

        observer.observe(targetElement, { attributes: true });

        return () => {
            observer.disconnect();
        };
    }, [callback]);
}