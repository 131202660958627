import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultPermissions, RoleInterface, RolesActionInterface } from "../../Config/interfaces/RoleInterface";

interface RoleState {
    roles: RoleInterface[] | [];
    loggedInUserPermissions: RolesActionInterface;
}

const initialState: RoleState = {
    roles: [],
    loggedInUserPermissions: { ...defaultPermissions }
}

const RoleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRoles(state, action: PayloadAction<RoleInterface[]>): void {
            state.roles = action.payload;
        },
        setLoggedInUserPermissions(state, action: PayloadAction<RolesActionInterface>): void {
            state.loggedInUserPermissions = action.payload;
        }
    }
});

export const { setRoles, setLoggedInUserPermissions } = RoleSlice.actions;
export default RoleSlice.reducer;