import { MenuInterface } from "../interfaces/MenuInterface";

export class MenuConstant {
  static readonly menuItems: MenuInterface[] = [
    { title: "Map View", route: "/", icon: "home", isPermitted: true },
    { title: "Organization", route: "/organization", icon: "hierarchy", isPermitted: false },
    { title: "User management", route: "/userManagement", icon: "user-management", isPermitted: false },
    { title: 'Settings', route: '/settings', icon: 'maintenance', isPermitted: false }
  ];
}
