// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabulardata_tabular_view__7e-Hc {
  width: 100%;
  height: 50vh;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/Detailedview/Tabulardata/Tabulardata.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,YAAY;EACZ,cAAc,EAAA","sourcesContent":[".tabular_view {\n    width: 100%;\n    height: 50vh;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabular_view": `Tabulardata_tabular_view__7e-Hc`
};
export default ___CSS_LOADER_EXPORT___;
