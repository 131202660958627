import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Alarms } from "../../Config/interfaces/AlarmInterface";
import { AlarmEnum } from "../../Config/enums/AlarmsEnum";

interface AlarmState {
  alarms: Alarms;
  alarmCount: number;
}

const initialState: AlarmState = {
  alarms: {
    [AlarmEnum.actual]: [],
    [AlarmEnum.forecasted]: []
  },
  alarmCount: 0
};

const AlarmSlice = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    setAlarms(state, action: PayloadAction<Alarms>): void {
      state.alarms = action.payload;
    },
    setAlarmCount(state, action: PayloadAction<number>): void {
      state.alarmCount = action.payload;
    }
  },
});

export const { setAlarms, setAlarmCount } = AlarmSlice.actions;
export default AlarmSlice.reducer;
