import { useEffect, useState } from "react";
import "./Maps.scss";
import { useInject } from "../hooks/useInject";
import PlantService from "../Services/PlantService";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { OrganizationInfoInterface, PlantInterface } from "../Config/interfaces/PlantInterface";
import { setPlants, setSelectedPlantId } from "../redux/slices/plantSlice";
import { useNavigate } from "react-router-dom";
import { Map, Marker, Popup, NavigationControl, FullscreenControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import useSetUserAssignedPlants from "../hooks/useSetUserAssignedPlants";
import { IxButton } from "@siemens/ix-react";
import { setDashboardNavigatedThrough } from "../redux/slices/navigationSlice";
import { NavigationEnum } from "../Config/enums/NavigationEnum";
import useResetPlantKpisData from "../hooks/useResetKpiData";
import { ManagementType, OrgManagementActions } from "../Config/enums/RolesEnum";

const Maps = () => {
  const plantService = useInject(PlantService);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const plants = useAppSelector((state) => state.plantSlice.plants);
  const userAssignedPlants = useAppSelector(state => state.keycloakSlice.userAssignedPlants);
  const [popupInfo, setPopupInfo] = useState<PlantInterface | null>(null);
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const accessToken = environment === 'DEVELOPMENT' ? process.env.REACT_APP_MAPBOX_ACCESS_TOKEN_DEV! : process.env.REACT_APP_MAPBOX_ACCESS_TOKEN_PROD!;
  const loggedInUserPermission = useAppSelector(state => state.roleSlice.loggedInUserPermissions);

  useSetUserAssignedPlants();
  useResetPlantKpisData();

  useEffect(() => {
    plantService.getPlants().then((plants: PlantInterface[]) => {
      plants?.length ? dispatch(setPlants(plants)) : dispatch(setPlants([]));
    });
  }, []);

  const handleDashboardClick = (plant: any) => {
    const plantInfo: OrganizationInfoInterface = {
      isPlant: plant.IsPlant,
      icon: "",
      id: plant.SuborgId,
      isActive: plant.IsActive,
      isDisabled: Array.isArray(userAssignedPlants) && !!userAssignedPlants.length && !userAssignedPlants.includes(plant?.SubOrgName),
      location: plant.Location,
      plantParent: plant.ParentId,
      subOrgName: plant.SubOrgName,
      totalPlants: 0,
      totalSubOrgs: 0
    }
    if (plantInfo.isDisabled) {
      return;
    }
    plantService.setPlantDetails(plantInfo);
    dispatch(setSelectedPlantId(plantInfo?.id));
    dispatch(setDashboardNavigatedThrough(NavigationEnum.map));
    navigate('/dashboard');
  }

  return (
    <div className="map-container">
      <Map
        initialViewState={{
          latitude: 22.3511,
          longitude: 78.6677,
          zoom: 4,
          bearing: 0,
          pitch: 0
        }}
        mapStyle='mapbox://styles/mapbox/streets-v11'
        mapboxAccessToken={accessToken}
      >
        <NavigationControl position="top-right" />
        <FullscreenControl position="top-right" />

        {plants.map(plant => (
          <Marker
            key={`${plant.Latitude}-${plant.Longitude}`}
            latitude={plant.Latitude}
            longitude={plant.Longitude}
          >
            <div
              className={plant.IsActive ? 'custom-marker' : 'custom-marker-inactive'}
              onMouseEnter={() => setPopupInfo(plant)}
            />
            {popupInfo && popupInfo.SuborgId === plant.SuborgId && (
              <Popup
                latitude={plant.Latitude}
                longitude={plant.Longitude}
                closeButton={false}
                onClose={() => setPopupInfo(null)}
              >
                <div className="popup-content">
                  <h3 className="popup-title">{plant.SubOrgName}</h3>
                  <p className="popup-description">
                    Installed Capacity:{plant.Capacity} <br />
                    Location: {plant.Location} <br />
                    <IxButton disabled={!loggedInUserPermission[ManagementType.Org][OrgManagementActions.View] && Array.isArray(userAssignedPlants) && !!userAssignedPlants.length && !userAssignedPlants.includes(plant?.SubOrgName)} className={`mt-2`} ghost variant="Primary" onClick={(e) => {
                      e.preventDefault();
                      handleDashboardClick(plant);
                    }}>Go to Dashboard</IxButton>
                  </p>
                  <span className="popup-close" onClick={() => setPopupInfo(null)}>×</span>
                </div>
              </Popup>
            )}
          </Marker>
        ))}
      </Map>
    </div>
  );
};

export default Maps;

