import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeseriesInterface } from "../../Config/interfaces/TimeseriesInterface";
import { KpiTag } from "../../Config/interfaces/KpiInterface";

interface TimeSeriesState {
  timeSeries: TimeseriesInterface[] | [];
  tagNames: KpiTag[] | [];
}

const initialState: TimeSeriesState = {
  timeSeries: [],
  tagNames: []
};

const timeSeriesSlice = createSlice({
  name: "timeSeries",
  initialState,
  reducers: {
    setTimeSeries(state, action: PayloadAction<TimeseriesInterface[]>): void {
      state.timeSeries = action.payload;
    },

    setKPITags(state, action: PayloadAction<KpiTag[]>):void {
      state.tagNames = action.payload;
    }
  },
});

export const { setTimeSeries, setKPITags } = timeSeriesSlice.actions;
export default timeSeriesSlice.reducer;
