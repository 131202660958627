import { useEffect, useState } from 'react';
import styles from "./Users.module.scss";
import Userlist from "./UserLists/Userlist";
import UserForm from "../UserForm/UserForm";
import { IxButton, IxDivider, IxDrawer, IxIcon, showToast } from "@siemens/ix-react";
import Spinner from "../../Shared/Spinner/Spinner";
import { useAppSelector } from "../../redux/hooks";
import { ManagementType, UserManagementActions } from '../../Config/enums/RolesEnum';

/**
 * Initializes user page and gets data from keycloak backend 
 *
 * @param None
 */
const Users = (): JSX.Element => {

    /**
     * initialize variable states
     *
    */
    const users = useAppSelector(state => state.userSlice.users);
    const usersProfile = useAppSelector(state => state.userSlice.usersProfile);
    const rolesData = useAppSelector(state => state.roleSlice.roles);
    const realmID = useAppSelector(state => state.keycloakSlice.realmId);
    const [showUserTable, setUserTable] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const createNewUserText = 'Create new User';
    const [userDrawerShow, setUserDrawerShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loggedInUserRolePermissions = useAppSelector(state => state.roleSlice.loggedInUserPermissions);
    /**
    * Calls the GET user API to get user list from keycloak
    *
    * @param None
    */

    useEffect(() => {
        fetchUsers().then(r => r);
    }, [rolesData, realmID]);

    const fetchUsers = async () => {
        if (usersProfile) {
            setUserTable(true);
        }
        else {
            setUserTable(false);
        }
    };

    /**
      * Function to display the userlist component
      *
      * @param None
    */
    const updateNoteHandler = async (notification: any) => {
        setIsEditMode(false);
        setUserDrawerShow(false);
        await showToast(notification);
    };

    /**
      * Returns HTML DOM elements
      *
      * @param None
    */

    const renderUser = (
        <div id="users-tab-content">
            <section className="m-3 d-flex justify-content-between align-items-center">
                <div className="text-default text-gray py-2">View everyone with app access, and add new users.</div>
                {
                    (showUserTable && loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add]) && (
                        <IxButton disabled={!loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add] && userDrawerShow} onClick={() => setUserDrawerShow(true)}>
                            <IxIcon name="plus"></IxIcon>{createNewUserText}
                        </IxButton>
                    )
                }
            </section>

            {
                !showUserTable && loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add] && (
                    <section>
                        <div className="create-default m-2">
                            <div className="vertical-center">
                                <div className="text-l-title">Add Users to your organization</div>
                                <div className="text-default text-gray">Add users to access</div>
                                <IxButton className="m-3" variant="Primary" disabled={!loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add] && userDrawerShow} onClick={() => setUserDrawerShow(true)}>
                                    <IxIcon name="plus"></IxIcon>{createNewUserText}
                                </IxButton>
                            </div>
                        </div>
                    </section>
                )
            }

            {
                showUserTable && users && (
                    <section className={`my-2 ${styles.users_table_section}`}>
                        <Userlist realmID={realmID}></Userlist>
                    </section>
                )
            }

            {
                !loggedInUserRolePermissions[ManagementType.User][UserManagementActions.View] && (
                    <section>
                        <div className="create-default m-2">
                            <div className="vertical-center">
                                <div className="text-l-title text-gray">User Not Authorized to View !</div>
                            </div>
                        </div>
                    </section>
                )
            }

            <IxDrawer closeOnClickOutside={true}
                fullHeight={true}
                width={28}
                show={loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add] && userDrawerShow}
                onDrawerClose={() => {
                    setUserDrawerShow(false);
                    setIsEditMode(false);
                }}>
                <div className={`d-flex flex-column`}>
                    <header className={`text-l-title mx-3 my-2`}>{isEditMode ? "Edit User" : "Create new User"}</header>
                    <IxDivider></IxDivider>
                    <section className={`mx-3`}>
                        <UserForm
                            closeDialog={() => {
                                setUserDrawerShow(false);
                                setIsEditMode(false);
                            }}
                            userDrawerShow={userDrawerShow}
                            onSaveNotification={(type) => updateNoteHandler(type)}
                            editMode={isEditMode}
                            usersProfile={usersProfile}
                        />
                    </section>
                </div>
            </IxDrawer>
        </div>
    );

    return (
        <div>
            {isLoading ? <Spinner /> : renderUser}
        </div>
    )

}

export default Users;