import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface KeycloakState {
    realmId: string;
    userAssignedPlants: string[];
}

const initialState: KeycloakState = {
    realmId: '',
    userAssignedPlants: []
}

const keycloakSlice = createSlice({
    name: 'keycloak',
    initialState,
    reducers: {
        setRealmId(state, action: PayloadAction<string>): void {
            state.realmId = action.payload;
        },
        setUserAssignedPlants(state, action: PayloadAction<string[]>): void {
            state.userAssignedPlants = action.payload;
        }
    }
});

export const { setRealmId, setUserAssignedPlants } = keycloakSlice.actions;
export default keycloakSlice.reducer;