import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import TimeseriesService from "../Services/TimeSeriesService";
import { useInject } from "./useInject";
import { setKpisForecastData } from "../redux/slices/plantSlice";

const usePollForecastDataOnInterval = (): void => {
    const dispatch = useAppDispatch();
    const timeseriesService = useInject(TimeseriesService);
    const interval = +(process.env.REACT_APP_FORECAST_INTERVAL_IN_MICROSECONDS ?? 300000);
    const selectedPlantId = useAppSelector((state) => state.plantSlice.selectedPlantId);

    useEffect(() => {
        const intervalId = pollKpisForecastDataOnInterval();

        return () => clearInterval(intervalId);
    }, [selectedPlantId]);

    const pollKpisForecastDataOnInterval = () => {
        return setInterval(async () => {      
          await getKpisForecastData();
        }, interval);
    }

    const getKpisForecastData = async () => {
        if (!selectedPlantId) {
            return;
        }

        const kpisForecastResponse = await timeseriesService.getKpisForecastData(selectedPlantId);        
        dispatch(setKpisForecastData(kpisForecastResponse?.forecastData ?? {}));
    };
}

export default usePollForecastDataOnInterval;