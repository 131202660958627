// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Info_info_bar_value__Rgl6H {
  width: 20vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
`, "",{"version":3,"sources":["webpack://./src/Dashboard/Infobar/Info/Info.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB,EAAA","sourcesContent":[".info_bar_value {\n  width: 20vh;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info_bar_value": `Info_info_bar_value__Rgl6H`
};
export default ___CSS_LOADER_EXPORT___;
