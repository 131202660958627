import KeyCloakService from "../App/KeycloakService";

class ConfigService {
    protected readonly appIpAddress: string | undefined;
    protected readonly appPort: string | undefined;
    protected readonly serverBaseUrl: string | undefined;
    protected readonly keycloakBaseUrl: string | undefined;
    protected readonly accessToken: string | undefined;
    protected readonly headersList;

    constructor() {
        this.appIpAddress = process.env.REACT_APP_FRONTEND_IP_ADDRESS;
        this.appPort = process.env.REACT_APP_FRONTEND_PORT;
        this.serverBaseUrl = process.env.REACT_APP_HTTPS_PROTOCOL! + process.env.REACT_APP_SERVER_IP_ADDRESS ;
        this.keycloakBaseUrl = process.env.REACT_APP_KEYCLOAK_BASEURL;
        this.accessToken = KeyCloakService.getToken() ?? '';
        this.headersList = {
            "Accept": "*/*",
            "Authorization": "Bearer " + this.accessToken,
            "Content-Type": "application/json"
        }
    }
}

export default ConfigService;