// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ix-application-header.TopBar_sc-ix-basic-navigation__H-rNc {
  background-color: var(--theme-nav--background) !important; }

.TopBar_logo_color__U20Y4 {
  background-color: var(--theme-app-header-logo--color) !important;
  font-weight: bold;
  font-size: large; }
`, "",{"version":3,"sources":["webpack://./src/Layout/Topbar/TopBar.module.scss"],"names":[],"mappings":"AAAA;EAEI,yDAAyD,EAAA;;AAG7D;EAEI,gEAAgE;EAChE,iBAAiB;EACjB,gBAAgB,EAAA","sourcesContent":["ix-application-header.sc-ix-basic-navigation\n{\n    background-color: var(--theme-nav--background) !important;\n}\n\n.logo_color\n{\n    background-color: var(--theme-app-header-logo--color) !important;\n    font-weight: bold;\n    font-size: large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sc-ix-basic-navigation": `TopBar_sc-ix-basic-navigation__H-rNc`,
	"logo_color": `TopBar_logo_color__U20Y4`
};
export default ___CSS_LOADER_EXPORT___;
