// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeSeries_processing_txt__vCYxe {
    text-align: center;
    position: relative;
    top: 10em;
}

.timeSeries_graph__YROeo {
    height: 55vh;
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/Analytics/Charts/timeSeries.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".processing_txt {\n    text-align: center;\n    position: relative;\n    top: 10em;\n}\n\n.graph {\n    height: 55vh;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"processing_txt": `timeSeries_processing_txt__vCYxe`,
	"graph": `timeSeries_graph__YROeo`
};
export default ___CSS_LOADER_EXPORT___;
