import {FC, useEffect} from "react";
import { useState, useRef } from "react";
import {
    IxTabItem,
    IxTabs,
    IxButton,
    IxIcon,
    IxDrawer,
    IxDivider,
    showModal,
    IxSpinner,
    showToast
} from "@siemens/ix-react";
import { scatterPlotFormConstant, scatterPlotFormDefaultValues } from "./constant/scatterPlotFormConfig";
import { heatMapFormConstant, heatMapFormDefaultValues } from "./constant/heatMapFormConfig";
import TrendForm from "../Analytics/TrendForm/TrendForm";
import ScatterPlot from "./ScatterPlot/ScatterPlot";
import ConfirmModal from "../Shared/ConfirmModal/ConfirmModal";
import HeatMap from "./HeatMap/HeatMap";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useInject} from "../hooks/useInject";
import ConfigService from "../Services/ConfigService";
import styles from './Correlation.module.scss';
import {
    addHeatMap,
    addScatterPlot,
    removeHeatMap,
    removeScatterPlot,
    setHeatMaps,
    setParametersList,
    setScatterPlots, updateHeatMap,
    updateScatterPlot
} from "../redux/slices/insightsSlice";
import InsightsService from "../Services/InsightsService";
import {ToastMessage, ToastType} from "../Config/constants/ToastMessage";

const createNewGraphText: string = 'Create new graph';
const editGraphText: string = "Edit graph"
let createNewText : string = createNewGraphText;

interface EditFormDataInterface {
    id: number;
    data: any;
}

const Correlation: FC = (): JSX.Element => {
    const configService = useInject(ConfigService);
    const insightsService = useInject(InsightsService);
    const dispatch = useAppDispatch();
    const plantKpis = useAppSelector((state) => state.plantSlice.plantKpis);
    const selectedPlant = useAppSelector(state => state.plantSlice.selectedPlant);
    const selectedPlantId = useAppSelector(state => state.plantSlice.selectedPlantId);
    const scatterGraphs = useAppSelector(state => state.insightsSlice.scatterPlots);
    const heatMaps = useAppSelector(state => state.insightsSlice.heatMaps);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editFormData, setEditFormData] = useState<EditFormDataInterface>({data: {}, id: -1});
    const [isLoading, setIsLoading] = useState(false);
    let currentGraphId: any = useRef(null);
    heatMapFormDefaultValues.graphName = 'HeatMap - ' + selectedPlant;

    useEffect(() => {
        fetchTrendData().then();
    }, []);

    useEffect(() => {
        prepareParameters();
    }, [plantKpis]);

    const changeTab = (tabId: number) => {
        setSelectedTab(tabId);
    }

    const prepareParameters = () => {
        if (!plantKpis.length) {
            return;
        }

        const selectOptionData = plantKpis?.map((kpi: any) => {
            return {
                value: kpi.id,
                name: kpi.IotData.displayName,
            };
        });
        scatterPlotFormConstant.forEach((item: any) => {
            if (item.name === 'xParameter' || item.name === 'yParameter') {
                item.options = selectOptionData;
            }
        });
        heatMapFormConstant.forEach((item: any) => {
            if (item.name === 'parameters') {
                item.options = selectOptionData;
            }
        });

        const parameters: Record<number, string> = {};
        selectOptionData.forEach((item: {value: number, name: string}): void => {
            parameters[item?.value] = item?.name;
        });

        dispatch(setParametersList(parameters));
    }

    const fetchTrendData = async () => {
        try {
            setIsLoading(true);
            const response = await insightsService.getGraphs(selectedPlantId);
            if (!response) {
                return;
            }
            setIsLoading(false);
            const scatterData: any = [];
            const heatmapData: any = [];
            response.forEach((item: any) => {
                const data = item.trendMetaInfo;

                if (data.graphType === 'scatter') {
                    scatterData.push({
                        id: item.id,
                        graphName: data.graphName,
                        graphType: data.graphType,
                        timeRange: data.timeRange,
                        xParameter: data.xParameter,
                        yParameter: data.yParameter
                    });
                } else if (data.graphType === 'heatmap') {
                    heatmapData.push({
                        id: item.id,
                        graphName: data.graphName,
                        graphType: data.graphType,
                        liveData: data.livedata,
                        timeRange: data.timeRange,
                        parameters: data.parameters
                    });
                }
            });

            scatterData.length ? dispatch(setScatterPlots(scatterData)) : dispatch(setScatterPlots([]));
            heatmapData.length ? dispatch(setHeatMaps(heatmapData)) : dispatch(setHeatMaps([]));
        }
        catch (error) {
            console.error('Error in Trend Form', error);
            setIsLoading(false);
       }
    };

    const onSaveForm = async (payload: any) => {
        const graph = structuredClone(payload.trendMetaInfo);

        if (!Object.keys(payload).length) {
            return;
        }

        if (isEditMode) {
            graph.id = editFormData.id;
            graph.timeRange.startDateTime = payload?.trendMetaInfo?.timeRange?.startDateTime?.toISOString();
            graph.timeRange.endDateTime = payload?.trendMetaInfo?.timeRange?.endDateTime?.toISOString();
            if (selectedTab === 0) {
                dispatch(updateScatterPlot(graph));
            } else {
                dispatch(updateHeatMap(graph));
            }
        } else {
            graph.id = payload.id;
            if (graph.graphType === 'scatter') {
                dispatch(addScatterPlot(graph));
            } else {
                dispatch(addHeatMap(graph));
            }
        }
    };

    const closeDialog = () => {
        setShowForm(false);
        setIsEditMode(false);
    }

    const notifyUser = (type: ToastType | any, formValues: any) => {
        let action = isEditMode ? ToastMessage.trendUpdated : ToastMessage.trendSaved;
        if (type === ToastType.error) {
            action = ToastMessage.trendFailed;
        }

        const paramLength = formValues?.parameters ? formValues.parameters.length : 2;
        showToast({
            type: type,
            title: '',
            message: ToastMessage.getTrendMessage(formValues?.graphName, action, paramLength)
        }).then(r => r);
    };

    const createNewTrend = async (payload: any) => {
        try {
            setIsLoading(true);
            const response = await insightsService.createInsightsGraph(payload);
            closeDialog();
            await onSaveForm(response);
            notifyUser(ToastType.success, payload?.trendMetaInfo);
        } catch (error) {
            notifyUser(ToastType.error, payload?.trendMetaInfo);
            console.error("Error in createNewTrend", error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateTrend = async (id: any, payload: any) => {
        try {
            setIsLoading(true);
            const response = await insightsService.updateInsightsGraph(id, payload);
            if (response.status === 200) {
                closeDialog();
                await onSaveForm(payload);
                notifyUser(ToastType.success, payload?.trendMetaInfo);
            } else {
                notifyUser(ToastType.error, payload?.trendMetaInfo);
            }
        } catch (error) {
            console.error("Error in updateTrend", error);
            notifyUser(ToastType.error, payload?.trendMetaInfo);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteGraphHandler = (id: number | null) => {
        if (!id) {
            return;
        }
        fetch(configService.serverBaseUrl + "/api/trendData/" + id, {
            method: "DELETE",
            headers: configService.headersList
        }).then(async () => {
            currentGraphId = null;
            selectedTab === 0 ? dispatch(removeScatterPlot(id)) : dispatch(removeHeatMap(id));
        }).catch((error) => {
            console.error("Error in deleteGraphHandler", error);
        });
    };

    async function showConfirmBox(id: any, graphName: any) {
        currentGraphId = id;
        const confirmationText = `Are you sure?`;
        const confirmationSubText = `Do you really want to delete Graph "${graphName}" permanently?`;
        await showModal({
            title: 'deleteConfirmationModal',
            content: <ConfirmModal onAction={confirmationHandler} confirmationText={confirmationText}
                                   confirmationSubText={confirmationSubText}/>,
            icon: 'warning-filled',
            iconColor: 'color-warning',
            size: 'lg'
        });
    }

    const confirmationHandler = (actionType: string) => {
        if (actionType === "yes") {
            deleteGraphHandler(currentGraphId);
        } else {
            currentGraphId = null;
        }
    };

    const editGraphHandler = (id: number) => {
        if (selectedTab === 0) {
            const index = scatterGraphs.findIndex(
                (trend: any) => trend.id === id
            );
            setEditFormData(() => {
                return {data: scatterGraphs[index], id};
            });
        } else {
            const index = heatMaps.findIndex(
                (trend: any) => trend.id === id
            );
            setEditFormData(() => {
                return {data: heatMaps[index], id};
            });
        }

        setIsEditMode(true);
        setShowForm(true);
    };

    return (
        <div className="ms-1 mt-2">
            <section className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <IxTabs selected={selectedTab}>
                        <IxTabItem onClick={() => changeTab(0)}>Scatter plots</IxTabItem>
                        <IxTabItem onClick={() => changeTab(1)}>Heat map</IxTabItem>
                    </IxTabs>
                </div>
                {
                    selectedTab === 0 && scatterGraphs.length > 0 &&
                    <IxButton
                        disabled={showForm}
                        onClick={() => {
                            setShowForm(!showForm);
                            setIsEditMode(false);
                        }}
                    >
                        <IxIcon name="plus"></IxIcon>
                        {createNewText}
                    </IxButton>
                }

            </section>

            <section>
                {
                    isLoading && <IxSpinner className={`vertical-center`} size={'medium'}></IxSpinner>
                }
            </section>

            <div className={`${styles.view_height} overflow-auto`}>
                {selectedTab === 0 && !isLoading && scatterGraphs.length === 0 && (
                    <div className="create-default m-2">
                        <div className="vertical-center">
                            <div className="text-l-title">No details available</div>
                            <div className="text-default text-gray">Click to create a new scatter graph</div>
                            <IxButton
                                disabled={showForm}
                                onClick={() => {
                                    setShowForm(!showForm);
                                    setIsEditMode(false);
                                }}
                                className="m-3"
                                variant="Primary"
                            >
                                <IxIcon name="plus"></IxIcon>
                                {createNewText}
                            </IxButton>
                        </div>
                    </div>
                )}

                {selectedTab === 0 && scatterGraphs.length > 0 && (
                    <div>
                        {/* Render Scatter Plots here */}
                        {scatterGraphs.map((data: any, index: number) => (
                                <ScatterPlot
                                    key={data?.id}
                                    collapsed={index !== 0}
                                    graphDetails={data}
                                    deleteGraph={showConfirmBox}
                                    editGraph={editGraphHandler}
                                />
                            )
                        )}
                    </div>
                )}

                {selectedTab === 1 && heatMaps.length === 0 && (
                    <div className="create-default m-2">
                        <div className="vertical-center">
                            <div className="text-l-title">No details available</div>
                            <div className="text-default text-gray">Configure Heat Map</div>
                            <IxButton
                                disabled={showForm}
                                onClick={() => {
                                    setShowForm(!showForm);
                                    setIsEditMode(false);
                                }}
                                className="m-3"
                                variant="Primary"
                            >
                                <IxIcon name="plus"></IxIcon>
                                {createNewText}
                            </IxButton>
                        </div>
                    </div>
                )}

                {selectedTab === 1 && heatMaps.length > 0 && (
                    <div>
                        {/* Render Heat Maps here */}
                        <HeatMap
                            graphDetails={heatMaps[0]}
                            deleteGraph={showConfirmBox}
                            editGraph={editGraphHandler}
                            createNewTrend={createNewTrend}
                        />
                    </div>
                )}
                {/* Display TrendForm when showForm is true */}
                <IxDrawer
                    closeOnClickOutside={true}
                    fullHeight={true}
                    width={28}
                    show={showForm}
                    onDrawerClose={() => setShowForm(false)}>
                    <div className={`d-flex flex-column`}>
                        <header
                            className={`text-l-title mx-3 my-2`}>{isEditMode ? editGraphText : createNewGraphText}</header>
                        <IxDivider></IxDivider>
                        <section>
                            {showForm && (
                                <TrendForm
                                    isLoading={isLoading}
                                    createNewTrend={createNewTrend}
                                    updateTrend={updateTrend}
                                    editMode={isEditMode}
                                    editableData={editFormData.data}
                                    trendId={editFormData.id}
                                    trendFormShow={showForm}
                                    setCreateNewTrendShow={setShowForm}
                                    formFields={selectedTab === 0 ? scatterPlotFormConstant : heatMapFormConstant}
                                    formDefaultValues={selectedTab === 0 ? scatterPlotFormDefaultValues : heatMapFormDefaultValues}
                                />
                            )}
                        </section>
                    </div>
                </IxDrawer>
            </div>
        </div>
    )
}

export default Correlation;

