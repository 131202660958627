export enum RolesEnum {
    admin = 'stp_Admin',
    adminRoleId = '845c8ac2-7121-4d77-8428-1850aa6e0086'
}

export enum ManagementType {
    User = 'user',
    Org = 'org',
    Roles = 'roles',
    Plant = 'plant'
}

export enum UserManagementActions {
    Add = 'user_add',
    View = 'user_view',
    Del = 'user_del',
    Full = 'user_full'
}

export enum OrgManagementActions {
    Add = 'org_add',
    View = 'org_view',
    Del = 'org_del',
    Full = 'org_full'
}

export enum RoleManagementActions {
    Add = 'roles_add',
    View = 'roles_view',
    Del = 'roles_del',
    Full = 'roles_full'
}

export enum PlantManagementActions {
    Add = 'plant_add',
    View = 'plant_view',
    Del = 'plant_del',
    Full = 'plant_full'
}