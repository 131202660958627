import { FC } from "react";
import { IxBreadcrumb, IxBreadcrumbItem } from "@siemens/ix-react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const BreadCrumb: FC<{
    itemString: string
}> = (props): JSX.Element => {
    const selectedCategoryId = useAppSelector((state) => state.plantSlice.selectedCategoryId);
    const selectedKpiId = useAppSelector((state) => state.plantSlice.selectedKpiId);
    const breadCumbItems : string [] = props.itemString.split(',');
    const navigate = useNavigate();
    
    const onBreadCrumbItemClick = (item: any) => {
      if (item.detail && typeof item.detail === "string") {
        const detailStr = item.detail.toLowerCase();

        if (detailStr.includes("map")) {
          navigate("/");
        } else if (detailStr.includes("organization")) {
          navigate("/organization");
        } else if (detailStr.includes("dashboard")) {
          let path = "/dashboard";
          navigate(path);
        } else if (detailStr.includes("analytics")) {
          let path = `/dashboard/${selectedCategoryId}/${selectedKpiId}`;
          navigate(path);
        } else if (detailStr.includes("alarms")) {
          navigate("/alarms");
        }
      }
    };

    return (
      <IxBreadcrumb ghost onItemClick={(item) => {onBreadCrumbItemClick(item)}}>
          {
              breadCumbItems.map((item, key) => (
                  <IxBreadcrumbItem key = {key} label = {item}></IxBreadcrumbItem>
              ))
          }
      </IxBreadcrumb>
    )
  };

  export default BreadCrumb;