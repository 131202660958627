import {showToast} from "@siemens/ix-react";
import {ToastMessage} from "../Config/constants/ToastMessage";

export function compareStartAndEndDate(startEndDateTimestamps: Date[], showAlert=true): boolean {
    if (startEndDateTimestamps[0] > startEndDateTimestamps[1]) {
        if (showAlert){
            showToast({
                type: 'error',
                title: '',
                message: ToastMessage.startDateGreater
            }).then(r => r);
        }
        return false;
    }
    return true;
}


export function reload() {
    setTimeout(() => {
        window.location.reload();
    }, 3000);
}