import React, {FC, JSX} from "react";

interface CheckboxPropsInterface {
    name: string;
    label: string;
    readonly: boolean;
    field: any;
}

const Checkbox: FC<CheckboxPropsInterface> = ({name, label, readonly, field}): JSX.Element => {
    return (
        <>
            <input
                id={name}
                name={name}
                type="checkbox"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                checked={field.value}
                readOnly={readonly}
            />
            <label htmlFor={name}>{label}</label>
        </>
    );
}

export default Checkbox;