// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Correlation_view_height__3H9J9 {
  height: 75vh; }
`, "",{"version":3,"sources":["webpack://./src/Correlation/Correlation.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAA","sourcesContent":[".view_height {\n  height: 75vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view_height": `Correlation_view_height__3H9J9`
};
export default ___CSS_LOADER_EXPORT___;
