export class RealmRoles {
    static getAccountRoles() {
        const accountRoles = [
            {
                "id": "a0bb8a18-0832-480b-9dd4-2551eddb3630",
                "name": "manage-account",
                "description": "Manage realm account",
                "composite": true,
                "clientRole": true,
                "containerId": "e45c9b84-4f9c-44e0-98de-0438b906c33f"
            }
        ];

        return JSON.stringify(accountRoles);
    }

    static getRealmRoles() {
        const realmRoles = [
            {
                "id": "5a94b13f-6703-4b39-a59c-a59d530a70ce",
                "name": "manage-clients",
                "description": "role_manage-clients",
                "composite": false,
                "clientRole": true,
                "containerId": "9435e103-160a-4c22-ad27-c7cd1b4d9abb"
            },
            {
                "id": "97c299bf-6bd5-4111-bfd1-373194c06b79",
                "name": "manage-users",
                "description": "role_manage-users",
                "composite": false,
                "clientRole": true,
                "containerId": "9435e103-160a-4c22-ad27-c7cd1b4d9abb"
            }
        ];

        return JSON.stringify(realmRoles);
    }
}