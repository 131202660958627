import styles from "./listnavigation.module.scss";
import { useNavigate } from "react-router-dom";
import { IxKpi, IxExpandingSearch, IxSpinner } from "@siemens/ix-react";
import { JSX, useEffect, useState } from "react";
import { getKpiMeasurement, getKpiValue, getQualityAttributeResult } from "../../utilities/converters";
import { Categories } from "../../Config/interfaces/KpiInterface";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCategories, setSelectedCategoryId } from "../../redux/slices/plantSlice";
import { useInject } from "../../hooks/useInject";
import KpiService from "../../Services/KpiService";
import { setSelectedKpiId } from "../../redux/slices/plantSlice";
import { PlantKpiInterface } from "../../Config/interfaces/PlantInterface";
import PlantKpiService from "../../Services/PlantKpiService";
import { IxSelect, IxSelectItem } from "@siemens/ix-react";
import { NavigationEnum } from "../../Config/enums/NavigationEnum";

const ListNavigation = ({ filteredKpis }: { filteredKpis: any }): JSX.Element => {
	const navigate = useNavigate();
	const plantKpiService = useInject(PlantKpiService);
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const kpiService = useInject(KpiService, setIsLoading);
	const categories: Categories[] = useAppSelector((state) => state.plantSlice.categories);
	const selectedCategoryId = useAppSelector((state) => state.plantSlice.selectedCategoryId);
	const plantKpis = useAppSelector((state) => state.plantSlice.plantKpis);
	const selectedPlantId = useAppSelector((state) => state.plantSlice.selectedPlantId);
	const selectedKpiId = useAppSelector((state) => state.plantSlice.selectedKpiId);
	const [searchText, setSearchText] = useState("");
	const [searchedPlantKpis, setSearchedPlantKpis] = useState<PlantKpiInterface[] | []>([]);

	useEffect(() => {
		getKpiCategories().then((r) => r);
	}, [selectedPlantId]);

	useEffect(() => {
		searchKpis();
	}, [searchText, filteredKpis]);

	const getKpiCategories = async () => {
		if (!selectedPlantId) {
			navigate("/");
			return;
		}

		if (categories.length) {
			updateSelectedCategory(categories);
			return;
		}

		const plantCategories: Categories[] = await kpiService.getKpiCategories(selectedPlantId);
		plantCategories?.length ? dispatch(setCategories(plantCategories)) : dispatch(setCategories([]));
	};

	const updateSelectedCategory = (categories: Categories[]) => {
		if (!selectedCategoryId || !categories.length) {
			return;
		}
		const category = categories.find((category) => category.id === selectedCategoryId);
		category?.id ? dispatch(setSelectedCategoryId(category.id)) : dispatch(setSelectedCategoryId(0));
	};

	const setSelected = (kpiId: number) => {
		const navPath = `/${NavigationEnum.dashboard.toLowerCase()}/${selectedCategoryId}/${kpiId}`;
		dispatch(setSelectedKpiId(kpiId));
		navigate(navPath);
	};

	const changeCategory = (categoryId: string) => {
		const plantKpisFiltered = plantKpiService.filterKpis(plantKpis, +categoryId);
		if (!plantKpisFiltered.length) {
			return;
		}

		const defaultKpiId = plantKpisFiltered?.[0]?.id ?? 0;
		const navPath = `/dashboard/${categoryId}/${defaultKpiId}`;
		navigate(navPath);
	};

	const searchKpis = () => {
		if (searchText) {
			const filteredPlantKpis = plantKpiService.searchPlantKpis(searchText, filteredKpis);
			setSearchedPlantKpis(filteredPlantKpis);
		} else {
			setSearchedPlantKpis(filteredKpis);
		}
	};

	return (
		<>
			<section>{isLoading && <IxSpinner className={`vertical-center`} size={"medium"}></IxSpinner>}</section>
			<div className='d-flex flex-column align-items-left'>
				<div className='mt-1 me-2'>
					{!!categories.length && (
						<IxSelect
							i18nPlaceholderEditable={`Select category`}
							selectedIndices={selectedCategoryId.toString()}>
							{categories.map((category: any) => {
								return (
									<IxSelectItem
										key={category.id}
										label={category.name}
										value={category.id.toString()}
										onItemClick={(categoryId) => changeCategory(categoryId.detail)}></IxSelectItem>
								);
							})}
						</IxSelect>
					)}
				</div>
				<div className='mt-2 me-2'>
					<IxExpandingSearch
						onValueChange={(event) => setSearchText(event.target.value)}
						placeholder='Search KPIs'></IxExpandingSearch>
				</div>
			</div>

			<div className={styles.kpi_list + " pe-2"}>
				{!!searchedPlantKpis.length &&
					searchedPlantKpis.map((kpi: any) => {
						return (
							<IxKpi
								key={kpi.id}
								onClick={() => setSelected(kpi.id)}
								label={kpi["IotData"] && kpi["IotData"].displayName}
								value={getKpiValue(kpi)}
								state={getQualityAttributeResult(kpi)}
								unit={getKpiMeasurement(kpi)}
								className={`mt-3 p2-1 ${+selectedKpiId === kpi.id ? styles.selected : ""}`}></IxKpi>
						);
					})}

				{!searchedPlantKpis.length && <div className={`text-default mt-3`}>No KPIs found</div>}
			</div>
		</>
	);
};
export default ListNavigation;
