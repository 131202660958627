import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { optionsData } from "../constant/chartOptions.constant";
import moment from "moment";
import styles from "./timeSeries.module.css";
import { useAppSelector } from "../../redux/hooks";

interface TimeSeriesChart {
  initialData: { name:string; data: any; markLine?: any }[];
  ref?: any;
}

const TimeSeriesChart: React.FC<TimeSeriesChart> = forwardRef(
  ({ initialData }, ref) => {
    const chartRef = useRef<ReactECharts>(null);
    const [optionData, setOptionData] = useState(optionsData);
    const themeMode = useAppSelector(state => state.settingsSlice.theme);

    useImperativeHandle(ref, () => ({
      append(series: any) {
        appendData(series);
      },
      add(name: string, data: any) {
        handleAddData(name, data);
      },
      addSeries(name: string, data: any) {
        handleAddSeries(name, data);
      },
      reset(options: any) {
        resetInstance(options);
      },
      load() {
        startLoading();
      },
      stopLoading() {
        hideLoading();
      },
    }));

    const startLoading = () => {
      if (chartRef.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        chartInstance.showLoading();
      }
    };

    const hideLoading = () => {
      if (chartRef.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        chartInstance.hideLoading();
      }
    };

    const resetInstance = (options: any) => {
      if (chartRef.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        chartInstance?.clear();
        const optionData = generateOptions(options);
        setOptionData(optionData);
      }
    };

    const handleAddSeries = async (name: string, data: any) => {
      const series = {
        name,
        data,
      };
      if (chartRef.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        const options: any = chartInstance.getOption();

        let ledgendAvailable = -1;
        if (options?.series?.length > 0) {
          ledgendAvailable = options.series.findIndex((data: any) => data.name === name);
        }

        let newSeries: any = [];
        
        if (ledgendAvailable > -1) {
          // replace existing data with new series to avoid duplicate series
          options.series[ledgendAvailable].data = data;
          newSeries = options.series;
        } else {
          newSeries = [
            ...options.series,
            { ...getBaseConfigforSeries(series) },
          ];
        }
        
        chartInstance.setOption({
          series: newSeries,
        });
        chartInstance.resize();
      }
    };

    const appendData = (series: any) => {
      if (chartRef?.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        const options: any = chartInstance.getOption();
        const seriesData = options.series;

        chartInstance.setOption<echarts.EChartsOption>({
          series: series.map((serie: any, i: number) => {
            const name = serie.name;
            const newData = seriesData.find((s: any) => name === s.name)?.data ?? [];

            return {
              name,
              data: [
                ...(i > 0 ? newData : newData.splice(1)),
                serie.data.pop(),
              ],
            };
          }),
        });
      }
    };

    const handleAddData = (name: string, data: any) => {
      if (chartRef?.current) {
        const chartInstance = chartRef.current.getEchartsInstance();
        const options: any = chartInstance.getOption();
        const seriesData = options.series;

        let newData = seriesData.find((s: any) => name === s.name)?.data ?? [];
        if (newData.length > 50) {
          newData = newData.slice(-50);
        }

        chartInstance.setOption<echarts.EChartsOption>({
          series: [{ name: name, data: [...newData, data] }],
        });
        chartInstance.hideLoading();
      }
    };

    const getBaseConfigforSeries = (series: any, type = "line") => {
      return {
        type,
        name: series.name,
        data: series.data,
        symbolSize: 7,
        symbolKeepAspect: true,
        lineStyle: {
          width: 2.5,
        },
        areaStyle: {
          origin: "start",
          opacity: 0.5,
        },
      };
    };

    const generateMarkLineData = (name: string, value: any) => {
      return {
        name: name,
        yAxis: value,
        label: {
          formatter: `{b}: {c}`,
          position: "insideEndTop",
          fontWeight: "bold",
        },
      };
    };

    const generateOptions = (options: any = initialData): EChartsOption => {
      return {
        ...optionsData,
        grid: { top: 80, right: 5, bottom: 90, left: 50, width: "95%" },
        xAxis: {
          type: "time",
          scale: false,
          boundaryGap: ["0%", "1%"],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            show: true,
            hideOverLap: true,
            margin: 12,
            align: "center",
            verticalAlign: "top",
            formatter: function (value: any, i: number) {
              let label = moment(value).format("h:mm:ss a");
              return i % 3 === 0
                ? `{date| ${moment(value).clone().format("MMM Do")}}\n ${label}`
                : label;
            },
            rich: {
              date: {
                color: "#777",
                fontWeight: "bold",
              },
            },
          },
        },
        yAxis: {
          type: "value",
          scale: false,
        },
        series: options.map((series: any) => {
          const seriesMarkLineKeys = series?.markLine
            ? Object.keys(series.markLine)
            : [];
          return {
            ...getBaseConfigforSeries(series),
            ...(series.markLine &&
              seriesMarkLineKeys.length && {
                markLine: {
                  data: seriesMarkLineKeys.map((key: any) =>
                    generateMarkLineData(key, series.markLine[key])
                  ),
                  lineStyle: {
                    width: 1.5,
                  },
                },
              }),
          };
        }),
      };
    };

    return (
      <div className={`${styles.graph}`}>
        {!Object.keys(initialData[0]).length && (
          <p className={`${styles.processing_txt}`}>Processing data...</p>
        )}
        <ReactECharts
          ref={chartRef}
          option={optionData}
          notMerge={true}
          lazyUpdate={true}
          theme={themeMode}
          opts={{ renderer: "svg" }}
          style={{ height: "29em", width: "98%" }}
        />
      </div>
    );
  }
);

export default TimeSeriesChart;
