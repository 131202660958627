import { BrowserRouter } from "react-router-dom";
import TopBar from "./Topbar/TopBar";

const Layout = () => {
  return (
    <BrowserRouter>
        <TopBar />
    </BrowserRouter>
  );
};

export default Layout;
