// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpenAI_openaiAnalysis__oW9r9 {
  display: flex; }
  .OpenAI_openaiAnalysis__oW9r9 .OpenAI_responseDiv__CNbMq {
    height: 450px;
    margin-bottom: 20px;
    overflow-y: scroll; }
`, "",{"version":3,"sources":["webpack://./src/Detailedview/OpenAI/OpenAI.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAII,aAAa;IACb,mBAAmB;IACnB,kBAAkB,EAAA","sourcesContent":[".openaiAnalysis {\n  display: flex;\n\n  .responseDiv {\n    height: 450px;\n    margin-bottom: 20px;\n    overflow-y: scroll;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openaiAnalysis": `OpenAI_openaiAnalysis__oW9r9`,
	"responseDiv": `OpenAI_responseDiv__CNbMq`
};
export default ___CSS_LOADER_EXPORT___;
