export const scatterPlotFormDefaultValues = {
    graphName: '',
    graphType: 'scatter',
    timeRange: {
        startDateTime: '',
        endDateTime: ''
    },
    xParameter: null,
    yParameter: null
}

export const scatterPlotFormConstant = [
    {
        type: "text",
        placeholder: "Enter graph name",
        label: "Graph Name",
        name: "graphName",
        rules: {required: true},
        readonly: false
    },
    {
        type: "dateRangePicker",
        placeholder: "",
        label: "Time Range",
        name: "timeRange",
        fields: [
            {
                type: "startDate",
                datePlaceholder: "Start date",
                timePlaceholder: "Start time",
                label: "",
                name: "startDateTime",
                rules: {required: true}
            },
            {
                type: "endDate",
                datePlaceholder: "End date",
                timePlaceholder: "End time",
                label: "",
                name: "endDateTime",
                rules: {required: true}
            }
        ]
    },
    {
        type: "select",
        placeholder: "",
        label: "X-Axis Parameter",
        name: "xParameter",
        rules: {required: true},
        options: [],
        mode: "single",
        readonly: false
    },
    {
        type: "select",
        placeholder: "",
        label: "Y-Axis Parameter",
        name: "yParameter",
        rules: {required: true},
        options: [],
        mode: "single",
        readonly: false
    }
];

export const scatterPlotGraphConfig = {
    grid: {top: 50, right: 0, bottom: 90, left: '8%', width: "80%"},
    backgroundColor: '#ffffff00',
    legend: {
      textStyle: {
          lineHeight: 14,
          overflow: "break",
          fontSize: 12
      }
    },
    xAxis: {
        type: 'value',
        name: 'xAxis', // updates with xAxis name
        axisLine: {
            show: true,
            hideOverLap: true,
            margin: 12,
        },
        splitLine: {
            show: false
        },
        lineStyle: {
            width: 1.5
        },
        axisTick: {
            alignWithLabel: true
        }
    },
    yAxis: {
        type: 'value',
        name: 'yAxis', // updates with yAxis name
        axisLabel: {
            color: '#afafac'
        },
        axisLine: {
            show: true
        },
        splitLine: {
            show: true,
            lineStyle: {width: .5}
        },
        lineStyle: {
            width: 1.5
        }
    },
    toolbox: {
        right: 30,
        itemSize: 20,
        itemGap: 10,
        top: 0,
        iconStyle: {
            color: "rgba(255, 255, 255, 0)",
            borderColor: "#0cc",
            borderWidth: 2
        },
        feature: {
            dataZoom: {
                show: false,
                yAxisIndex: "none",
            },
            restore: {show: false},
            saveAsImage: {show: true},
        },
    },
    dataZoom: [
        {
            type: "inside",
            start: 0,
            end: 100,
        },
        {
            start: 0,
            end: 100,
        }
    ],
    tooltip: {
        trigger: "axis"
    },
    series: [
        {
            name: 'yAxis', // updates with yAxis name
            type: 'scatter',
            data: [],
            symbolSize: 6, // Set the size of the data points
        },
    ]
};