import ConfigService from "./ConfigService";
import jwt from "jwt-decode";
import { reload } from "../utilities/utils";
import { RoleInterface } from "../Config/interfaces/RoleInterface";

class RolesService extends ConfigService {

    async bindRolePermissions(realmId: string, roles: RoleInterface[]) {
        if (roles.length === 0) {
            return [];
        }
        for (let i = 0; i < roles.length; i++) {
            const fetchedPermissions = await this.getRolePermissions(realmId, roles[i].id);
            const permissions: any = { 'user': {}, 'org': {}, 'roles': {}, 'plant': {} };
            Object.keys(fetchedPermissions).forEach((key: string) => {
                const type = key.split("_")[0];
                permissions[type][key] = JSON.parse(fetchedPermissions[key]);
            });
            roles[i] = { ...roles[i], permissions };
        }
        return roles;
    }

    async getRolesData(realmId: string): Promise<RoleInterface[]> {
        if (!realmId) {
            return [];
        }
        const roles: RoleInterface[] = await this.bindRolePermissions(realmId, await this.getRoles(realmId));
        return roles;
    }

    async getRoles(realmID: string) {
        if (!realmID) {
            return;
        }
        try {
            return await fetch(this.keycloakBaseUrl + "clients/" + realmID + "/roles", {
                method: "GET",
                headers: this.headersList
            }).then(response => response.json());
        } catch (err) {
            console.error("Get roles Failed: ", err);
            reload();
        }
    }

    getLoggedInUserRole() {
        let decodedVal: any;
        if (this.accessToken != null) {
            decodedVal = jwt(this.accessToken);
        }
        return decodedVal?.resource_access[decodedVal.azp]?.roles;
    }

    async createRole(realmID: string, bodyContent: string) {
        if (!realmID) {
            return;
        }
        try {
            return await fetch(this.keycloakBaseUrl + "clients/" + realmID + "/roles", {
                method: "POST",
                body: bodyContent,
                headers: this.headersList
            });
        } catch (err) {
            console.error("Create role failed: ", err);
        }
    }

    async updateRole(realmID: string, editId: string, bodyContent: string) {
        if (!realmID) {
            return;
        }
        try {
            return await fetch(this.keycloakBaseUrl + "roles-by-id/" + editId + "?client=" + realmID, {
                method: "PUT",
                body: bodyContent,
                headers: this.headersList
            });
        } catch (err) {
            console.error("Update role failed: ", err);
        }
    }

    async deleteRole(realmID: string, deleteId: string) {
        if (!realmID) {
            return;
        }
        try {
            return await fetch(this.keycloakBaseUrl + "clients/" + realmID + "/roles/" + deleteId, {
                method: "DELETE",
                headers: this.headersList
            });
        } catch (err) {
            console.error("Delete role failed: ", err);
        }
    }

    async getRolePermissions(realmID: string, roleId: string) {
        if (!realmID) {
            return;
        }
        try {
            return await fetch(this.keycloakBaseUrl + "roles-by-id/" + roleId
                + "?client=" + realmID, {
                method: "GET",
                headers: this.headersList
            }).then(response => response.json()).then(data => data.attributes);
        } catch (err) {
            console.log("Get role permissions failed: ", err);
            reload();
        }

    }
}

export default RolesService;