import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlantInterface, PlantKpiInterface} from "../../Config/interfaces/PlantInterface";
import { Categories } from "../../Config/interfaces/KpiInterface";
import { PlantKpisDataInterface } from "../../Config/interfaces/TimeseriesInterface";

interface PlantState {
    plants: PlantInterface[] | [];
    orgTree: PlantInterface[] | [];
    selectedPlant: string;
    selectedPlantId: number;
    selectedPlantStatus: boolean;
    plantKpis: PlantKpiInterface[] | [];
    selectedKpiId: number;
    selectedCategoryId: number;
    categories: Categories[];
    kpisForecastData: Record<number, PlantKpisDataInterface[]>;
}

const initialState: PlantState = {
    plants: [],
    orgTree: [],
    selectedPlant: '',
    selectedPlantId: 0,
    selectedPlantStatus: false,
    plantKpis: [],
    selectedKpiId: 0,
    selectedCategoryId: 0,
    categories: [],
    kpisForecastData: {}
}

const plantSlice = createSlice({
    name: 'plant',
    initialState,
    reducers: {
        setPlants(state, action: PayloadAction<PlantInterface[]>): void {
            state.plants = action.payload;
        },
        setOrgTree(state, action: PayloadAction<PlantInterface[]>): void {
            state.orgTree = action.payload;
        },
        setSelectedPlant(state, action: PayloadAction<string>): void {
            state.selectedPlant = action.payload;
        },
        setSelectedPlantId(state, action: PayloadAction<number>): void {
            state.selectedPlantId = action.payload;
        },
        setCategories(state, action: PayloadAction<Categories[]>): void {
            state.categories = action.payload;
        },
        setPlantKpis(state, action: PayloadAction<PlantKpiInterface[] | []>): void {
            state.plantKpis = action.payload;
        },
        setSelectedKpiId(state, action: PayloadAction<number>): void {
            state.selectedKpiId = action.payload;
        },
        removePlantKpi(state, action: PayloadAction<number>): void {
            state.plantKpis = state.plantKpis.filter((kpi: PlantKpiInterface) => +kpi.id !== action.payload);
        },
        setSelectedPlantStatus(state, action: PayloadAction<boolean>): void {
            state.selectedPlantStatus = action.payload;
        },
        setSelectedCategoryId(state, action: PayloadAction<number>): void {
            state.selectedCategoryId = action.payload;
        },
        setKpisForecastData(state, action: PayloadAction<Record<number, PlantKpisDataInterface[]>>): void {
            state.kpisForecastData = action.payload;
        }
    }
});

export const {
    setPlants,
    setOrgTree,
    setSelectedPlant,
    setSelectedPlantId,
    setCategories,
    setPlantKpis,
    setSelectedKpiId,
    removePlantKpi,
    setSelectedPlantStatus,
    setSelectedCategoryId,
    setKpisForecastData
} = plantSlice.actions;
export default plantSlice.reducer;