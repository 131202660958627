import React, {FC, Key, useState} from "react";
import Select from "../../Shared/UI-Elements/Select/Select";
import Input from "../../Shared/UI-Elements/Input/Input";
import {IxButton} from "@siemens/ix-react";
import DateTimePickerInputs from "../../Shared/UI-Elements/DateTimePickerInputs/DateTimePickerInputs";
import {getFormattedStartEndDatesTimestamp} from "../../utilities/converters";
import {useToastPosition} from "../../hooks/useToastPosition";
import {useAppSelector} from "../../redux/hooks";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Checkbox from "../../Shared/UI-Elements/Checkbox/Checkbox";
import {DateTimePickerField} from "../../Config/interfaces/InsightsInterface";

const TrendForm: FC<{
    isLoading: boolean,
    createNewTrend: (payload: any) => void;
    updateTrend: (id: any, payload: any) => void;
    editMode: boolean;
    editableData?: any;
    trendId: number;
    trendFormShow: boolean;
    setCreateNewTrendShow: any;
    formFields: any;
    formDefaultValues: any;
}> = (props): JSX.Element => {
    let trendFormData = props.formFields;
    const [defaultFormValue] = useState(initializeDefaultFormData());
    const selectedPlantId = useAppSelector((state) => state.plantSlice.selectedPlantId);

    const {control, register, handleSubmit} = useForm({
        mode: 'onBlur',
        defaultValues: defaultFormValue,
        shouldUnregister: false,
        shouldUseNativeValidation: false
    });

    useToastPosition('top-right');

    function initializeDefaultFormData() {
        const formObject = JSON.parse(JSON.stringify(props.formDefaultValues));
        return Object.keys(formObject).reduce((acc: any, key: string) => {
            acc[key] = props.editMode && Object.keys(props.editableData).length
                ? key === "timeRange"
                    ? props.editableData[key]
                    : props.editableData[key]
                : formObject[key];
            return acc;
        }, {});
    }

    const onSubmit: SubmitHandler<any> = async (formData: any) => {
        const formDataClone = structuredClone(formData);
        const timeRange = getFormattedStartEndDatesTimestamp(formData?.timeRange?.startDateTime, formData?.timeRange?.endDateTime);
        if (timeRange?.length) {
            formDataClone.timeRange.startDateTime = timeRange[0];
            formDataClone.timeRange.endDateTime = timeRange[1];
        }

        const payload = {
            plantId: selectedPlantId,
            trendMetaInfo: {...formDataClone},
            timestamp: new Date(),
        };

        if (props.editMode) {
            props.updateTrend(props.trendId, payload);
        } else {
            props.createNewTrend(payload);
        }
    }

    return (
        <>
            <form
                className={`m-3 needs-validation`}
                onSubmit={handleSubmit(onSubmit)}>
                <section className={`form-inputs-block-trend`}>
                    {
                        trendFormData.map((input: any, i: Key) => {
                            if (input.type === 'select') {
                                return (
                                    <div key={i} className={`my-3`}>
                                        <label htmlFor={input.name}
                                               className={`mb-1 text-default-title ${input.rules.required ? 'is_required' : ''}`}>
                                            {input.label}
                                        </label>
                                        <Controller
                                            key={input.name}
                                            name={`${input?.name}` as const}
                                            control={control}
                                            rules={input.rules}
                                            render={({field}) => (
                                                <Select
                                                    mode={input.mode}
                                                    field={field}
                                                    placeholder={input.placeholder}
                                                    options={input.options}
                                                    readonly={input.readonly}
                                                />
                                            )}
                                        />
                                    </div>
                                );
                            } else if (input.type === 'text' || input.type === 'hidden' || input.type === 'number') {
                                return (
                                    <div key={i} className={`my-3`}>
                                        <label htmlFor={input.name}
                                               className={`mb-1 text-default-title ${input.rules.required ? 'is_required' : ''}`}>
                                            {input.label}
                                            {input.value}
                                        </label>
                                        <Controller
                                            key={input.name}
                                            name={`${input?.name}` as const}
                                            control={control}
                                            rules={input.rules}
                                            render={({field}) => (
                                                <Input
                                                    name={input.name}
                                                    type={input.type}
                                                    placeholder={input.placeholder}
                                                    field={field}
                                                    readOnly={input.readonly}
                                                />
                                            )}
                                        />
                                    </div>
                                );
                            } else if (input.type === 'dateRangePicker') {
                                return (
                                    <div key={i}>
                                        <div className={`my-3`} key={input.name}>
                                            <label className={`text-default-title is_required`}>
                                                Time Range
                                            </label>
                                            {
                                                input?.fields?.map((datetimePickerField: DateTimePickerField) => (
                                                    <Controller
                                                        key={input.name + datetimePickerField.name}
                                                        name={`${input.name}.${datetimePickerField.name}` as const}
                                                        control={control}
                                                        rules={datetimePickerField.rules}
                                                        render={({field}) => (
                                                            <DateTimePickerInputs
                                                                type={datetimePickerField.type}
                                                                field={field}
                                                                datePlaceholder={datetimePickerField.datePlaceholder}
                                                                timePlaceholder={datetimePickerField.timePlaceholder}
                                                            />
                                                        )}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                );
                            } else if (input.type === 'checkbox') {
                                return (
                                    <div className={`my-3`} key={input.name}>
                                        <Controller
                                            key={input.name}
                                            name={`${input?.name}` as const}
                                            control={control}
                                            rules={input.rules}
                                            render={({field}) => (
                                                <Checkbox
                                                    name={input.name}
                                                    label={input.label}
                                                    readonly={input.readonly}
                                                    field={field}
                                                />
                                            )}
                                        />
                                    </div>
                                );
                            }
                        })
                    }
                </section>

                <footer className={`m-2 py-2`}>
                    <div className={`d-flex flex-row justify-content-center`}>
                        <IxButton class="m-1" outline onClick={() => props.setCreateNewTrendShow(false)}>
                            Cancel
                        </IxButton>
                        <IxButton type="submit" disabled={props.isLoading} class="m-1" variant="Primary">
                            Generate Graph
                        </IxButton>
                    </div>
                </footer>
            </form>
        </>
    );
};

export default TrendForm;