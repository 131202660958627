import { RealmRoles } from "../Config/RealmRoles";
import ConfigService from "./ConfigService";
import { reload } from "../utilities/utils";

class UsersService extends ConfigService {

    getAssignedRoleFormat(roles: any[], realmID: string): string {
        const rolesMap: any[] = [];
        roles.forEach((role) => {
            rolesMap.push({
                "id": role.id,
                "name": role.name,
                "description": role.name + " Role",
                "composite": false,
                "clientRole": true,
                "containerId": realmID
            });
        })
        return JSON.stringify(rolesMap);

    }

    async mapRealmDefaultRoles(userID: string): Promise<void> {
        if (!userID) {
            console.error("User ID is missing");
            return;
        }

        await fetch(this.keycloakBaseUrl + "users/" + userID + "/role-mappings/clients/e45c9b84-4f9c-44e0-98de-0438b906c33f", {
            method: "POST",
            body: RealmRoles.getAccountRoles(),
            headers: this.headersList
        });

        await fetch(this.keycloakBaseUrl + "users/" + userID + "/role-mappings/clients/9435e103-160a-4c22-ad27-c7cd1b4d9abb", {
            method: "POST",
            body: RealmRoles.getRealmRoles(),
            headers: this.headersList
        });
    }

    async deleteTheExistingRole(userID: string, rolesArr: string, realmID: string): Promise<void> {
        if (!userID || !realmID) {
            console.error("User ID or Realm ID is missing");
            return;
        }
        if (rolesArr.length) {
            try {
                await fetch(this.keycloakBaseUrl + "users/" + userID + "/role-mappings/clients/" + realmID, {
                    method: "DELETE",
                    body: JSON.stringify(rolesArr),
                    headers: this.headersList
                });
            } catch (err) {
                console.error('Failed to delete the existing role: ', err);
            }
        }
    }

    async assignTheRoleToUser(userID: string, roles: any[], realmID: string) {
        // POST roles list available in the realm
        try {
            await fetch(this.keycloakBaseUrl + "users/" + userID + "/role-mappings/clients/" + realmID, {
                method: "POST",
                body: this.getAssignedRoleFormat(roles, realmID),
                headers: this.headersList
            });
        } catch (err) {
            console.error('Failed to assign role to the user: ', err);
        }
    }

    async getUsersList() {
        try {
            return await fetch(this.keycloakBaseUrl + 'users', {
                method: "GET",
                headers: this.headersList
            }).then(response => response.json());
        } catch (err) {
            console.error("Get user Failed: ", err);
            reload();
        }
    }

    async getUsersProfile(realmID: string) {
        try {
            return await fetch(this.serverBaseUrl + '/api/users/' + realmID, {
                method: "GET",
                headers: this.headersList
            }).then(response => response.json());
        } catch (err) {
            console.error("Could not retrieve messages: ", err);
            reload();
        }
    }

    async addUpdateUser(bodyContent: string, prop: any): Promise<any> {

        if (!prop.editMode) {
            try {
                return await fetch(this.keycloakBaseUrl + "users", {
                    method: prop.editMode ? "PUT" : "POST",
                    body: bodyContent,
                    headers: this.headersList
                });

                // prop.onSave(newFormData);
            } catch (error) {
                console.error("API", error);
            }

        } else {
            try {
                return await fetch(this.keycloakBaseUrl + "users/" + prop.editId, {
                    method: "PUT",
                    body: bodyContent,
                    headers: this.headersList
                });
            } catch (err) {
                console.error('API call failed: ', err);
            }
        }
    }

    async sendEmailToUser(userID: string): Promise<void> {
        let bodyExecuteAction = JSON.stringify(
            ["VERIFY_EMAIL", "UPDATE_PASSWORD", "UPDATE_PROFILE"]
        );
        let redirectUri = `${process.env.REACT_APP_HTTPS_PROTOCOL}${this.appIpAddress}:${this.appPort}/users`;
        try {
            let executeResp = await fetch(this.keycloakBaseUrl + "users/" + userID + "/execute-actions-email?redirect_uri =" + redirectUri, {
                method: "PUT",
                body: bodyExecuteAction,
                headers: this.headersList

            });
            console.log("success in sending email", executeResp);
        } catch (error) {
            console.error("Error in sending email: ", error);
        }
    }
}

export default UsersService;