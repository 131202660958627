// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormulaEditorModal_shadow_box__RxDse {
  box-shadow: var(--theme-shadow-3); }

.FormulaEditorModal_tag_list__8tinR {
  height: auto;
  max-height: 55vh;
  overflow: auto; }

.FormulaEditorModal_tag_label__YNchg {
  opacity: 0.7;
  margin-bottom: .25rem; }

.FormulaEditorModal_textarea_color__8Wqvm {
  color: var(--theme-color-contrast-bdr);
  background-color: var(--theme-color-lightbox); }
`, "",{"version":3,"sources":["webpack://./src/Components/Kpis/FormulaEditor/FormulaEditorModal.module.scss"],"names":[],"mappings":"AAAA;EACE,iCAAiC,EAAA;;AAGnC;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc,EAAA;;AAGhB;EACE,YAAY;EACZ,qBAAqB,EAAA;;AAGvB;EACE,sCAAsC;EACtC,6CAA6C,EAAA","sourcesContent":[".shadow_box {\n  box-shadow: var(--theme-shadow-3);\n}\n\n.tag_list {\n  height: auto;\n  max-height: 55vh;\n  overflow: auto;\n}\n\n.tag_label {\n  opacity: 0.7;\n  margin-bottom: .25rem;\n}\n\n.textarea_color {\n  color: var(--theme-color-contrast-bdr);\n  background-color: var(--theme-color-lightbox);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadow_box": `FormulaEditorModal_shadow_box__RxDse`,
	"tag_list": `FormulaEditorModal_tag_list__8tinR`,
	"tag_label": `FormulaEditorModal_tag_label__YNchg`,
	"textarea_color": `FormulaEditorModal_textarea_color__8Wqvm`
};
export default ___CSS_LOADER_EXPORT___;
