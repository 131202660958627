import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setPlantKpis } from "../redux/slices/plantSlice";
import { PlantKpisDataInterface } from "../Config/interfaces/TimeseriesInterface";

const usePushDataIntoPlantKpis = (timeSeriesData: Record<number, PlantKpisDataInterface[]>) => {
	const dispatch = useAppDispatch();
	const plantKpis = useAppSelector((state) => state.plantSlice.plantKpis);

	useEffect(() => {
		pushTimeSeriesDataIntoExistingKpis();
	}, [timeSeriesData]);

	const pushTimeSeriesDataIntoExistingKpis = () => {		
		if (
			!plantKpis?.length ||
			!timeSeriesData ||
			typeof timeSeriesData !== "object" ||
			!Object.keys(timeSeriesData).length
		) {
			return;
		}

		try {
			const tempPlantKpis = structuredClone(plantKpis);

			const clearTimeseries = (kpi: any) => {
				kpi.timeseries = [];
				kpi.IotData.time = [];
			}

			tempPlantKpis.length &&
				tempPlantKpis?.forEach((kpi: any) => {
					const kpiData: any = timeSeriesData?.[kpi?.id];

					if (!kpiData?.length) {
						return;
					}

					if (kpiData?.length > 1) {
						clearTimeseries(kpi);
						kpiData.reverse().forEach((data: PlantKpisDataInterface) => {
							kpi?.timeseries.push(data?.value);
							kpi?.IotData?.time.push(data?.timestamp);
						});
					} else {
						kpi?.timeseries.push(kpiData?.[0]?.value);
						kpi?.IotData?.time.push(kpiData?.[0]?.timestamp);
					}
					kpi.IotData.value = kpiData?.[kpiData.length - 1]?.value ?? 'N/A';
					kpi.IotData.currentTimestamp = kpiData?.[kpiData.length - 1]?.timestamp ?? 'N/A';

					if (kpi.timeseries.length > 55 && kpi.IotData.time.length > 55) {
						kpi.timeseries.splice(0, 5);
						kpi.IotData.time.splice(0, 5);
					}
				});

			dispatch(setPlantKpis(tempPlantKpis));
		} catch (error) {
			console.error("Unknown error: ", error);
		}
	};
};

export default usePushDataIntoPlantKpis;
