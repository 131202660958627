import { IxDatetimePicker, IxIcon, IxInputGroup } from "@siemens/ix-react";
import { getCurrentDate, getFormattedDateTime } from '../../../utilities/converters';
import { useEffect, useRef, useState } from "react";
import { useDatePickerDetectClick } from "../../../hooks/useDatePickerDetectClick";

interface DateTimePickerInputProps {
    type: string
    dateTimePlaceholder: string;
    setDateTime: any;
    disabled?: boolean;
}

const DateTimePickerInput = ({disabled, type, dateTimePlaceholder, setDateTime}: DateTimePickerInputProps) => {
    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [selectedTime, setSelectedTime] = useState<string>('');
    const [formattedDateTime, setFormattedDateTime] = useState<string>(
            getFormattedDateTime((getCurrentDate() + ' ' + getTimeBasedOnStartEndDate()))
        );
    const startDatePickerInputRef = useRef<HTMLInputElement>(null);
    const startDatePickerRef = useRef<HTMLDivElement>(null);

    useDatePickerDetectClick(startDatePickerInputRef, startDatePickerRef, setShowDateTimePicker);

    useEffect(() => {
        const dateTimeArr = formattedDateTime.split(' ');
        const dateTime = {date: dateTimeArr[0], time: selectedTime ? selectedTime : dateTimeArr[1]};
        
        setDateTime(dateTime);
    }, [formattedDateTime]);

    function getTimeBasedOnStartEndDate(): string {
        return type === 'startDate' ? '00:00:00' : '23:59:59';
    }

    return (
        <div className={`mb-2 mt-1 me-2 d-flex flex-row justify-content-between`}>
            <div className={`date_time_input`}>
                <IxInputGroup>
                    <input
                        className="form-control"
                        value={formattedDateTime ? formattedDateTime : dateTimePlaceholder}
                        type="text"
                        readOnly={!disabled ?? true}
                        onClick={() => setShowDateTimePicker(!showDateTimePicker)}
                        ref={startDatePickerInputRef}
                        disabled={disabled ?? false}
                    />
                    <span slot="input-end" className={`input-end-icon`}>
                        <IxIcon name="calendar" size="16" />
                    </span>
                </IxInputGroup>

                {
                    showDateTimePicker ? (
                        <div ref={startDatePickerRef} className={`mt-1 date_picker date_picker_center`}>
                            <IxDatetimePicker from={selectedDate ? selectedDate : getCurrentDate()}
                                              time={selectedTime ? selectedTime : getTimeBasedOnStartEndDate()}
                                              to={null}
                                              range={false}
                                              maxDate={getCurrentDate()}
                                              onDateSelect={(event) => {
                                                  setShowDateTimePicker(false);
                                                  setSelectedDate(event?.detail?.from ? event?.detail?.from : getCurrentDate());
                                                  setSelectedTime(event?.detail?.time ? event?.detail?.time : getTimeBasedOnStartEndDate());
                                                  setFormattedDateTime(
                                                      getFormattedDateTime(event?.detail?.from ? (event?.detail?.from + ' ' + event?.detail?.time) : (getCurrentDate() + ' ' + getTimeBasedOnStartEndDate()) )
                                                  );
                                              }}/>
                        </div>
                    ) : ''
                }
            </div>
        </div>
    );
}

export default DateTimePickerInput;