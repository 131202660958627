// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  vertical-align: middle; }

.Userlist_assigned_plants__CxsOO {
  width: 20vw;
  height: auto;
  max-height: 12vh;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/UserManagement/Users/UserLists/Userlist.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB,EAAA;;AAG1B;EACI,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,cAAc,EAAA","sourcesContent":["td {\n    vertical-align: middle;\n}\n\n.assigned_plants {\n    width: 20vw;\n    height: auto;\n    max-height: 12vh;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assigned_plants": `Userlist_assigned_plants__CxsOO`
};
export default ___CSS_LOADER_EXPORT___;
