import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import AlarmService from '../Services/AlarmService';
import { useInject } from "./useInject";
import { setAlarmCount, setAlarms } from "../redux/slices/alarmSlice";
import { Alarms } from "../Config/interfaces/AlarmInterface";
import { AlarmEnum } from "../Config/enums/AlarmsEnum";

const usePollAlarmsOnInterval = () => {
    const dispatch = useAppDispatch();
    const alarmService = useInject(AlarmService);
    const interval = +(process.env.REACT_APP_INTERVAL_IN_MICROSECONDS ?? 10000);
    const selectedPlantId = useAppSelector(state => state.plantSlice.selectedPlantId);

    useEffect(() => {
        const intervalId = pollAlarmsOnInterval();

        return () => clearInterval(intervalId);
    }, [selectedPlantId]);

    const pollAlarmsOnInterval = () => {
        getAlarms();
        return setInterval(async () => {      
          await getAlarms();
        }, interval);
    }

    const getAlarms = async () => {
        const alarmsObj: Alarms = {
            [AlarmEnum.actual]: [],
            [AlarmEnum.forecasted]: []
        }

        try {
            const alarmInfo = await alarmService.fetchAlarms(selectedPlantId);

            const alarms = alarmInfo?.reduce((acc: any, alarm: any) => {
                acc[alarm.type] = acc[alarm.type] || [];
                acc[alarm.type].push(alarm);

                return acc;
            }, {});

            alarmsObj[AlarmEnum.actual] = alarms?.[AlarmEnum.actual] ?? [];
            alarmsObj[AlarmEnum.forecasted] = alarms?.[AlarmEnum.forecasted] ?? [];

            dispatch(setAlarmCount(alarmsObj?.[AlarmEnum.actual]?.length ?? 0));
            dispatch(setAlarms(alarmsObj));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
}

export default usePollAlarmsOnInterval;
