// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_settings__DfLq5 {
  max-width: 99%; }
  .settings_settings__DfLq5 .settings_settings_container__q3xJG {
    min-height: 500px;
    height: auto; }
    .settings_settings__DfLq5 .settings_settings_container__q3xJG div.settings_left_col__xEe57,
    .settings_settings__DfLq5 .settings_settings_container__q3xJG div.settings_right_col__1a7Vn {
      height: fit-content; }
      .settings_settings__DfLq5 .settings_settings_container__q3xJG div.settings_left_col__xEe57 ix-tooltip,
      .settings_settings__DfLq5 .settings_settings_container__q3xJG div.settings_right_col__1a7Vn ix-tooltip {
        margin-left: 80px;
        width: fit-content; }
      .settings_settings__DfLq5 .settings_settings_container__q3xJG div.settings_left_col__xEe57 ix-datetime-picker,
      .settings_settings__DfLq5 .settings_settings_container__q3xJG div.settings_right_col__1a7Vn ix-datetime-picker {
        position: absolute;
        z-index: 2;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.271); }
`, "",{"version":3,"sources":["webpack://./src/Settings/settings.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAc,EAAA;EADlB;IAIQ,iBAAiB;IACjB,YAAY,EAAA;IALpB;;MASY,mBAAmB,EAAA;MAT/B;;QAYgB,iBAAiB;QACjB,kBAAkB,EAAA;MAblC;;QAiBgB,kBAAkB;QAClB,UAAU;QACV,8CAA8C,EAAA","sourcesContent":[".settings {\n    max-width: 99%;\n\n    .settings_container {\n        min-height: 500px;\n        height: auto;\n\n        & div.left_col,\n        div.right_col {\n            height: fit-content;\n\n            & ix-tooltip {\n                margin-left: 80px;\n                width: fit-content;\n            }\n\n            & ix-datetime-picker {\n                position: absolute;\n                z-index: 2;\n                box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.271);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `settings_settings__DfLq5`,
	"settings_container": `settings_settings_container__q3xJG`,
	"left_col": `settings_left_col__xEe57`,
	"right_col": `settings_right_col__1a7Vn`
};
export default ___CSS_LOADER_EXPORT___;
