export const initialStatData = {
    count: 0,
    sum: 0,
    max: 'NA',
    min: 'NA',
    avg: 0,
    lowerLimit: 'NA',
    upperLimit: 'NA',
    timeseries: []
    
  }