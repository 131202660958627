import Keycloak from "keycloak-js";
import keycloakConfig from "../keycloakConfig";

const keycloakInstance = new Keycloak(keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback: Function) => {
  keycloakInstance
    .init({ onLoad: "login-required", checkLoginIframe: false })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert("non authenticated");
    })
    .catch(function (e) {
      console.log(`keycloak init exception ${e}`);
    });
};

//Logout function call
const doLogout = keycloakInstance.logout;

//Get token 
let getToken:any = () => keycloakInstance.token;
//localStorage.setItem('token',JSON.stringify(keycloakInstance.token));

//Refresh token after expiry
const updateToken = (successCallback: any) =>

  keycloakInstance.updateToken(5)
    .then(function (refreshed) {
      if (refreshed) {
        console.log('Token was successfully refreshed');
        localStorage.setItem('token',JSON.stringify(keycloakInstance.token));
      } else {
        console.log('Token is still valid');
      }
    }).catch(function () {
      console.log('Failed to refresh the token, or the session has expired');
    }
    );

//Get Current user name
const getUserName = () => keycloakInstance.tokenParsed?.preferred_username;

//Get Current user id
const getUserId= () => keycloakInstance.tokenParsed?.sub;

//Roles
const hasRoles = (roles: any) => roles.some((role: any) => keycloakInstance.hasRealmRole(role));

//Exports
const KeyCloakService = {
  CallLogin: Login,
  doLogout: doLogout,
  getToken: getToken,
  updateToken: updateToken,
  getUserName: getUserName,
  getUserId: getUserId,
};

export default KeyCloakService;