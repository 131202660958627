import { useEffect, useState } from 'react';
import CheckboxTree from "react-checkbox-tree";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import treeStyles from "./checklistTreeStruct.module.scss";
import { IxIcon } from "@siemens/ix-react";
import { useInject } from "../../hooks/useInject";
import RolesService from "../../Services/RolesService";
import { RolesEnum } from "../../Config/enums/RolesEnum";
import { useAppSelector } from "../../redux/hooks";

export default function ChecklistTreeStruct(props: any, { plantsAlloted }: { plantsAlloted: any }) {

    const rolesService = useInject(RolesService);
    const [checkedTree, setCheckedTree] = useState([]);
    const [expandedTree, setExpandedTree] = useState(["1"]);
    const [loggedInUserRole] = useState(rolesService.getLoggedInUserRole());
    const nodes: any = [];
    const plantList = useAppSelector(state => state.plantSlice.orgTree);

    const getChildPlants = (interPlant: any) => {
        let grandChildren: any = [];
        plantList.filter((dat: any) => {
            return interPlant.SuborgId === dat.ParentId;
        }).map((plantInner: any) => {
            let nodeInnerChildren: any = {};
            if (plantInner.IsPlant) {
                nodeInnerChildren.label = plantInner.SubOrgName;
                nodeInnerChildren.value = plantInner.SubOrgName;

            }
            else {
                nodeInnerChildren.label = plantInner.SubOrgName;
                nodeInnerChildren.value = plantInner.SubOrgName;
                let children: any = [];
                children = getChildPlants(plantInner);
                nodeInnerChildren.children = children;
            }
            grandChildren.push(nodeInnerChildren);
            return plantInner;
        });
        return grandChildren;
    }

    plantList.filter((dat: any) => dat.ParentId === 0)
        .map((plant: any) => {
            let nodeElement: any = {};

            if (plant.IsPlant) {
                nodeElement.value = plant.SubOrgName;
                nodeElement.label = plant.SubOrgName;
                nodes.push(nodeElement);
            }
            else {
                nodeElement.value = plant.SubOrgName;
                nodeElement.label = plant.SubOrgName;
                let innerEle = getChildPlants(plant);
                nodeElement.children = innerEle;
                nodes.push(nodeElement)
            }
            return plant;
        });

    useEffect(() => {
        const defaultSelectedPlants = props.selectedPlants && props.selectedPlants.length ? props.selectedPlants : [];
        oncheck(defaultSelectedPlants);

        return () => {
            oncheck([])
        }

    }, [])

    const icons = {
        check: <IxIcon name="checkbox-component-checked" size="16"></IxIcon>,
        uncheck: <IxIcon name="checkbox-component-unchecked" size="16"></IxIcon>,
        halfCheck: (
            <IxIcon name="checkbox-component-mixed" size="16"></IxIcon>
        ),
        expandClose: (
            <IxIcon name="chevron-right-small" size="16"></IxIcon>
        ),
        expandOpen: (
            <IxIcon name="chevron-down-small" size="16"></IxIcon>
        ),
        expandAll: <IxIcon name="tree" size="16"></IxIcon>,
        collapseAll: (
            <IxIcon name="checkbox-component-mixed" size="16"></IxIcon>
        ),
        parentClose: null, parentOpen: null, leaf: null
    };

    let checkedArr: any;
    const oncheck = (checkedTree: any) => {
        setCheckedTree(checkedTree);
        checkedArr = checkedTree;
        props.setPlantsDataArr(checkedArr)
    };

    return (
        <div className={`my-2 p-2 ${treeStyles.tree_view}`}>
            <CheckboxTree
                nodes={nodes}
                checked={checkedTree}
                expanded={expandedTree}
                onCheck={(oncheck)}
                disabled={!loggedInUserRole.includes(RolesEnum.admin)}
                onExpand={(expandedTree) => setExpandedTree(expandedTree)}
                icons={icons}
            />
        </div>
    );
}

