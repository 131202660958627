import {showToast} from "@siemens/ix-react";

export function validateUserInputFields(values: {
    username?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    roles: string[];
    userplants?: never[];
}, users: any, editMode: boolean): boolean {
    const validationRules = [
        { condition: !values.username?.trim(), message: 'Username is required' },
        { condition: values.username && findDuplicateUsername(values.username?.trim(), users), message: 'Username is already in use' },
        { condition: values.email?.trim() && !validateEmail(values.email?.trim()), message: 'Please enter a valid email address' },
        { condition: !values.firstname?.trim(), message: 'First name is required' },
        { condition: !values.lastname?.trim(), message: 'Last name is required' },
        { condition: !editMode && findDuplicateUser(values.email?.trim(), users), message: 'Email is already in use' },
        { condition: !values.roles, message: 'Role is required' },
    ];

    const failedValidation = validationRules.find(rule => rule.condition);

    if (failedValidation) {
        showToast({
            type: 'error',
            title: '',
            message: failedValidation.message,
            autoCloseDelay: 3000
        });
        return true;
    }

    return false;
}

export function validateEmail(email: string | undefined): RegExpMatchArray | null {
    return String(email)
        ?.toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function findDuplicateUser(email: any, users: any): boolean {
    return users.some((user: { email: any; }) => user?.email === email?.toLowerCase());
}
export function findDuplicateUsername(username: string, users: any): boolean {
    return users.some((user: { username: any; id: string }) => user?.username === username?.toLowerCase() && !user.id);
}