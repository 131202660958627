import { IxDatetimePicker, IxDivider, IxIcon, IxSelect, IxSelectItem, IxToggle, IxTooltip, showModal, showToast } from "@siemens/ix-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useDatePickerDetectClick } from "../hooks/useDatePickerDetectClick";
import { useInject } from "../hooks/useInject";
import { PlantInterface } from "../Config/interfaces/PlantInterface";
import { setAutoDeletionEnabled, setAutoDeletionInterval, setOldestTimestamp } from "../redux/slices/settingsSlice";
import { ToastType } from "../Config/constants/ToastMessage";
import BreadCrumb from "../Shared/Breadcrumb/BreadCrumb";
import styles from './settings.module.scss';
import ConfirmModal from "../Shared/ConfirmModal/ConfirmModal";
import SettingsService from "../Services/SettingsService";
import KeyCloakService from "../App/KeycloakService";
import { getFormattedDateTimeReadable, isAfter, subtractFromDate } from "../utilities/converters";

export interface SettingsInterface {
    startDate: string;
    theme: string;
    autoDeletionEnabled: boolean;
    autoDeletionInterval: number;
    selectedPlants: string;
}

const Settings = () => {
    const settingsSlice = useAppSelector(state => state.settingsSlice);
    const [showTimeframeSelection, setShowTimeframeSelection] = useState(false);
    const [selectedPlants, setSelectedPlant] = useState<PlantInterface[]>([]);
    const settingsService: SettingsService = useInject(SettingsService);
    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [endDate, setEndDate] = useState('');
    const [dateTime, setDateTime] = useState({ date: "", time: "" });
    const [saveBtnState, setSaveBtnState] = useState(true);
    const [showDeletionHistory, setShowDeletionHistory] = useState([]);
    const startDate = settingsSlice.oldestTimestamp;
    const selectedAutoDeletionValue = settingsSlice.autoDeletionInterval;
    const showDateTimePickerSpanRef = useRef<HTMLSpanElement>(null);
    const showDateTimePickerDivRef = useRef<HTMLDivElement>(null);
    const plantsList = useAppSelector(state => state.plantSlice.plants);
    const dispatch = useAppDispatch();

    useDatePickerDetectClick(showDateTimePickerSpanRef, showDateTimePickerDivRef, setShowDateTimePicker);

    useEffect(() => {
        setShowTimeframeSelection(settingsSlice.autoDeletionEnabled);
        setSelectedPlant(settingsSlice.selectedPlants);
    }, [settingsSlice.autoDeletionEnabled, settingsSlice.selectedPlants]);

    useEffect(() => {
        fetchData();
    },[]);

    useEffect(() => {
        setSaveBtnState(!(startDate && endDate && selectedPlants.length > 0));
    }, [selectedPlants, startDate, endDate]);

    const fetchData = async () => {
        if (!settingsSlice.oldestTimestamp) {
            await getStartDate();
        }
        await getSettingsData();
        await getDeletionHistory();
    }

    const getEndDate = () => {
        const currentDate = new Date();
        let endDate = subtractFromDate(new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`), settingsSlice.autoDeletionInterval, 'months').toISOString().split('T')[0];
        if (startDate && isAfter(new Date(startDate), new Date(endDate))) {
            endDate = startDate.split(' ')[0];
        }
        const dateAndTime = { date: endDate, time: "23:59:59" };
        setDateTime(dateAndTime);
        setEndDate(`${dateAndTime.date} ${dateAndTime.time}`);
    }

    const getSettingsData = async () => {
        setShowTimeframeSelection(settingsSlice.autoDeletionEnabled);
        handleChange({ target: { value: settingsSlice.autoDeletionInterval } });
        setSelectedPlant(settingsSlice.selectedPlants);
    }

    const getStartDate = async () => {
        if (!startDate) {
            try {
                const response = await settingsService.getOldestTimestamp();
                const data = await response.json();
                dispatch(setOldestTimestamp(`${data.min.split('T')[0]} ${data.min.split('T')[1].split('.')[0]}`));
            } catch (error) {
                console.error('Error fetching oldest timestamp:', error);
            }
        }
    }

    const getDeletionHistory = async () => {
        try {
            const response = await settingsService.getDeletionHistory();
            const data = await response.json();
            if (data.length) {
                setShowDeletionHistory(data);
            }
        } catch (error) {
            console.error('Error fetching deletion history:', error);
        }
    };

    const handleChange = (e: any) => {
        dispatch(setAutoDeletionInterval(e.target.value));
        getEndDate();
    }

    const updateDateTime = (timestamp: string) => {
        setShowDateTimePicker(false);
        const [date, , time] = timestamp.split(' - ');
        setDateTime({ date, time });
        setEndDate(`${date} ${time}`);
    }

    const saveSettings = async () => {
        const settings: SettingsInterface = {
            startDate,
            theme: settingsSlice.theme,
            autoDeletionEnabled: settingsSlice.autoDeletionEnabled,
            autoDeletionInterval: settingsSlice.autoDeletionInterval,
            selectedPlants: JSON.stringify(settingsSlice.selectedPlants)
        };
        try {
            const res = await settingsService.updateSettings(settings);
            if (res.status === 'success') {
                showToast({
                    type: ToastType.success,
                    title: 'Success',
                    message: 'Settings saved successfully'
                });
            }
            return res;
        } catch (error) {
            console.error('Error while saving settings', error);
            showToast({
                type: ToastType.error,
                title: 'Error',
                message: 'Error while saving settings'
            });
        }
    }

    const updateDeletionHistory = async () => {
        const deletionHistory = {
            deletedAt: new Date().toISOString(),
            deletedBy: KeyCloakService.getUserName(),
            start: startDate,
            end: endDate,
            plantsName: settingsSlice.selectedPlants.map((plant) => plant.SubOrgName).join(', '),
            type: settingsSlice.autoDeletionEnabled ? 'Auto' : 'Manual'
        };
        try {
            const res = await settingsService.saveDeletionHistory(deletionHistory);
            if (res.status === 'success') {
                showToast({
                    type: ToastType.success,
                    title: 'Success',
                    message: 'Deletion history saved successfully'
                });
            }
        } catch (error) {
            console.error('Error while updating deletion history', error);
            showToast({
                type: ToastType.error,
                title: 'Error',
                message: 'Error while updating deletion history'
            });
        }
    }

    const deleteData = async () => {
        const plantIds = settingsSlice.selectedPlants.map((plant) => plant.SuborgId);
        try {
            const res = await settingsService.deleteData({ plantIds, end: endDate });
            if (res.status === 'success') {
                showToast({
                    type: ToastType.success,
                    title: 'Success',
                    message: res.rowsDeleted > 0 ? res.message : 'No data found to delete'
                });
                if (res.rowsDeleted > 0) {
                    updateDeletionHistory();
                }
            }
        } catch (error) {
            console.error('Error while deleting data', error);
            showToast({
                type: ToastType.error,
                title: 'Error',
                message: 'Error while deleting data'
            });
        }
    }

    const confirmDeleteData = async (e: any) => {
        if (e !== 'yes') {
            return;
        }
        const res = await saveSettings();
        if (res.status !== 'success') {
            return;
        }
        await deleteData();
    }

    const showConfirmModal = () => {
        showModal({
            title: 'deleteConfirmationModal',
            icon: 'warning-filled',
            iconColor: 'color-warning',
            size: 'lg',
            content: <ConfirmModal onAction={confirmDeleteData}
                confirmationText={'Are you sure?'}
                confirmationSubText={`Do you really want to delete historical data permanently <br/> <p><h6><strong>From:</strong> ${startDate ? getFormattedDateTimeReadable(startDate): ''}&emsp; <strong>To:</strong> ${endDate ? getFormattedDateTimeReadable(endDate) : ''} ?</h6></p>`}
            />,
        });
    }

    const updateSelectedPlant = (checked: boolean, plant: PlantInterface) => {
        setSelectedPlant((prevSelectedPlants) => {
            if (checked) {
                return [...prevSelectedPlants, plant];
            } else {
                return prevSelectedPlants.filter((p) => p.SubOrgName !== plant.SubOrgName);
            }
        });
    }

    const resetData = () => {
        setShowTimeframeSelection(false);
        dispatch(setAutoDeletionEnabled(false));
        dispatch(setAutoDeletionInterval(6));
        setSelectedPlant([]);
    }

    return (
        <>
            <div className={`m-3 container-fluid ${styles.settings}`}>
                <BreadCrumb itemString="Settings"></BreadCrumb>
                <section className='m-1 d-flex justify-content-between align-items-center'>
                    <div className="text-default text-gray py-2">
                        <span>Settings page is available</span>
                        <p><sub>We recommend, at least, to keep 6 months' data. Datepicker will allow selection accordingly</sub></p>
                    </div>
                </section>
                <div className={`${styles.settings_container} row`}>
                    <div className={`col-12 col-md-6 ${styles.left_col}`}>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center">
                                <span key="legacyDataText" className="col-9">Enable legacy data deletion</span>
                                <IxToggle
                                    checked={showTimeframeSelection}
                                    hideText={true} className="ms-auto"
                                    onCheckedChange={(e) => {
                                        e.stopPropagation();
                                        setShowTimeframeSelection(!showTimeframeSelection);
                                        dispatch(setAutoDeletionEnabled(e.target.checked))
                                    }}
                                ></IxToggle>
                            </div>
                        </div>
                        {showTimeframeSelection && (
                            <div className="mt-3 row">
                                <div className="col-12 d-flex align-items-center">
                                    <span key="autoDeleteText" className="col-8">Auto-delete data older than:</span>
                                    <IxSelect className="col-4" editable={false} mode="single" selectedIndices={selectedAutoDeletionValue + ''}>
                                        <IxSelectItem label="6 Months" value="6" onItemClick={handleChange} ></IxSelectItem>
                                        <IxSelectItem label="9 Months" value="9" onItemClick={handleChange} ></IxSelectItem>
                                        <IxSelectItem label="1 Year" value="12" onItemClick={handleChange} ></IxSelectItem>
                                    </IxSelect>
                                </div>
                            </div>
                        )}
                        {!showTimeframeSelection &&
                            <div className="mt-3">
                                <div className="row">
                                    <span key="cleanupText" className="col-8 col-md-7 d-flex align-items-center">
                                        Clean-up historical data &nbsp;<IxIcon name="info" size="16" />
                                    </span>
                                    <IxTooltip for="[name='info']" placement="bottom">
                                        It will permanently delete historical data <br /> from start (oldest available timestamp in your DB is "{startDate ? getFormattedDateTimeReadable(startDate) : ''}") to till the "{endDate ? getFormattedDateTimeReadable(endDate) : ''}"
                                    </IxTooltip>
                                    <span
                                        ref={showDateTimePickerSpanRef} key="datetimePicker" className="col-auto ms-auto"
                                        onClick={() => setShowDateTimePicker(!showDateTimePicker)}
                                    >
                                        <span key="dateTimeBox" className="form-control d-flex align-items-center">
                                            <span key="dateTime">{dateTime.date + " " + dateTime.time}</span>
                                            <span key="calendarIcon" slot="input-end" className="ms-2">
                                                <IxIcon name="calendar" size="16" />
                                            </span>
                                        </span>
                                    </span>
                                    {showDateTimePicker && (
                                        <div ref={showDateTimePickerDivRef} className="mt-3">
                                            <IxDatetimePicker
                                                dateFormat="yyyy-LL-dd"
                                                minDate={startDate.split(' ')[0]}
                                                from={dateTime.date}
                                                time={dateTime.time}
                                                to={null}
                                                range={false}
                                                maxDate={dateTime.date}
                                                onDone={(e) => {
                                                    e.stopPropagation();
                                                    updateDateTime(e.detail);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        <div className="mt-5">
                            <p>Active plants<sup>*</sup>:</p>
                            <div className="d-flex flex-wrap">
                                {plantsList?.map((plant, index) => (
                                    (plant.IsActive && plant.IsPlant) &&
                                    <span key={`checkbox_${index}`} className="list-inline-item me-3">
                                        <input type="checkbox" id={plant.SubOrgName}
                                            onChange={(e) => updateSelectedPlant(e.target.checked, plant)}
                                            checked={selectedPlants.findIndex((p) => p.SubOrgName === plant.SubOrgName) > -1}
                                        />
                                        <label htmlFor={plant.SubOrgName}>{plant.SubOrgName}</label>
                                    </span>
                                ))}
                            </div>
                        </div>
                        <footer className="mt-5 d-flex">
                            <button className="btn btn-secondary me-2" onClick={() => resetData()}>Reset</button>
                            <button id="saveBtn" className="btn btn-primary" disabled={saveBtnState} onClick={() => showConfirmModal()}>Save</button>
                        </footer>
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0 border-start">
                        <h6>Deletion History</h6>
                        <IxDivider className="mt-0"></IxDivider>
                        {showDeletionHistory?.length > 0 ?
                            (<table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Deleted On</th>
                                        <th>User</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Plants</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showDeletionHistory.map((history: any, index) => (
                                        <tr key={index}>
                                            <td>{getFormattedDateTimeReadable(history.DeletedAt)}</td>
                                            <td>{history.DeletedBy}</td>
                                            <td>{getFormattedDateTimeReadable(history.From)}</td>
                                            <td>{getFormattedDateTimeReadable(history.To)}</td>
                                            <td>{history.PlantsName}</td>
                                            <td>{history.DeletionMode}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>) : (<h6 className="text-center mt-5">No deletion history available</h6>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default Settings;