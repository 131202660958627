import { useEffect, useState } from 'react';
import styles from "./Roles.module.scss";
import Roleslist from './RolesLists/Roleslist';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setRoles } from '../../redux/slices/roleSlice';
import { RoleInterface } from '../../Config/interfaces/RoleInterface';

const Roles = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const realmID = useAppSelector(state => state.keycloakSlice.realmId);
    let roles = useAppSelector(state => state.roleSlice.roles);
    const [showRolesTable, setRolesTable] = useState(false);

    useEffect(() => {
        roles?.length ? setRolesTable(true) : setRolesTable(false);
    }, [realmID, roles]);

    const updateRolesState = (roles: RoleInterface[]) => {
        dispatch(setRoles(roles));
    };

    /**
     *Return tag for DOM elements display
     *
    */
    return (
        <div id="roles-tab-content">
            {
                showRolesTable && (
                    <section className={`my-2 ${styles.roles_table_section}`}>
                        <Roleslist rolesArray={roles} updateRoles={updateRolesState}/>
                    </section>
                )
            }

        </div>
    );
}



export default Roles;