import {JSX, useState} from "react";
import "./Card.module.scss";
import Linechart from "./Linechart/Linechart";
import {Link} from "react-router-dom";
import {IxTooltip} from "@siemens/ix-react";
import {
    getKpiMeasurement,
    getKpiValue,
    getQualityAttributeResult,
} from "../../../utilities/converters";
import { useAppSelector } from "../../../redux/hooks";

const Card = ({kpi}: {kpi: any}): JSX.Element => {
    const [isFormulaBased] = useState(kpi.isFormulaBased);
    const [formulaBasedTooltipMessage] = useState("Formula: " + kpi.formula);
    const categoryId = useAppSelector(state => state.plantSlice.selectedCategoryId);

    function getLimit(): string {
        if (kpi) {
            switch (kpi.voilationCondition) {
                case "Greater":
                    return kpi.upperLimit ? kpi.upperLimit : "";
                case "Lesser":
                    return kpi.lowerLimit ? kpi.lowerLimit : "";
                case "Range":
                    return kpi.upperLimit
                        ? `${kpi.lowerLimit} - ${kpi.upperLimit}`
                        : "";
                default:
                    return "";
            }
        }
        return "";
    }

    function getConditionalOperator(): string {
        if (kpi) {
            switch (kpi.voilationCondition) {
                case "Greater":
                    return "<";
                case "Lesser":
                    return ">";
                default:
                    return "";
            }
        }
        return "";
    }

    return (
        <div className="mt-1 mb-4 mx-4">
            <Link
                to={"/dashboard/" + categoryId + "/" + kpi.id}
                className="no-underline"
            >
                <div
                    data-tooltip={isFormulaBased ? "cardTooltip" : ""}
                    className={`custom-card d-flex flex-column flex-wrap px-3 py-2 ${
                        isFormulaBased ? "card_formula_based" : ""
                    }`}
                >
                    <div className="card-header d-flex flex-row justify-content-between align-items-center">
                        <div className="text-xl">
                            {kpi.IotData.displayName}
                        </div>
                    </div>
                    <div className="card-body mt-4 d-flex flex-row justify-content-between align-items-center">
                        <div className="card-values">
                            <div className={`${getQualityAttributeResult(kpi, true)}` + " text-h2"}>
                                {getKpiValue(kpi) + " " + getKpiMeasurement(kpi)}
                            </div>
                            <div className="text-default">
                                Limit: {getConditionalOperator()} {getLimit()}{" "}
                                {kpi.measurement}
                            </div>
                        </div>
                        <div className={"line_chart_height m-1"}>
                            <Linechart
                                data={kpi.timeseries}
                                isVoilated={getQualityAttributeResult(
                                    kpi,
                                    false,
                                    true
                                )}
                            ></Linechart>
                        </div>
                    </div>
                </div>
            </Link>
            {
                isFormulaBased && (
                    <IxTooltip for="[data-tooltip='cardTooltip']">
                        {formulaBasedTooltipMessage}
                    </IxTooltip>
                )
            }
        </div>
    );
};

export default Card;
