import {
    IxButton,
    IxIcon,
    IxIconButton,
    IxSelect,
    IxSelectItem,
    IxSpinner, IxTooltip, showModal,
    showToast,
    ToastConfig
} from "@siemens/ix-react";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {FC, JSX, Key, useEffect, useState} from "react";
import {useInject} from "../../hooks/useInject";
import styles from "./Kpis.module.scss";
import {RolesEnum} from "../../Config/enums/RolesEnum";
import {Categories, KpiFormInterface, SelectOptions} from "../../Config/interfaces/KpiInterface";
import {KpiFormType} from "../../Config/customTypes/KpisType";
import {KpisConstant} from "../../Config/constants/KpisConstant";
import RolesService from "../../Services/RolesService";
import KpiService from "../../Services/KpiService";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {removeFromKpiForm, setCategories, setKpisForm, setSelectedPlantIdForKpiForm} from "../../redux/slices/kpiSlice";
import {KpiFields, KpiInputTypesEnum} from "../../Config/enums/KpisEnum";
import {useToastPosition} from "../../hooks/useToastPosition";
import {ToastMessage, ToastTitle, ToastType} from "../../Config/constants/ToastMessage";
import FormulaEditorModal from "./FormulaEditor/FormulaEditorModal";
import {useNavigate, useParams} from "react-router-dom";
import ConfirmModal from "../../Shared/ConfirmModal/ConfirmModal";
import {allowOnlyAlphabets, getRandomString} from "../../utilities/converters";
import BreadCrumb from "../../Shared/Breadcrumb/BreadCrumb";
import {setKPITags} from "../../redux/slices/timeSeriesSlice";
import {setPlantKpis} from "../../redux/slices/plantSlice";

const Kpis: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rolesService = useInject(RolesService);
    const plants = useAppSelector(state => state.plantSlice.plants);
    const [loggedInUserRole] = useState(rolesService.getLoggedInUserRole());
    const categories: Categories[] = useAppSelector(state => state.kpiSlice.categories);
    const kpis: KpiFormInterface[] = useAppSelector(state => state.kpiSlice.kpisForm);
    const [isLoading, setIsLoading] = useState(false);
    const kpiService = useInject(KpiService, setIsLoading);
    const plantId = params.plantId ? Number(params.plantId) : 0;
    const kpiTags = useAppSelector(state => state.timeseriesSlice.tagNames);

    useToastPosition('top-right');

    // ++ React hook form configuration
    const {control, handleSubmit, reset, setValue, getValues} = useForm<{ kpiForm: KpiFormType }>({
        mode: 'onBlur',
        defaultValues: {
            kpiForm: kpis
        },
        shouldUnregister: false,
        shouldUseNativeValidation: false
    });
    const {fields: kpiFormFields, append: appendKpi, remove: removeKpi} = useFieldArray({
        control,
        name: 'kpiForm',
        shouldUnregister: true
    });
    // -- React hook form configuration

    useEffect(() => {
        getKpiCategories().then(r => r);
    }, []);

    useEffect(() => {
        getKpiTags().then(r=> r);
    }, [plants, plantId]);

    useEffect(() => {
        if (!plantId || isNaN(plantId)) {
            return;
        }
        dispatch(setSelectedPlantIdForKpiForm(plantId));
        getKpis().then(r => r);
    }, [plantId]);

    useEffect(() => {
        refreshForm();
    }, [kpis]);

    useEffect(() => {
        prepareSelectOptions();
        refreshForm();
    }, [categories]);

    const plantSelectionChanged = (plantId: string) => {
        navigate('/kpis/' + plantId);
    }

    const getKpiCategories = async () => {
        const categories = await kpiService.getKpiCategories();
        categories?.length ? dispatch(setCategories(categories)) : dispatch(setCategories([]));
    }

    const getKpiTags = async () => {
        if (plants?.length){
            const plantObj = plants.find((plant) => 
                Number(plant.SuborgId) === plantId)
            const plantName = plantObj ? plantObj.SubOrgName : '';
            if(plantName.length){
                const kpiTags = await kpiService.getTagNamesForKPIs(plantName);
                kpiTags?.length ? dispatch(setKPITags(kpiTags)) : dispatch(setKPITags([]));
            }
        }
    }

    const getKpis = async () => {
        const kpisData = await kpiService.getKpis(plantId);
        storeKpis(kpisData);
    }

    const storeKpis = (kpisData: KpiFormInterface[]) => {
        dispatch(setPlantKpis([]));
        if (!kpisData?.length) {
            dispatch(setKpisForm([KpisConstant.initialValues]));
        } else {
            const kpis: KpiFormInterface[] = filterAndGetUsedFieldsInKpi(kpisData);
            dispatch(setKpisForm(kpis));
        }
    }

    const filterAndGetUsedFieldsInKpi = (kpis: KpiFormInterface[]) => {
        return kpis.map((kpi: KpiFormInterface) => {
            const usedFields: KpiFormInterface = structuredClone(KpisConstant.initialValues);

            Object.values(KpiFields).forEach(fieldName => {
                usedFields[fieldName] = kpi[fieldName];
            });

            return usedFields;
        });
    }

    const prepareSelectOptions = (): void => {
        // ++ prepare for KPI Categories
        if (categories?.length) {
            KpisConstant.fieldsConfig[KpiFields.categoryId].selectOptions = categories.map(option => {
                return {value: option?.id.toString(), label: option?.name.toString()};
            });
        }
        // -- prepare for KPI Categories
        // TODO: INFO -> Prepare/assign other (newly introduced) select options here
    }

    const refreshForm = (): void => {
        reset({kpiForm: kpis});
    }

    const matchInputType = (inputTypes: KpiInputTypesEnum[], fieldName: string): boolean => {
        return inputTypes.includes(KpisConstant.fieldsConfig?.[fieldName]?.type)
    }

    const pushNewSelectItem = async (newItem: string, selectFieldName: string): Promise<void> => {
        if (selectFieldName === KpiFields.categoryId) {
            const categoryDetails: Categories = {
                id: 0,
                name: newItem,
                code: allowOnlyAlphabets(newItem) + '_' + getRandomString()
            }
            const updatedCategories = await kpiService.addKpiCategory(categoryDetails);
            if (updatedCategories?.length) {
                dispatch(setCategories(updatedCategories));
                await showToast({
                    type: ToastType.success,
                    title: ToastTitle.success,
                    message: `Category "${newItem}" ${KpisConstant.kpiCategoryAdded}`
                });
            } else {
                await showToast({
                    type: ToastType.error,
                    title: ToastTitle.error,
                    message: ToastMessage.unknown
                });
            }
        }
    }

    const showToastMessage = async (ixToast: Omit<ToastConfig, "message"> & ToastConfig): Promise<void> => {
        await showToast(ixToast);
    }

    const formulaSaveHandler = (actionStatus: boolean, formula: string, formulaBased: boolean, kpiIndex: number) => {
        if (!actionStatus) {
            return;
        }

        const fieldsToUpdate = {[KpiFields.formula]: formula, [KpiFields.formulaBased]: formulaBased};
        setFormFieldValues(kpiIndex, fieldsToUpdate);
    }

    const setFormFieldValues = (kpiIndex: number, fieldsToUpdate: Record<string, string | boolean>) => {
        for (const [fieldName, value] of Object.entries(fieldsToUpdate)) {
            setValue(`kpiForm.${kpiIndex}.${fieldName}`, value);
        }
    }

    const getFormFieldValues = (kpiIndex: number) => {
        return getValues(`kpiForm.${kpiIndex}`);
    }

    const showFormulaEditor = async (index: number): Promise<void> => {
        const kpi = getFormFieldValues(index);
        await showModal({
            title: 'formulaEditorModal',
            content: <FormulaEditorModal onAction={formulaSaveHandler} kpi={kpi} kpiIndex={index} kpiTags={kpiTags}/>,
            size: 'lg'
        });
    }

    const removeOrDeleteKpi = (index: number, kpiId: string, kpiName: string) => {
        if (!kpiId) {
            removeKpi(index);
            return;
        }
        showConfirmBox(index, +kpiId, kpiName).then(r => r);
    }

    const confirmationHandler = async (actionType: string, index: number | undefined, kpiId: number | undefined) => {
        if (actionType !== "yes" || !kpiId) {
            return;
        }
        const response = await kpiService.deleteKpi(kpiId);
        if (response) {
            dispatch(removeFromKpiForm(kpiId));
            await showToast({
                type: ToastType.success,
                title: ToastTitle.success,
                message: KpisConstant.kpiDeleted
            });
        } else {
            await showToast({
                type: ToastType.error,
                title: ToastTitle.error,
                message: ToastMessage.unknown
            });
        }
    };

    async function showConfirmBox(index: number, kpiId: number | undefined, kpiName: string) {
        if (!kpiId) {
            return;
        }
        const confirmationText = `Are you sure?`;
        const confirmationSubText = `Do you really want to delete the KPI "${kpiName}" permanently?`;
        await showModal({
            title: 'deleteConfirmationModal',
            content: <ConfirmModal key={kpiId} id={kpiId} index={index} onAction={confirmationHandler} confirmationText={confirmationText}
                                   confirmationSubText={confirmationSubText}/>,
            icon: 'warning-filled',
            iconColor: 'color-warning',
            size: 'lg'
        });
    }

    const onSubmit: SubmitHandler<{ kpiForm: KpiFormInterface[] }> = async (formData: {
        kpiForm: KpiFormType
    }): Promise<void> => {
        if (!plantId || isNaN(+plantId)) {
            const ixToast = {
                type: ToastType.error,
                message: KpisConstant.incorrectPlantSelection
            }
            await showToastMessage(ixToast);
            return;
        }
        try {
            const plantKpis = {
                plantId: +plantId,
                kpiForm: formData?.kpiForm
            }
            const updatedKpis = await kpiService.saveKpis(plantKpis);
            storeKpis(updatedKpis); // save in redux store
            const ixToast = {
                type: ToastType.success,
                title: ToastTitle.success,
                message: KpisConstant.kpiSaved
            }
            await showToastMessage(ixToast);
        } catch (err: any) {
            const ixToast = {
                type: ToastType.error,
                message: KpisConstant.kpiFailedToSave
            }
            await showToastMessage(ixToast);
            if (err?.response?.status === 500) {
                // Handle 500 Internal Server Error here
                console.error('Internal Server Error:', err?.response?.data);
            } else {
                // Handle other errors
                console.error('An error occurred:', err);
            }
        }
    }

    const getTextInputTypesJSX = (fieldName: string, index: number): JSX.Element => {
        return (
            <Controller
                key={fieldName}
                control={control}
                name={`kpiForm.${index}.${fieldName}` as const}
                rules={KpisConstant.fieldsConfig?.[fieldName]?.rules}
                defaultValue={KpisConstant.fieldsConfig?.[fieldName]?.defaultValue}
                render={({
                             field: {
                                 onChange,
                                 onBlur,
                                 value
                             }
                         }) => (
                    <input
                        name={fieldName}
                        type={KpisConstant.fieldsConfig[fieldName].type}
                        className={`form-control`}
                        onChange={onChange}
                        value={value}
                        autoComplete="off"
                        spellCheck="false"
                        onBlur={() => {
                            onBlur();
                            setFormFieldValues(index, {[fieldName]: value});
                        }}
                        readOnly={KpisConstant.fieldsConfig[fieldName].readonly}
                        placeholder={KpisConstant.fieldsConfig[fieldName].placeholder}
                        minLength={KpisConstant.fieldsConfig[fieldName].minlength}
                        maxLength={KpisConstant.fieldsConfig[fieldName].maxlength}
                    />
                )}
            />
        );
    }

    const getCheckboxJSX = (fieldName: string, index: number): JSX.Element => {
        return (
            <Controller
                key={fieldName}
                control={control}
                name={`kpiForm.${index}.${fieldName}` as const}
                rules={KpisConstant.fieldsConfig?.[fieldName]?.rules}
                defaultValue={KpisConstant.fieldsConfig?.[fieldName]?.defaultValue}
                render={({
                             field: {
                                 onChange,
                                 onBlur,
                                 value
                             }
                         }) => (
                    <input
                        name={fieldName}
                        type={KpisConstant.fieldsConfig[fieldName].type}
                        className={`form-control`}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        autoComplete="off"
                        checked={typeof value === 'string' ? value === 'true' : value}
                        readOnly={KpisConstant.fieldsConfig[fieldName].readonly}
                    />
                )}
            />
        );
    }

    // TODO: Only handled for single selection mode.
    //  Value part is not handled for multiple selection (Will have to use string column in DB)
    const getSelectBoxJSX = (fieldName: string, index: number): JSX.Element => {
        return (
            <Controller
                key={fieldName}
                control={control}
                name={`kpiForm.${index}.${fieldName}` as const}
                rules={KpisConstant.fieldsConfig?.[fieldName]?.rules}
                defaultValue={KpisConstant.fieldsConfig?.[fieldName]?.defaultValue}
                render={({field}) => (
                    <>
                        {
                            !!KpisConstant.fieldsConfig[fieldName]?.selectOptions?.length
                            && <IxSelect
                                mode={KpisConstant.fieldsConfig[fieldName]?.selectMode}
                                editable={KpisConstant.fieldsConfig[fieldName]?.editable || false}
                                i18nPlaceholderEditable={KpisConstant.fieldsConfig[fieldName].placeholderEditable}
                                i18nSelectListHeader={KpisConstant.fieldsConfig[fieldName].placeholder}
                                onItemSelectionChange={(newValue) => field.onChange(Number(newValue.detail))}
                                onAddItem={(addedItem => pushNewSelectItem(addedItem.detail, fieldName))}
                                onBlur={field.onBlur}
                                selectedIndices={[field.value.toString()]}
                                readonly={KpisConstant.fieldsConfig[fieldName].readonly}>
                                    {
                                        KpisConstant.fieldsConfig[fieldName]?.selectOptions?.map((option: SelectOptions, i) => (
                                            <IxSelectItem
                                                key={i}
                                                label={option.label}
                                                value={option.value}></IxSelectItem>
                                        ))
                                    }
                            </IxSelect>
                        }
                    </>
                )}
            />
        )
    }

    const getTextareaJSX = (fieldName: string, index: number): JSX.Element => {
        return (
            <Controller
                key={fieldName}
                control={control}
                name={`kpiForm.${index}.${fieldName}` as const}
                rules={KpisConstant.fieldsConfig?.[fieldName]?.rules}
                defaultValue={KpisConstant.fieldsConfig?.[fieldName]?.defaultValue}
                render={({
                             field: {
                                 onChange,
                                 onBlur,
                                 value
                             }
                         }) => (
                    <>
                        <textarea
                            name={fieldName}
                            className={`form-control`}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            autoComplete="off"
                            readOnly={KpisConstant.fieldsConfig[fieldName].readonly}
                            placeholder={KpisConstant.fieldsConfig[fieldName].placeholder}
                            tooltip-selector={`${fieldName}-textarea-${index}`}
                        ></textarea>
                        {
                            value && <IxTooltip for={`[tooltip-selector='${fieldName}-textarea-${index}']`} placement="bottom">{value}</IxTooltip>
                        }
                    </>
                )}
            />
        );
    }

    const getFormulaEditorButton = (index: number): JSX.Element => {
        return (
            <IxIconButton className="mx-1"
                          size={`16`}
                          disabled={!loggedInUserRole.includes(RolesEnum.admin)}
                          onClick={() => showFormulaEditor(index)}
                          icon="pen"></IxIconButton>
        );
    }

    const getTableHeadJSX = (): JSX.Element => {
        return (
            <thead>
            <tr>
                {
                    Object.keys(KpisConstant.fieldsConfig).map((fieldName: string, i: Key) => (
                        <th scope="col" key={i}
                            className={
                                ((KpisConstant.fieldsConfig[fieldName].type === KpiInputTypesEnum.number) ? `${styles.custom_width_number} ` : ``) +
                                ((KpisConstant.fieldsConfig[fieldName].type === KpiInputTypesEnum.select) ? `${styles.custom_width_select} ` : ``) +
                                (KpisConstant.fieldsConfig?.[fieldName]?.isHidden ? `d-none` : ``)
                            }>
                            {KpisConstant.fieldsTitle[fieldName]}
                            {
                                KpisConstant.fieldsConfig[fieldName].rules.required &&
                                <span className={`color-alarm`}>*</span>
                            }
                        </th>
                    ))
                }
                <th scope="col">Action</th>
            </tr>
            </thead>
        );
    }

    const getTableBodyJSX = (): JSX.Element => {
        return (
            <tbody>
            {kpiFormFields.map((kpi: Record<string, string>, index: number) => (
                <tr key={kpi.id}>
                    {
                        Object.keys(kpi).map((fieldName: string) => (
                            <td key={kpi.id + fieldName}
                                className={
                                    (fieldName === KpiFields.formula ? `d-flex flex-row justify-content-between align-items-end ` : ``) +
                                    (KpisConstant.fieldsConfig?.[fieldName]?.isHidden ? `d-none ` : ``) +
                                    (fieldName === 'id' ? 'd-none' : '')
                                }>
                                {
                                    matchInputType(KpisConstant.textInputTypes, fieldName) &&
                                    getTextInputTypesJSX(fieldName, index)
                                }
                                {
                                    matchInputType(KpisConstant.checkboxType, fieldName) &&
                                    getCheckboxJSX(fieldName, index)
                                }
                                {
                                    matchInputType(KpisConstant.selectType, fieldName) &&
                                    getSelectBoxJSX(fieldName, index)
                                }
                                {
                                    matchInputType(KpisConstant.textareaType, fieldName) &&
                                    getTextareaJSX(fieldName, index)
                                }
                                {
                                    fieldName === KpiFields.formula &&
                                    getFormulaEditorButton(index)
                                }
                            </td>
                        ))
                    }
                    <td>
                        <IxIconButton className="mx-1"
                                      disabled={kpiFormFields.length === 1 || !loggedInUserRole.includes(RolesEnum.admin)}
                                      onClick={() => removeOrDeleteKpi(index, kpi.kpiId, kpi.name)}
                                      icon="trashcan"></IxIconButton>
                    </td>
                </tr>
            ))}
            </tbody>
        );
    }

    const getTableAndFormJSX = (): JSX.Element => {
        return (
            <section className={`mt-2`}>
                <form onSubmit={handleSubmit(onSubmit)} className={`needs-validation m-2`}>
                    <div className={`${styles.kpi_table}`}>
                        <table className={`table`}>
                            {
                                getTableHeadJSX()
                            }
                            {
                                getTableBodyJSX()
                            }
                        </table>
                    </div>
                    <div className={`float-end`}>
                        <IxButton className={`mx-2`} type="submit" disabled={!kpiFormFields.length || isLoading}>Save</IxButton>
                    </div>
                </form>
            </section>
        );
    }

    return (
        <div className={`mx-3`}>
            <section>
                {
                    isLoading && <IxSpinner className={`vertical-center`} size={'large'}></IxSpinner>
                }
            </section>
            <section className={`d-flex flex-row justify-content-between align-items-center`}>
                <div className="d-flex align-items-center">
                    <BreadCrumb itemString={`Organization, ${KpisConstant.kpiHeading}`}></BreadCrumb>
                                <span className={`ms-2 mt-1`}>
                                    {
                                        !!plants?.length && <IxSelect selectedIndices={plantId.toString()}>
                                            {
                                                plants.map((plant, i) => (
                                                    <IxSelectItem
                                                        key={i}
                                                        label={plant.SubOrgName}
                                                        value={plant.SuborgId.toString()}
                                                        onItemClick={() => plantSelectionChanged(plant.SuborgId)}></IxSelectItem>
                                                ))
                                            }
                                        </IxSelect>
                                    }
                                </span>
                </div>
                <IxButton onClick={() => appendKpi(KpisConstant.initialValues)} className={`mx-2 mt-1`}
                          disabled={isLoading || !categories.length}
                          variant="Primary">
                    <IxIcon name="plus"></IxIcon> Add
                </IxButton>
            </section>
            {
                !isLoading && !categories.length && (
                    <section>
                        <div className={`text-default text-gray py-2`}>KPI categories not found!</div>
                    </section>
                )
            }
            {
                !!categories.length && getTableAndFormJSX()
            }
        </div>
    )
}

export default Kpis;