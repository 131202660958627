// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_kpi_card__WJjL1 {
  min-width: 10.937rem !important;
  max-width: 10.937rem !important;
  width: 10.937rem !important; }
`, "",{"version":3,"sources":["webpack://./src/Detailedview/Card/Card.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,+BAA+B;EAC/B,2BAA2B,EAAA","sourcesContent":[".kpi_card {\n  min-width: 10.937rem !important;\n  max-width: 10.937rem !important;\n  width: 10.937rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kpi_card": `Card_kpi_card__WJjL1`
};
export default ___CSS_LOADER_EXPORT___;
