import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInterface, UserProfileInterface } from "../../Config/interfaces/UserInterface";

interface UserState {
    users: UserInterface[];
    usersProfile: UserProfileInterface[];
}

const initialState: UserState = {
    users: [],
    usersProfile: []
}

const UserSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers(state, action: PayloadAction<UserInterface[]>): void {
            state.users = action.payload;
        },
        setUsersProfile(state, action: PayloadAction<UserProfileInterface[]>): void {
            state.usersProfile = action.payload;
        }
    }
});

export const { setUsers, setUsersProfile } = UserSlice.actions;
export default UserSlice.reducer;