import { PlantConstant } from "../Config/constants/plantConstant";
import { OrganizationInfoInterface } from "../Config/interfaces/PlantInterface";
import ConfigService from "./ConfigService";

class PlantService extends ConfigService {
    async getOrgHierarchy() {
        try {
            const response = await fetch(this.serverBaseUrl + PlantConstant.organizationHierarchyApi, {
                headers: this.headersList
            });
            return await response?.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getPlants() {
        try {
            const response = await fetch(this.serverBaseUrl + PlantConstant.plantApi, {
                headers: this.headersList
            });
            return await response?.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async addPlants(plantValues: any): Promise<boolean> {
        try {
            const url = this.serverBaseUrl + PlantConstant.plantApi
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(plantValues),
                headers: this.headersList
            });

            const status = await response.text();

            return 'success' === status.toLowerCase();
        } catch (err) {
            console.error('API call failed: ', err);
            return false;
        }
    }

    async editPlant(plantValues: any): Promise<boolean> {
        try {
            const url = this.serverBaseUrl + PlantConstant.plantApi;
            const response = await fetch(url, {
                method: 'PUT',
                body: JSON.stringify(plantValues),
                headers: this.headersList
            });

            const status = await response.text();

            return 'success' === status.toLowerCase();
        } catch (err) {
            console.error('API call failed: ', err);
            return false;
        }
    }

    async getOrganization() {
        try {
            return await fetch(this.serverBaseUrl + PlantConstant.organizationApi, {
                method: 'GET',
                headers: this.headersList
            }).then(response => response.json());
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async addOrganization(reqOptions: any) {
        try {
            return await fetch(this.serverBaseUrl + PlantConstant.organizationApi, {
                method: 'POST',
                body: JSON.stringify(reqOptions),
                headers: this.headersList
            }).then(response => response.json());
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async addSubOrganization(subOrgValues: any) {
        try {
            const response = await fetch(this.serverBaseUrl + PlantConstant.subOrganizationApi, {
                method: 'POST',
                body: JSON.stringify(subOrgValues),
                headers: this.headersList
            });
            const status = await response.text();

            return 'success' === status.toLowerCase();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async editSubOrganization(subOrgValues: any): Promise<boolean> {
        try {
            const url = this.serverBaseUrl + PlantConstant.subOrganizationApi;
            const response = await fetch(url, {
                method: 'PUT',
                body: JSON.stringify(subOrgValues),
                headers: this.headersList
            });

            const status = await response.text();

            return 'success' === status.toLowerCase();
        } catch (err) {
            console.error('API call failed: ', err);
            return false;
        }
    }

    async deleteSubOrganization(subOrgId: any) {
        if (!subOrgId) {
            return false;
        }

        const url: string = this.serverBaseUrl + PlantConstant.subOrganizationApi + "/" + subOrgId;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async deletePlant(plantId: any) {
        if (!plantId) {
            return false;
        }

        const url: string = this.serverBaseUrl + PlantConstant.plantApi + "/" + plantId;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: this.headersList
            });

            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    setPlantDetails(organizationInfo: OrganizationInfoInterface) {
        const plantStatus = organizationInfo?.isPlant && organizationInfo?.isActive && !organizationInfo?.isDisabled;
        localStorage.setItem("plantName", organizationInfo?.subOrgName);
        localStorage.setItem("plantId", organizationInfo?.id?.toString());
        localStorage.setItem("plantStatus", plantStatus?.toString());
    }
}

export default PlantService;