// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users_users_table_section__XMHLg {
  height: 75vh;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/UserManagement/Users/Users.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc,EAAA","sourcesContent":[".users_table_section {\n  height: 75vh;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"users_table_section": `Users_users_table_section__XMHLg`
};
export default ___CSS_LOADER_EXPORT___;
