import {FC, useEffect, useState} from "react";
import ReactEcharts from "echarts-for-react";
import { IxBlind, IxDropdown, IxDropdownItem, IxIconButton, IxSpinner } from "@siemens/ix-react";
import uniqueId from "../../utilities/uniqueId";
import {useInject} from "../../hooks/useInject";
import InsightsService from "../../Services/InsightsService";
import {scatterPlotGraphConfig} from "../constant/scatterPlotFormConfig";
import {getFormattedDateTimeReadable} from "../../utilities/converters";
import { useAppSelector } from "../../redux/hooks";

const ScatterPlot: FC<{
    collapsed: boolean
    graphDetails: any;
    deleteGraph: (id: number, graphName: string) => void;
    editGraph: (id: number) => void;
}> = (props) => {
    const insightsService = useInject(InsightsService);
    const parametersList = useAppSelector(state => state.insightsSlice.parametersList);
    const xAxisParam = props.graphDetails.xParameter;
    const yAxisParam = props.graphDetails.yParameter;
    const startTime = props.graphDetails.timeRange.startDateTime;
    const endTime = props.graphDetails.timeRange.endDateTime;
    const chartTitle = props.graphDetails.graphName;
    const xAxisName = parametersList?.[props.graphDetails.xParameter[0]] ?? '';
    const yAxisName = parametersList?.[props.graphDetails.yParameter[0]] ?? '';
    const [blindCollapsed, setBlindCollapsed] = useState(props.collapsed);
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uniqueChartId] = useState(uniqueId());
    const [options, setOptions] = useState(scatterPlotGraphConfig);
    const [blindLabel, setBlindLabel] = useState<string>('');
    const themeMode = useAppSelector(state => state.settingsSlice.theme);

    useEffect(() => {
        onBlindExpand(blindCollapsed).then(r => r);
    }, [startTime, endTime, xAxisName, yAxisName]);

    useEffect(() => {
        setBlindLabel(`${chartTitle} (${getFormattedDateTimeReadable(startTime)} - ${getFormattedDateTimeReadable(endTime)})`);
    }, [chartTitle, startTime, endTime]);

    useEffect(() => {
        refreshGraph();
    }, [graphData]);

    const onBlindExpand = async (blindCollapsed: boolean): Promise<void> => {
        setBlindCollapsed(blindCollapsed);
        if (!blindCollapsed) {
            try {
                const parameters = [...xAxisParam, ...yAxisParam];
                setIsLoading(true);
                const graphDetails = await insightsService.getScatterPlotData(parameters, startTime, endTime);
                if (!graphDetails) {
                    return;
                }

                if (graphDetails?.data[xAxisParam] && Array.isArray(graphDetails.data[xAxisParam]) &&
                    graphDetails?.data[yAxisParam] && Array.isArray(graphDetails.data[yAxisParam])) {
                    const xAxisData = graphDetails.data[xAxisParam];
                    const yAxisData = graphDetails.data[yAxisParam];
                    const graphData = xAxisData.map((xAxis: any[], index: number) => [xAxis[1], yAxisData?.[index]?.[1]]);
                    setGraphData(graphData);
                }
            } catch (error) {
                console.error('Error fetching graph data', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const refreshGraph = (): void => {
        const optionsCloned = structuredClone(options);
        optionsCloned.xAxis.name = xAxisName;
        optionsCloned.yAxis.name = yAxisName;
        optionsCloned.series[0].name = yAxisName;
        optionsCloned.series[0].data = graphData?.length ? graphData : [];
        setOptions(optionsCloned);
    }

    return (
        <div className={`mt-2`}>
            <IxBlind label={blindLabel} collapsed={blindCollapsed} onCollapsedChange={(state) => onBlindExpand(state.detail)}>
                <IxIconButton
                    id={uniqueChartId}
                    slot="header-actions"
                    ghost
                    icon="context-menu"
                ></IxIconButton>
                <span className={isLoading ? "center-align" : 'normal'}
                      style={{width: '100%', height: '60vh', display: 'grid', alignItems: 'center'}}>
                    {
                        isLoading ? (
                            <IxSpinner size="large"></IxSpinner>
                        ) : (
                            <ReactEcharts option={options} style={{height: '60vh'}} theme={themeMode}/>
                        )
                    }
                </span>
            </IxBlind>
            <IxDropdown className={`me-4`} trigger={uniqueChartId}>
                <IxDropdownItem icon="pen" onClick={() => props.editGraph(props?.graphDetails?.id)}>Edit
                    Graph</IxDropdownItem>
                <IxDropdownItem icon="trashcan"
                                onClick={() => props.deleteGraph(props?.graphDetails?.id, props?.graphDetails?.graphName)}>Delete</IxDropdownItem>
            </IxDropdown>
        </div>
    )
}

export default ScatterPlot;
