import { createSlice } from "@reduxjs/toolkit";
import { PlantInterface } from "../../Config/interfaces/PlantInterface";

interface SettingsState {
    oldestTimestamp: string;
    theme: string;
    autoDeletionEnabled: boolean;
    autoDeletionInterval: number;
    selectedPlants: PlantInterface[];
}

const initialState: SettingsState = {
    oldestTimestamp: '',
    theme: 'dark',
    autoDeletionEnabled: false,
    autoDeletionInterval: 6,
    selectedPlants: []
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setOldestTimestamp(state, action) {
            state.oldestTimestamp = action.payload;
        },
        setTheme(state, action) {
            state.theme = action.payload;
            localStorage.setItem('theme', action.payload);
        },
        setAutoDeletionEnabled(state, action) {
            state.autoDeletionEnabled = action.payload;
        },
        setAutoDeletionInterval(state, action) {
            state.autoDeletionInterval = action.payload;
        },
        setSelectedPlants(state, action) {
            state.selectedPlants = action.payload;
        }
    }
});

export const { setOldestTimestamp, setTheme, setAutoDeletionEnabled, setAutoDeletionInterval, setSelectedPlants } = settingsSlice.actions;
export default settingsSlice.reducer;