import { ManagementType, OrgManagementActions, PlantManagementActions, RoleManagementActions, UserManagementActions } from "../enums/RolesEnum";

export interface RoleInterface {
    id: string;
    name: string;
    description: string;
    composite: boolean;
    clientRole: boolean;
    containerId: string;
    permissions: RolesActionInterface;
}

export interface RolePermissionInterface {
    [OrgManagementActions.View]: boolean;
    [OrgManagementActions.Add]: boolean;
    [OrgManagementActions.Del]: boolean;
    [OrgManagementActions.Full]: boolean;

    [UserManagementActions.View]: boolean;
    [UserManagementActions.Add]: boolean;
    [UserManagementActions.Del]: boolean;
    [UserManagementActions.Full]: boolean;

    [RoleManagementActions.View]: boolean;
    [RoleManagementActions.Add]: boolean;
    [RoleManagementActions.Del]: boolean;
    [RoleManagementActions.Full]: boolean;

    [PlantManagementActions.View]: boolean;
    [PlantManagementActions.Add]: boolean;
    [PlantManagementActions.Del]: boolean;
    [PlantManagementActions.Full]: boolean;
}

export interface RolesActionInterface {
    [ManagementType.Org]: {
        [OrgManagementActions.View]: boolean;
        [OrgManagementActions.Add]: boolean;
        [OrgManagementActions.Del]: boolean;
        [OrgManagementActions.Full]: boolean;
    };
    [ManagementType.User]: {
        [UserManagementActions.View]: boolean;
        [UserManagementActions.Add]: boolean;
        [UserManagementActions.Del]: boolean;
        [UserManagementActions.Full]: boolean;
    };
    [ManagementType.Roles]: {
        [RoleManagementActions.View]: boolean;
        [RoleManagementActions.Add]: boolean;
        [RoleManagementActions.Del]: boolean;
        [RoleManagementActions.Full]: boolean;
    };
    [ManagementType.Plant]: {
        [PlantManagementActions.View]: boolean;
        [PlantManagementActions.Add]: boolean;
        [PlantManagementActions.Del]: boolean;
        [PlantManagementActions.Full]: boolean;
    }
}

export const defaultPermissions: RolesActionInterface = {
    [ManagementType.User]: {
        [UserManagementActions.Add]: false,
        [UserManagementActions.Del]: false,
        [UserManagementActions.View]: false,
        [UserManagementActions.Full]: false
    },
    [ManagementType.Roles]: {
        [RoleManagementActions.Add]: false,
        [RoleManagementActions.Del]: false,
        [RoleManagementActions.View]: false,
        [RoleManagementActions.Full]: false
    },
    [ManagementType.Org]: {
        [OrgManagementActions.Add]: false,
        [OrgManagementActions.Del]: false,
        [OrgManagementActions.View]: false,
        [OrgManagementActions.Full]: false
    },
    [ManagementType.Plant]: {
        [PlantManagementActions.Add]: false,
        [PlantManagementActions.Del]: false,
        [PlantManagementActions.View]: false,
        [PlantManagementActions.Full]: false
    }
};