import KeyCloakService from "../../App/KeycloakService";
import RolesService from "../../Services/RolesService";
import { Link, useLocation } from "react-router-dom";
import { IxMenu, IxMenuAvatar, IxMenuItem } from "@siemens/ix-react";
import { MenuConstant } from "../../Config/constants/MenuConstant";
import { Key, useEffect, useState } from "react";
import { MenuInterface } from "../../Config/interfaces/MenuInterface";
import { useDetectTheme } from "../../hooks/useDetectTheme";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useInject } from "../../hooks/useInject";
import { setTheme } from "../../redux/slices/settingsSlice";
import { RolesEnum } from "../../Config/enums/RolesEnum";

const SideBar = (): JSX.Element => {
    const rolesService = useInject(RolesService);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const loggedInUserRoles = rolesService.getLoggedInUserRole();
    const loggedInUserRolePermissions: any = useAppSelector(state => state.roleSlice.loggedInUserPermissions);
    const [menuItems, setMenuItems] = useState<MenuInterface[]>([]);

    useDetectTheme((themeClassName: string) => {
        if (themeClassName === "theme-classic-dark") {
            dispatch(setTheme('dark'));
        } else {
            dispatch(setTheme('light'));
        }
    });

    useEffect(() => {
        updatePermissions(MenuConstant.menuItems)
    }, [loggedInUserRolePermissions]);

    const updatePermissions = (items: MenuInterface[]) => {
        const clonedItems = structuredClone(items);
        clonedItems.forEach((menuItem: MenuInterface) => {
            if (menuItem.title === 'Organization' || menuItem.title === 'User management') {
                Object.keys(loggedInUserRolePermissions).some((key) => {
                    if (menuItem.title.toLowerCase().includes(key) && loggedInUserRolePermissions[key][key + '_view']) {
                        menuItem.isPermitted = true;
                        return true;
                    }
                });
            } else if (menuItem.title === 'Settings' && loggedInUserRoles.includes(RolesEnum.admin)) {
                menuItem.isPermitted = true;
            }
        });
        setMenuItems(clonedItems);
    };

    return (
        <IxMenu enableToggleTheme maxVisibleMenuItems={10}>
            <IxMenuAvatar
                top={KeyCloakService.getUserName()}
                onLogoutClick={() => {
                    localStorage.removeItem('plantName');
                    localStorage.removeItem('plantId');
                    KeyCloakService.doLogout();
                }}
                image={
                    "https://ui-avatars.com/api/?name=" +
                    KeyCloakService.getUserName().replace(" ", "+")
                }
            ></IxMenuAvatar>
            {menuItems.map((menuItem: MenuInterface, index: Key) => {
                return (
                    <Link style={{ display: menuItem.isPermitted ? 'block' : 'none' }} key={index} to={menuItem.route}>
                        <IxMenuItem
                            active={menuItem.route === location?.pathname}
                            tab-icon={menuItem.icon}
                        >
                            {menuItem.title}
                        </IxMenuItem>
                    </Link>
                );
            })}
        </IxMenu>
    );
};

export default SideBar;