import { useEffect } from "react";
import { useInject } from "./useInject";
import TimeseriesService from '../Services/TimeSeriesService';
import { PlantKpisDataInterface } from '../Config/interfaces/TimeseriesInterface';
import { useAppSelector } from "../redux/hooks";

const usePollKpisLatestDataOnInterval = (setKpisData: (kpisData: Record<number, PlantKpisDataInterface[]>) => void) => {
    const timeseriesService = useInject(TimeseriesService);
    const interval = +(process.env.REACT_APP_INTERVAL_IN_MICROSECONDS ?? 10000);
    const selectedPlantId = useAppSelector((state) => state.plantSlice.selectedPlantId);

    useEffect(() => {
        const intervalId = pollKpisLatestDataOnInterval();

        return () => clearInterval(intervalId);
    }, [selectedPlantId]);

    const pollKpisLatestDataOnInterval = () => {
        return setInterval(async () => {      
          await getKpisInitialData(1);
        }, interval);
    }   

    const getKpisInitialData = async (rowNum: number = 1) => {    
        if (!selectedPlantId) {
            return;
        }
        const kpiResponse = await timeseriesService.getKpisData(selectedPlantId, rowNum);            
        setKpisData(kpiResponse?.kpisData);
    };
}

export default usePollKpisLatestDataOnInterval;