import { useEffect } from "react";
import { setCategories, setPlantKpis } from "../redux/slices/plantSlice";
import { useAppDispatch } from "../redux/hooks";

const useResetPlantKpisData = () => {
    const dispatch = useAppDispatch();

	useEffect(() => {
		// data reset to default for dashboard
		dispatch(setCategories([]));
		dispatch(setPlantKpis([]));
	}, []);
};

export default useResetPlantKpisData;