import {JSX} from "react";
import Infobar from "./Infobar/Infobar";
import Cards from "./Cards/Cards";
import BreadCrumb from "../Shared/Breadcrumb/BreadCrumb";
import Tabs from "./Tabs/Tabs";
import useSetPlant from "../hooks/useSetPlant";
import { useAppSelector } from "../redux/hooks";
import { NavigationEnum } from "../Config/enums/NavigationEnum";
import useResetPlantKpisData from "../hooks/useResetKpiData";

const DashBoard = (): JSX.Element => {
    const selectedPlant = useAppSelector((state) => state.plantSlice.selectedPlant);
    const navigatedThrough = useAppSelector(state => state.navigationSlice.dasboardNavigatedThrough);

    useSetPlant();

    return (
        <div className={"mx-3"}>
            <BreadCrumb itemString={`${navigatedThrough},${selectedPlant} - ${NavigationEnum.dashboard}`}/>
            <Infobar/>
            <Tabs/>
            <Cards/>
        </div>
    );
};

export default DashBoard;
