import { FC } from "react";
import { IxSelect, IxSelectItem } from "@siemens/ix-react";

const Select: FC<{
    mode: 'single' | 'multiple' | undefined;
    field: any;
    placeholder: string;
    options: { name: string; value: any }[];
    readonly: boolean;
}> = ((props) => {

    return (
        <>
            {
                !!props.options.length &&
                <IxSelect
                    mode={props.mode}
                    hideListHeader={true}
                    onItemSelectionChange={(newValue) => props.field.onChange(newValue.detail)}
                    selectedIndices={props.field.value}
                    editable={false}
                    i18nPlaceholderEditable={''}
                    i18nSelectListHeader={''}
                    onBlur={props.field.onBlur}
                    readonly={props.readonly}
                >
                    {
                        props.options.map((option: any, key: number) => {
                            return (
                                <IxSelectItem key={key} label={option.name} value={option.value}></IxSelectItem>
                            );
                        })
                    }
                </IxSelect>
            }
        </>
    );

});

export default Select;
