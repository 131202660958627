// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Roles_roles_table_section__3n1cb {
  height: 75vh;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/UserManagement/Roles/Roles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc,EAAA","sourcesContent":[".roles_table_section {\n  height: 75vh;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roles_table_section": `Roles_roles_table_section__3n1cb`
};
export default ___CSS_LOADER_EXPORT___;
