// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Detailedview_kpi_list_section__CRflS {
  min-width: 20%;
  border-right: var(--theme-soft-bdr-2); }

.Detailedview_dashboard_details__nfZOM {
  height: 6vh; }
`, "",{"version":3,"sources":["webpack://./src/Detailedview/Detailedview.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qCAAqC,EAAA;;AAGvC;EACE,WAAW,EAAA","sourcesContent":[".kpi_list_section {\n  min-width: 20%;\n  border-right: var(--theme-soft-bdr-2);\n}\n\n.dashboard_details {\n  height: 6vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kpi_list_section": `Detailedview_kpi_list_section__CRflS`,
	"dashboard_details": `Detailedview_dashboard_details__nfZOM`
};
export default ___CSS_LOADER_EXPORT___;
