export class Converters {

    public static isotoLocalTime = (iso: any) => new Date(iso).toLocaleTimeString();
}

export function getKpiValue(qualityAttribute: any): string {
    if (!qualityAttribute?.IotData?.value) {
        return 'NA';
    }
    let value = isNaN(qualityAttribute.IotData.value) ? 0 : Number(qualityAttribute.IotData.value);
    return (value % 1 !== 0) ? value.toFixed(2) : value.toString();
}

export function getKpiMeasurement(qualityAttribute: any): string {
    return qualityAttribute?.measurement ?? '';
}

export function getQualityAttributeResult(qualityAttribute: any, color: boolean = false, returnIsViolated: boolean = false): any {
    const { voilationCondition, IotData, upperLimit, lowerLimit } = qualityAttribute;
    const value = isNaN(IotData?.value) ? 0 : Number(IotData?.value);
    let isViolated = false;

    if (!value) {
        return returnIsViolated ? false : color ? 'default_color' : 'neutral';
    }

    switch (voilationCondition) {
        case "Greater":
            isViolated = value > upperLimit;
            break;
        case "Lesser":
            isViolated = value < lowerLimit;
            break;
        case "Range":
            isViolated = !(value >= lowerLimit && value <= upperLimit);
            break;
    }

    if (returnIsViolated) {
        return isViolated;
    }

    if (color) {
        return isViolated ? 'valuerangered_custom' : 'valuerangegreen_custom';
    }

    return isViolated ? 'alarm' : 'neutral';
}

export function getCurrentDate(): string {
    const currDate = new Date();
    return currDate.toISOString().slice(0, 10).replaceAll('-', '/');
}

export function getCurrentTime(): string {
    const today = new Date();
    return today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
}

export function getFormattedDate(date: string): string {
    let tempDate = new Date(date);
    const year = tempDate.getFullYear();
    const month = String(tempDate.getMonth() + 1).padStart(2, '0');
    const day = String(tempDate.getDate()).padStart(2, '0');

    return [day, month, year].join('-');
}

export function getFormattedTime(timestamp: string): string {
    let tempTime = new Date(timestamp);
    return tempTime.getHours() + ':' + tempTime.getMinutes() + ':' + tempTime.getSeconds();
}

export function getFormattedDateTime(date: string): string {
    let timestamp = new Date(date);
    const selectedDate = String(timestamp.getDate()).padStart(2, '0') + '-' + String((timestamp.getMonth() + 1)).padStart(2, '0') + '-' + timestamp.getFullYear();
    const chosenTime = timestamp.getHours() + ':' + timestamp.getMinutes() + ':' + timestamp.getSeconds();
    return selectedDate + ' ' + chosenTime;
}

export function getFormattedDateTimeReadable(date: string): string {
    const timestamp = new Date(date);

    if (isNaN(timestamp.getTime())) {
        throw new Error('Invalid date');
    }

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: undefined,
        hour12: true,
    };

    const formattedDateTime = timestamp.toLocaleDateString(undefined, options);

    return `${formattedDateTime}`;
}


export function getFormattedStartEndDatesTimestamp(startDateTime: { date: string; time: string; }, endDateTime: {
    date: string;
    time: string;
}): Date[] | undefined {
    if (!startDateTime?.date || !endDateTime?.date) {
        return;
    }

    const formattedStartDate = startDateTime?.date.split('-');
    [formattedStartDate[0], formattedStartDate[1]] = [formattedStartDate[1], formattedStartDate[0]];
    const formattedEndDate = endDateTime?.date.split('-');
    [formattedEndDate[0], formattedEndDate[1]] = [formattedEndDate[1], formattedEndDate[0]];

    const startTimestamp = new Date(formattedStartDate + ' ' + startDateTime?.time);
    const endTimestamp = new Date(formattedEndDate + ' ' + endDateTime?.time);

    return [startTimestamp, endTimestamp];
}

export function getIxDateFormat(date: string): string {
    let tempDate = new Date(date);
    const year = tempDate.getFullYear();
    const month = String(tempDate.getMonth() + 1).padStart(2, '0');
    const day = String(tempDate.getDate()).padStart(2, '0');

    return [year, month, day].join('/');
}

export function getRandomString(length = 5) {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
    }

    return randomString;
}

export function allowOnlyAlphabets(inputString: string) {
    // Use a regular expression to match only alphabets (uppercase and lowercase)
    const regex = /[a-zA-Z]/g;

    // Use the match method to find all matches in the input string
    const matches = inputString.match(regex);

    // Join the matches to form the resulting string
    return matches ? matches.join('') : '';
}

export function getCurrentTimestamp() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

export function isAfter(date1: Date, date2: Date): boolean {
    return date1.getTime() > date2.getTime();
}

export function subtractFromDate(date: Date, period: number, type: string): Date {
    let newDate = new Date(date);
    switch (type) {
        case 'days':
            newDate.setDate(date.getDate() - period);
            break;
        case 'months':
            newDate.setMonth(date.getMonth() - period);
            break;
        case 'years':
            newDate.setFullYear(date.getFullYear() - period);
            break;
        default:
            break;
    }
    return newDate;
    
}