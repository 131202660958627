import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import KeyCloakService from "../App/KeycloakService";
import jwt from "jwt-decode";
import { setUserAssignedPlants } from "../redux/slices/keycloakSlice";

const useSetUserAssignedPlants = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(state => state.userSlice.users);
  const orgTree = useAppSelector((state) => state.plantSlice.orgTree);

  let access_token = KeyCloakService.getToken();
  let decodedVal: string = jwt(access_token);
  const userId = decodedVal.sub;

  useEffect(() => {
    let user;
    if (users) {
      user = users.filter((dat: any) => {
        return dat.id === userId;
      });
      if (user[0]) {
       getUserAssignedPlants(user);
      }
    }
  }, [users, orgTree]);

  const getUserAssignedPlants = (assigned: any) => {
    let plants: any = [];
    if (assigned[0].attributes) {
      Object.keys(assigned[0].attributes).map((key: any) => {
        plants.push(assigned[0].attributes[key].join());
      });
      dispatch(setUserAssignedPlants(plants));
    }
  };
};

export default useSetUserAssignedPlants;
