import { SettingsContant } from "../Config/constants/settingsConstant";
import ConfigService from "./ConfigService";


class SettingsService extends ConfigService {

    async getOldestTimestamp(): Promise<any> {
        const url: string = this.serverBaseUrl + SettingsContant.getOldestTimestampApi;
        try {
            return await fetch(url, { headers: this.headersList });
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getDeletionHistory(): Promise<any> {
        const url: string = this.serverBaseUrl + SettingsContant.getDeletionHistoryApi;
        try {
            const res = await fetch(url, { headers: this.headersList });
            return res;
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getSettings(): Promise<any> {
        const url: string = this.serverBaseUrl + SettingsContant.getSettingsApi;
        try {
            return await fetch(url, { headers: this.headersList });
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async updateSettings(settings: any): Promise<any> {
        const url: string = this.serverBaseUrl + SettingsContant.updateSettingsApi;
        try {
            const res = await fetch(url, {
                method: "POST",
                headers: this.headersList,
                body: JSON.stringify(settings)
            });
            return res.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async deleteData(settings: any): Promise<any> {
        const url: string = this.serverBaseUrl + SettingsContant.deleteDataApi;
        try {
            const res = await fetch(url, {
                method: "DELETE",
                headers: this.headersList,
                body: JSON.stringify(settings)
            });
            return res.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async saveDeletionHistory(settings: any): Promise<any> {
        const url: string = this.serverBaseUrl + SettingsContant.saveDeletionHistoryApi;
        try {
            const res = await fetch(url, {
                method: "POST",
                headers: this.headersList,
                body: JSON.stringify(settings)
            });
            return res.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }
}

export default SettingsService;