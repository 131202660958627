import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from "./App/App"
import reportWebVitals from './reportWebVitals';
import KeyCloakService from "./App/KeycloakService";
import { Provider } from "react-redux";
import { store } from './redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const renderApp = () => root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

KeyCloakService.CallLogin(renderApp);
reportWebVitals();