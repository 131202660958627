// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklistTreeStruct_tree_view__WUhqi {
  box-shadow: var(--theme-inset-shadow-1); }
`, "",{"version":3,"sources":["webpack://./src/UserManagement/Users/checklistTreeStruct.module.scss"],"names":[],"mappings":"AAAA;EACI,uCAAuC,EAAA","sourcesContent":[".tree_view {\n    box-shadow: var(--theme-inset-shadow-1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tree_view": `checklistTreeStruct_tree_view__WUhqi`
};
export default ___CSS_LOADER_EXPORT___;
