import { useState } from "react";

// Maintain a map of instances to ensure that the same instance is provided throughout the component tree
const instanceMap = new Map();

export function useInject(service: any, setIsLoading?: any) {

    const [instance] = useState(getInstance());

    function getInstance(): any {
        if (!service) {
            console.error('Service is not defined');
            return null;
        }
        // Check if an instance for this service already exists, and return it if available
        if (instanceMap.has(service)) {
            return instanceMap.get(service);
        }

        // If an instance does not exist, create a new one
        const newInstance = createServiceInstance();
        instanceMap.set(service, newInstance);
        return newInstance;
    }

    function createServiceInstance() {
        return new service(setIsLoading);
    }

    return instance;
}