import {IxDatePicker, IxIcon, IxInputGroup, IxTimePicker} from "@siemens/ix-react";
import {
    getCurrentDate,
    getFormattedDate, getFormattedTime,
} from "../../../utilities/converters";
import React, {useEffect, useRef, useState} from "react";
import {useDatePickerDetectClick} from "../../../hooks/useDatePickerDetectClick";

interface DateTimePickerInputsProps {
    type: string;
    field: any;
    datePlaceholder: string;
    timePlaceholder: string;
}

const DateTimePickerInputs = ({
                                  type,
                                  datePlaceholder,
                                  timePlaceholder,
                                  field,
                              }: DateTimePickerInputsProps) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [ixDateFormat, setIxDateFormat] = useState<string>('');
    const [formattedDate, setFormattedDate] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const startDatePickerInputRef = useRef<HTMLInputElement>(null);
    const startDatePickerRef = useRef<HTMLDivElement>(null);
    const startTimePickerInputRef = useRef<HTMLInputElement>(null);
    const startTimePickerRef = useRef<HTMLDivElement>(null);
    const [defaultValue] = useState(field?.value);

    useDatePickerDetectClick(startDatePickerInputRef, startDatePickerRef, setShowDatePicker);
    useDatePickerDetectClick(startTimePickerInputRef, startTimePickerRef, setShowTimePicker);

    useEffect(() => {
        const selectedTime = time ? time : getTimeBasedOnStartEndDate();
        const dateTime = {date: formattedDate, time: selectedTime};
        field.onChange(dateTime);
        field.onBlur();
    }, [formattedDate, time, type]);

    useEffect(() => {
        if (!defaultValue) {
            return;
        }
        const formattedDate = getFormattedDate(defaultValue);
        const formattedTime = getFormattedTime(defaultValue);
        setFormattedDate(formattedDate);
        setTime(formattedTime);
        if (formattedDate) {
            const ixDate = formattedDate.split('-');
            setIxDateFormat(ixDate[2] + '/' + ixDate[1] + '/' + ixDate[0]);
        }
    }, [defaultValue]);

    function getTimeBasedOnStartEndDate(): string {
        return type === 'startDate' ? '00:00:00' : '23:59:59';
    }

    return (
        <div className={`mb-2 mt-1 d-flex flex-row justify-content-between`}>
            <div className={`date_input`}>
                <IxInputGroup>
                    <input
                        className="form-control"
                        name={type}
                        value={formattedDate ? formattedDate : datePlaceholder}
                        type="text"
                        readOnly
                        onClick={() => setShowDatePicker(!showDatePicker)}
                        ref={startDatePickerInputRef}
                    />
                    <span slot="input-end" className={`input-end-icon`}>
                        <IxIcon name="calendar" size="16"/>
                    </span>
                </IxInputGroup>
                {
                    showDatePicker ? (
                        <div ref={startDatePickerRef} className={`mt-1 date_picker date_picker_center`}>
                            <IxDatePicker
                                from={ixDateFormat ? ixDateFormat : getCurrentDate()}
                                to={null} range={false}
                                maxDate={getCurrentDate()}
                                onDateSelect={(event) => {
                                    setShowDatePicker(false);
                                    setIxDateFormat(event?.detail?.from ? event?.detail?.from : getCurrentDate());
                                    setFormattedDate(
                                        getFormattedDate(event?.detail?.from ? event?.detail?.from : getCurrentDate())
                                    );
                                }}/>
                        </div>
                    ) : ''
                }
            </div>
            <div className={`time_input`}>
                <IxInputGroup>
                    <input
                        className="form-control"
                        type="text"
                        readOnly
                        value={time ? time : timePlaceholder}
                        onClick={() => setShowTimePicker(!showTimePicker)}
                        ref={startTimePickerInputRef}
                    />
                    <span slot="input-end" className={`input-end-icon`}>
                        <IxIcon name="clock" size="16"/>
                    </span>
                </IxInputGroup>

                {
                    showTimePicker ? (
                        <div ref={startTimePickerRef} className={`mt-1 date_picker date_picker_center`}>
                            <IxTimePicker
                                time={time ? time : getTimeBasedOnStartEndDate()}
                                textSelectTime={`Ok`}
                                onDone={(event) => {
                                    setShowTimePicker(false);
                                    setTime(event?.detail);
                                }}/>
                        </div>
                    ) : ''
                }
            </div>
        </div>
    );
}

export default DateTimePickerInputs;