import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HeatMap, InsightsState, ScatterPlot} from "../../Config/interfaces/InsightsInterface";

const initialState: InsightsState = {
    scatterPlots: [],
    heatMaps: [],
    parametersList: {}
}

const insightsSlice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        setScatterPlots: (state, action: PayloadAction<ScatterPlot[] | []>): void => {
            state.scatterPlots = action.payload;
        },
        addScatterPlot: (state, action: PayloadAction<ScatterPlot>): void => {
            state.scatterPlots = [action.payload, ...state.scatterPlots];
        },
        updateScatterPlot: (state, action: PayloadAction<ScatterPlot>): void => {
            const payload = action.payload;
            const payloadIndex = state.scatterPlots.findIndex(scatterPlot => scatterPlot.id === payload.id);
            state.scatterPlots[payloadIndex] = payload;
        },
        removeScatterPlot: (state, action: PayloadAction<number>): void => {
            state.scatterPlots = state.scatterPlots.filter((scatterPlot: ScatterPlot) => scatterPlot.id !== action.payload);
        },
        setHeatMaps: (state, action: PayloadAction<HeatMap[] | []>): void => {
            state.heatMaps = action.payload;
        },
        addHeatMap: (state, action: PayloadAction<HeatMap>): void => {
            state.heatMaps = [action.payload, ...state.heatMaps];
        },
        updateHeatMap: (state, action: PayloadAction<HeatMap>): void => {
            const payload = action.payload;
            const payloadIndex = state.heatMaps.findIndex(heatMap => heatMap.id === payload.id);
            state.heatMaps[payloadIndex] = payload;
        },
        removeHeatMap: (state, action: PayloadAction<number>): void => {
            state.heatMaps = state.heatMaps.filter((heatMap: HeatMap) => heatMap.id !== action.payload);
        },
        setParametersList: (state, action: PayloadAction<Record<number, string>>): void => {
            state.parametersList = action.payload;
        }
    }
});

export const {
    setScatterPlots,
    addScatterPlot,
    updateScatterPlot,
    removeScatterPlot,
    setHeatMaps,
    addHeatMap,
    updateHeatMap,
    removeHeatMap,
    setParametersList
} = insightsSlice.actions;
export default insightsSlice.reducer;