import { FC, useEffect, useState } from "react";
import ChecklistTreeStruct from "../Users/checklistTreeStruct";
import { IxButton, IxIcon, IxInputGroup, IxSelect, IxSelectItem } from "@siemens/ix-react";
import { useToastPosition } from "../../hooks/useToastPosition";
import { validateUserInputFields } from "../../utilities/validator";
import UsersService from "../../Services/UsersService";
import { useInject } from "../../hooks/useInject";
import { ToastMessage, ToastType } from "../../Config/constants/ToastMessage";
import { reload } from "../../utilities/utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setUsersProfile } from "../../redux/slices/userSlice";
import { ManagementType, PlantManagementActions, RoleManagementActions, UserManagementActions } from "../../Config/enums/RolesEnum";

const defaultFormValues = {
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    roles: [],
    userplants: []
};

const UserForm: FC<{
    closeDialog: () => void;
    userDrawerShow: boolean;
    onSaveNotification: (notification: any) => void;
    editMode: boolean;
    editId?: any;
    onUpdate?: (data: any) => void;
    usersProfile: any
}> = (props): JSX.Element => {
    const usersService = useInject(UsersService);
    const dispatch = useAppDispatch();
    const usersProfile = useAppSelector(state => state.userSlice.usersProfile);
    const rolesData = useAppSelector(state => state.roleSlice.roles);
    const realmID = useAppSelector(state => state.keycloakSlice.realmId);

    const [selectedPlants, setSelectedPlants] = useState([]);
    const [checklistTree, setChecklistTree] = useState(false);
    const [plantsArr, setPlantsArr] = useState([]);
    const [values, setValues] = useState<{ username: string; firstname: string; lastname: string; email: string; roles: string[]; userplants: never[]; }>(defaultFormValues);
    const loggedInUserRolePermissions = useAppSelector(state => state.roleSlice.loggedInUserPermissions);

    useToastPosition('top-right');

    const initializeSelectedUserData = () => {
        if (props.editId && props.editMode) {

            const selectedUserData: {
                username: string;
                firstname: string;
                lastname: string;
                email: string;
                roles: string[];
                userplants: never[];
            } = {
                username: '',
                firstname: '',
                lastname: '',
                email: '',
                roles: [],
                userplants: []
            }

            usersProfile.forEach((data: any) => {
                if (data.id === props.editId) {
                    selectedUserData.username = data.username;
                    selectedUserData.firstname = data.first_name;
                    selectedUserData.lastname = data.last_name;
                    selectedUserData.email = data.email;
                    selectedUserData.roles = data.roles;
                    selectedUserData.userplants = data.plants;
                    setSelectedPlants(data.plants?.split(","));
                }
            });

            setValues(selectedUserData);
            setChecklistTree(true);
        }
    }

    useEffect(() => {
        clearForm();
    }, [props.userDrawerShow]);

    useEffect(() => {
        initializeSelectedUserData();
    }, [props.editId, props.editMode]);


    /**
      * Function to show / hide the chekclist tree structure as per user interactions.
      *
      * @param None
    */
    const showCheckListTree = () => {
        setChecklistTree(!checklistTree);
    }

    /**
     * Function to get the plants checked by user and diplay it.
     *
     * @param plantsValue
     */
    const getPlantsArr = (plantsValue: any) => {
        values.userplants = plantsValue;
        setPlantsArr(plantsValue);
    }

    const notifyUser = (type: string, title: string, message: string) => {
        props.onSaveNotification({
            type,
            title,
            message
        });
    };

    const handleChange = (fieldName: string, val: string): void => {
        if (!fieldName) {
            return;
        }
        setValues({ ...values, [fieldName]: fieldName === 'roles' ? val.split(',') : val });
    }

    /**
   * Submit function to add the user details to keycloak DB
   *
   * @param None
  */
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const errorExists: boolean = validateUserInputFields(values, props.usersProfile, props.editMode);
        if (errorExists) {
            return;
        }

        let emailVerified: any;
        emailVerified = props.editMode;
        let userID = '';
        let roles: any[] = [];
        let attributes: any = {};

        plantsArr.forEach((element: any, index: any) => {
            attributes["plant_" + index] = [element]
        });
        let bodyContent = JSON.stringify({
            "username": values.username,
            "firstName": values.firstname,
            "lastName": values.lastname,
            "enabled": true,
            "emailVerified": emailVerified,
            "email": values.email,
            "attributes": attributes
        });

        let postRes = await usersService.addUpdateUser(bodyContent, props);
        if (postRes.status === 401) {
            notifyUser(ToastType.error, `Error`, `${ToastMessage.sessionExpired}. ${ToastMessage.loginAgain}`);
            reload();
        } else if (postRes.ok) {
            const action = props.editMode ? "Updated" : "Created";
            notifyUser(ToastType.success, `${action}`, `User ${values.firstname} ${values.lastname} ${action?.toLowerCase()} successfully`);
            clearForm();
            props.closeDialog();
        } else {
            notifyUser(ToastType.error, `Error`, "Something went wrong");
        }
        let updatedUserArray = [];
        updatedUserArray = await usersService.getUsersList(); // get users list once user is added
        // get select role id from the roles list
        const currentRolesArr = values.roles.map((roleName: string) => roleName.toLowerCase());
        rolesData.forEach((role: any) => {
            if (currentRolesArr.includes(role.name.toLowerCase())) {
                roles.push({ id: role.id, name: role.name });
            }
        });
        // find newly added user from the list (role is not assigned to the new user)
        const createdUserMatched = updatedUserArray.find((updatedUser: any) => {
            return updatedUser.username === values.username;
        });
        if (!createdUserMatched?.id) {
            return;
        }
        userID = createdUserMatched.id;
        await usersService.mapRealmDefaultRoles(userID);
        // remove existing role while updating user
        if (props.editMode) {
            let removeRoles: any[] = [];
            usersProfile.forEach((user: any) => {
                if (user.id === userID) {
                    const temp = user.roles.filter((role: any) => !currentRolesArr.includes(role?.toLowerCase()));
                    removeRoles =  rolesData.filter((role: any) => temp.includes(role.name))?.map((role: any) => {
                        const newRole = {...role};
                        delete newRole.permissions;
                        return newRole;
                    });
                }
            });
            await usersService.deleteTheExistingRole(userID, removeRoles, realmID);
        }
        // Assign selected role to the user
        await usersService.assignTheRoleToUser(userID, roles, realmID);
        notifyUser(ToastType.success, 'Role assigned', `"${[...values.roles]}" ${ToastMessage.rolesAssignedToUser}`);
        // Get updated users list
        const newUsersProfile = await usersService.getUsersProfile(realmID);
        newUsersProfile.length ? dispatch(setUsersProfile(newUsersProfile)) : dispatch(setUsersProfile([]));
        notifyUser(ToastType.success, 'Users list refreshed', '');
        // send email when user is created for the first time
        if (!props.editMode) {
            await usersService.sendEmailToUser(userID);
            notifyUser(ToastType.success, 'Email sent', ToastMessage.notifiedNewUserMessage);
        }
    }

    function clearForm(): void {
        setValues(defaultFormValues);
        setPlantsArr([]);
        setSelectedPlants([]);
        setChecklistTree(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={`form-inputs-block`}>
                <div className={`mt-2 text-default-title`}>User Details</div>
                <p className={`text-s`}>Enter the name & email address the user will use to sign in to this app</p>
                <div className={`d-flex flex-row justify-content-between mt-3`}>
                    <div>
                        <label htmlFor="username" className={`mb-1 text-default-title is_required`}>Username</label>
                        <input id="username" type="text" name="username"
                            placeholder='Enter unique username'
                            className={`form-control`}
                            value={values.username || ''}
                            disabled={props.editMode}
                            onChange={(e) => handleChange('username', e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="email" className={`mb-1 text-default-title`}>Email address</label>
                        <input id="email" type="text" name="email"
                            placeholder='Enter email address'
                            className={`form-control`}
                            value={values.email || ''}
                            onChange={(e) => handleChange('email', e.target.value)} />
                    </div>
                </div>
                <div className={`d-flex flex-row justify-content-between mt-3`}>
                    <div>
                        <label htmlFor="firstName" className={`mb-1 text-default-title is_required`}>First name</label>
                        <input id="firstName" type="text" name="firstname"
                            placeholder='Enter first name'
                            className={`form-control`}
                            value={values.firstname || ''}
                            onChange={(e) => handleChange('firstname', e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="lastName" className={`mb-1 text-default-title is_required`}>Last name</label>
                        <input id="lastName" type="text" name="lastname"
                            placeholder='Enter last name'
                            className={`form-control`}
                            value={values.lastname || ''}
                            onChange={(e) => handleChange('lastname', e.target.value)} />
                    </div>
                </div>
                <div className={`mt-3`}>
                    <label htmlFor="roleSelect" className={`mb-1 text-default-title is_required`}>Role name</label>
                    <p className={`text-s`}>Determine the permission that a user has for the organization selected above by assigning them a role.</p>
                    <IxSelect id="roleSelect" mode="multiple" hideListHeader selectedIndices={values.roles.map(role => role)}
                        onItemSelectionChange={(e) => handleChange('roles', e.detail.toString())}
                        disabled={!rolesData?.length || !(loggedInUserRolePermissions[ManagementType.User][UserManagementActions.Add] && loggedInUserRolePermissions[ManagementType.Roles][RoleManagementActions.Add])}>
                        {
                            rolesData && rolesData.length > 0 && (
                                rolesData.map((role: any, index: any) => (
                                    <IxSelectItem key={index} label={role.name} value={role.name}></IxSelectItem>)
                                )
                            )
                        }
                    </IxSelect>
                </div>
                <div className={`mt-3`}>
                    <label htmlFor="plantSelect" className={`mb-1 text-default-title`}>Plants List</label>
                    <p className={`text-s`}>Select the orgs, sub-orgs and plants to assign to user</p>
                    <IxInputGroup onClick={() => showCheckListTree()}>
                        <input name="userplants" id="plantSelect"
                            className="form-control"
                            placeholder="Select Organisation"
                            type="text" value={plantsArr} readOnly />
                        <span slot="input-end"><IxIcon name="chevron-down-small"></IxIcon></span>
                    </IxInputGroup>
                    {
                        checklistTree && (
                            <div>
                                <ChecklistTreeStruct setPlantsDataArr={getPlantsArr} selectedPlants={selectedPlants} />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={`m-2 py-2`}>
                <div className={`d-flex flex-row justify-content-center`}>
                    <IxButton class="m-1" outline onClick={() => {
                        clearForm();
                        props.closeDialog();
                    }}>
                        Cancel
                    </IxButton>
                    <IxButton type="submit" class="m-1" variant="Primary">
                        Save
                    </IxButton>
                </div>
            </div>
        </form>
    );

};

export default UserForm;