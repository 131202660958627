import { useState, useEffect } from "react";
import {useInject} from "./useInject";
import ConfigService from "../Services/ConfigService";

export default function useFetch(setData: Function, url: string) {
    const configService = useInject(ConfigService);
    let [isLoading, setIsloading] = useState(false),
        [error, setError] = useState(null);
    useEffect(() => {
        setIsloading((prevState: any): any => {
            isLoading = !prevState;
        });
        fetch(url, {
            headers: configService.headersList
        })
            .then((res) => {
                if (res.ok) {
                    res
                        .json()
                        .then((res) => {
                            setIsloading((prevState: any): any => {
                                isLoading = !prevState;
                            });
                            setError(null);
                            setData(res);
                        })
                        .catch(() => {
                            setError(error);
                        });
                } else {
                    setError(error);
                }
            })
            .catch((error) => {
                setError(error);
            });
    }, []);
    return { isLoading, error };
}