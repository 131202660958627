import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect } from "react";
import { setSelectedPlant, setSelectedPlantId, setSelectedPlantStatus } from "../redux/slices/plantSlice";

export default function useSetPlant() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedPlantId = useAppSelector(state => state.plantSlice.selectedPlantId);

    useEffect(() => {
        const plantId = localStorage.getItem("plantId");
        const plantName = localStorage.getItem("plantName");
        const plantStatus = localStorage.getItem("plantStatus");
        if (!plantId || !plantName) {
            navigate('/');
            return;
        }
        dispatch(setSelectedPlantId(+plantId));
        dispatch(setSelectedPlant(plantName));
        dispatch(setSelectedPlantStatus(plantStatus === "true"));
    }, [selectedPlantId]);
}