import { Categories, KpiFormInterface } from "../../Config/interfaces/KpiInterface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KpisConstant } from "../../Config/constants/KpisConstant";

interface KpiState {
    categories: Categories[];
    selectedPlantIdForKpiForm: number | null
    kpisForm: KpiFormInterface[];
}

const initialState: KpiState = {
    categories: [],
    selectedPlantIdForKpiForm: null,
    kpisForm: [KpisConstant.initialValues]
}

const KpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        setCategories(state, action: PayloadAction<Categories[]>): void {
            state.categories = action.payload;
        },
        setSelectedPlantIdForKpiForm(state, action: PayloadAction<number>): void {
            state.selectedPlantIdForKpiForm = action.payload;
        },
        setKpisForm(state, action: PayloadAction<KpiFormInterface[]>): void {
            state.kpisForm = action.payload;
        },
        removeFromKpiForm(state, action: PayloadAction<number>): void {
            state.kpisForm = state.kpisForm.filter((kpi: KpiFormInterface) => +kpi.kpiId !== action.payload);
        }
    }
});

export const {
    setCategories,
    setKpisForm,
    setSelectedPlantIdForKpiForm,
    removeFromKpiForm
} = KpiSlice.actions;
export default KpiSlice.reducer;