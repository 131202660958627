import { FC } from "react";

const Input: FC<{
  name: string;
  type: string;
  placeholder: string;
  readOnly: boolean;
  field: any;
}> = ((props) => {

  return (
    <>
      <input
        className="form-control"
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        value={props.field.value}
        onChange={props.field.onChange}
        onBlur={props.field.onBlur}
        readOnly={props.readOnly}
        spellCheck="false"
        autoComplete="off"
      />
    </>
  );
});

export default Input;
