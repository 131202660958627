// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listnavigation_kpi_list__O2Elz {
  height: 70vh;
  overflow: auto; }

.listnavigation_selected__EgGf6 {
  background-color: var(--theme-kpi-display--background--active); }
`, "",{"version":3,"sources":["webpack://./src/Detailedview/Listnavigation/listnavigation.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,cAAc,EAAA;;AAGlB;EACI,8DAA8D,EAAA","sourcesContent":[".kpi_list {\n    height: 70vh;\n    overflow: auto;\n}\n\n.selected {\n    background-color: var(--theme-kpi-display--background--active);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kpi_list": `listnavigation_kpi_list__O2Elz`,
	"selected": `listnavigation_selected__EgGf6`
};
export default ___CSS_LOADER_EXPORT___;
