import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setKpisForecastData, setPlantKpis, setSelectedPlantId } from "../redux/slices/plantSlice";
import { useInject } from "./useInject";
import PlantKpiService from "../Services/PlantKpiService";
import TimeSeriesService from "../Services/TimeSeriesService";
import { PlantKpisDataInterface } from "../Config/interfaces/TimeseriesInterface";
import { KpisConstant } from "../Config/constants/KpisConstant";
import usePollKpisLatestDataOnInterval from "./usePollKpisLatestDataOnInterval";
import usePushDataIntoPlantKpis from "./usePushDataIntoPlantKpis";
import usePollForecastDataOnInterval from "./usePollForecastDataOnInterval";

const useGetKpisInitialData = (): void => {
	const dispatch = useAppDispatch();
	const plantKpiService = useInject(PlantKpiService);
	const timeSeriesService = useInject(TimeSeriesService);
	const selectedPlantId = useAppSelector((state) => state.plantSlice.selectedPlantId);
	const plantKpis = useAppSelector((state) => state.plantSlice.plantKpis);
	const [kpisData, setKpisData] = useState<Record<number, PlantKpisDataInterface[]>>({});

	usePollKpisLatestDataOnInterval(setKpisData);
	usePollForecastDataOnInterval();
	usePushDataIntoPlantKpis(kpisData);

	useEffect(() => {
		getPlantKpis().then((r) => r);
	}, [selectedPlantId]);

	const getPlantKpis = async (): Promise<void> => {
		// Plant kpis are cleared before coming back here from navigation
		//  so length of kpis should be empty array mostly.
		// if length is not cleared before navigation then do nothing here
		if (plantKpis.length) {
			return;
		}
		if (!selectedPlantId || isNaN(selectedPlantId)) {
			const plantId = Number(localStorage.getItem("plantId"));
			if (!plantId) {
				return;
			}
			dispatch(setSelectedPlantId(plantId));
			return;
		}

		const response = await plantKpiService.getPlantKpi(selectedPlantId);
		response?.length ? dispatch(setPlantKpis(response)) : dispatch(setPlantKpis([]));
		await getKpisInitialData(KpisConstant.kpiRowNumber);
	};

	const getKpisInitialData = async (rowNum: number = 1) => {
		if (!selectedPlantId) {
			return;
		}

		const kpiResponse: { kpisData: Record<number, PlantKpisDataInterface[]> } = await timeSeriesService.getKpisData(
			selectedPlantId,
			rowNum
		);
		setKpisData(kpiResponse?.kpisData);

		const kpiForecastResponse: { forecastData: Record<number, PlantKpisDataInterface[]> } =
			await timeSeriesService.getKpisForecastData(selectedPlantId);
		dispatch(setKpisForecastData(kpiForecastResponse?.forecastData ?? {}));
	};
};

export default useGetKpisInitialData;
