import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Chart } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

export default function Linechart({ data, isVoilated }: { data: any, isVoilated: any }): JSX.Element {

  let geteConfigurations = (): { data: any; options: any } => {
    return {
      data: {
        labels: data
          ? data.length > 15
            ? data.slice(data.length - 15, data.length)
            : data
          : [],
        datasets: [
          {
            label: "",
            data: data
              ? data.length > 15
                ? data.slice(data.length - 15, data.length)
                : data
              : [],
            fill: true,
            backgroundColor: getGradientColor(isVoilated),
            borderColor: isVoilated ? "#E31A1C" : " #238443",
            borderWidth: 1,
            tension: 0,
          },
        ],
      },
      options:
      {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins:
        {
          legend: {
            display: false,
          }
        },
        title: {
          display: false,
        },
        scales: {
          x: {

            ticks: {
              display: false,
            },
            grid: {
              drawBorder: false,
              display: false,
            },
          },

          y:
          {
            ticks: {
              display: false,
              beginAtZero: true,
            },
            grid: {
              drawBorder: false,
              display: false,
            },
          },

        },

      },
    };
  },
    getGradientColor = (limitVoilations: boolean) => {
      let canvas = document.createElement("canvas").getContext("2d");
      let gradient;
      gradient = canvas!.createLinearGradient(0.1, 0.1, 0, 100);
      if (limitVoilations) {
        gradient.addColorStop(0, "rgba(255, 0,0, 0.7)");
        gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.20)");
        gradient.addColorStop(1, "rgba(255, 0, 0, 0)");
      } else {
        gradient.addColorStop(0, "rgba(0, 255,0, 0.5)");
        gradient.addColorStop(0.5, "rgba(0, 255, 0, 0.20)");
        gradient.addColorStop(1, "rgba(0, 0, 255, 0)");
      }
      return gradient;
    };

  return <Chart type='line' {...geteConfigurations()} />;
}
