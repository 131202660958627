import ConfigService from "./ConfigService";

class AlarmService extends ConfigService {

  constructor() {
    super();
  }

  async fetchAlarms(plantId: string | number): Promise<any> {
    if (!plantId) {
      return [];
    }

    const url: string = this.serverBaseUrl + "/api/alarms/" + plantId;
    try {      
      const response = await fetch(url, {
        headers: this.headersList,
      });

      return await response.json();
    } catch (err) {
      console.error('API call failed: ', err);
    }
  }
}

export default AlarmService;
