import ConfigService from "./ConfigService";

class TimeseriesService extends ConfigService {
    async getKpisData(plantId: number, rowNum: number): Promise<any> {
        if (!plantId) {
            return;
        }

        const url: string = this.serverBaseUrl + '/api/getKpisData/' + plantId + '/' + rowNum;

        try {
            const response = await fetch(url, {
                headers: this.headersList
            });
            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getHistoricalDataForReport(startDate: string, endDate: string, attrIds: number[]): Promise<any> {
        const url: string = this.serverBaseUrl + '/api/historicalDataReport?' + new URLSearchParams({
            startDate: startDate,
            endDate: endDate,
            attrIds: JSON.stringify(attrIds)
        });
        try {
            const response = await fetch(url, {headers: this.headersList});
            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }

    async getKpisForecastData(plantId: number) {
        if (!plantId) {
            return [];
        }

        const url: string = this.serverBaseUrl + '/api/getForecastData/' + plantId;
        try {
            const response = await fetch(url, {headers: this.headersList});
            return await response.json();
        } catch (err) {
            console.error('API call failed: ', err);
        }
    }
}
export default TimeseriesService;
