import { useEffect, useState, useRef } from "react";
import { IxTabItem, IxTabs, IxIconButton, showModal, IxTooltip, IxSpinner } from "@siemens/ix-react";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ExportKPIModal from "../ExportKPIs/ExportKPIModal";
import { getFormattedStartEndDatesTimestamp } from "../../utilities/converters";
import PlantKpiService from "../../Services/PlantKpiService";
import {useInject} from "../../hooks/useInject";
import React from "react";
import { getCurrentTimestamp } from "../../utilities/converters";
import { setSelectedCategoryId } from "../../redux/slices/plantSlice";
import { setCategories } from "../../redux/slices/plantSlice";
import KpiService from "../../Services/KpiService";
import { Categories } from "../../Config/interfaces/KpiInterface";
import styles from './Tabs.module.scss';

const Tabs = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const plantKpiService = useInject(PlantKpiService);
  const kpiService = useInject(KpiService, setIsLoading);
  const categories: Categories[] = useAppSelector(state => state.plantSlice.categories);
  const plantKpis = useAppSelector(state => state.plantSlice.plantKpis);
  const selectedPlantId = useAppSelector(state => state.plantSlice.selectedPlantId);
  const [selectedTab, setSelectedTab] = useState(0);
  const [downloadLink, setDownloadLink] = useState<string | null>(null); // State to manage download link

  useEffect(() => {
    getTabCategories().then(r => r);
  }, [selectedPlantId]);

  const changeTab = (tabId: number, category: any) => {
    setSelectedTab(tabId);
    dispatch(setSelectedCategoryId(category?.id));
  };

  const getTabCategories = async () => {
    if (!selectedPlantId) {
      return;
    }
    const categories = await kpiService.getKpiCategories(selectedPlantId);
    dispatch(setCategories(categories?.length ? categories : []));
    dispatch(setSelectedCategoryId(categories?.length ? categories[0]?.id : 0));
  }

  const exportHandler = (actionStatus: boolean, startDateTime: {date:string, time:string}, endDateTime: {date:string, time:string}, selectedKPIIds: number[]) => {
    if (!actionStatus) {
        return;
    }
    
    const startEndDateTimestamps : any = getFormattedStartEndDatesTimestamp(
      startDateTime,
      endDateTime
    );

    exportData(startEndDateTimestamps, selectedKPIIds);
  }

  const exportData = async (dateRange: any, attrIds: number[]) => {
    try {
        const [startDate, endDate] = dateRange;
        const response = await fetch(`${plantKpiService.serverBaseUrl}/api/historicalDataReport?` + new URLSearchParams({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            attrIds: JSON.stringify(attrIds),
            deviceId: selectedPlantId.toString()
        }), { headers: plantKpiService.headersList });

        if (!response.ok) {
            throw new Error(`Failed to fetch data: ${response.statusText}`);
        }

        const result = await response.json();
        if (!Array.isArray(result) || result.length === 0) {
            throw new Error("No data received or data format is incorrect.");
        }

        // Initialize an object to store the formatted data
        let formattedDataForExcel: { [timestamp: string]: { [attribute: string]: number } } = {};

        // Iterate over the result and group data by timestamp
        result.forEach(([attributeName, time, value]: [string, string, number]) => {
              const formattedTime = new Date(time).toLocaleString();
              if (!formattedDataForExcel[formattedTime]) {
                              formattedDataForExcel[formattedTime] = {};
              }
              formattedDataForExcel[formattedTime][attributeName] = value;
        });

        exportToCSV(formattedDataForExcel);
    } catch (error) {
        console.error("Error exporting data:", error);
        throw error; 
    }
  }

  const exportToCSV = (formattedDataForExcel: any) => {
    // Extract headers from the first entry in formattedDataForExcel
    // Extract all attributes from formattedDataForExcel
    const allAttributes = Object.values(formattedDataForExcel).reduce((acc: string[], entry: any) => {
      return acc.concat(Object.keys(entry));
    }, []);

    // Add "Timestamp" as the first header
    const headers = ['Timestamp', ...Array.from(new Set(allAttributes))];

    // Generate CSV content
    let csvContent = headers.join(',') + '\n'; // Headers row
    Object.entries(formattedDataForExcel).forEach(([timestamp, attributes]: [string, any]) => {
      const rowData = [`"${timestamp}"`];
      headers.slice(1).forEach(header => {
          const value = attributes[header] !== undefined ? attributes[header] : 0;
          rowData.push(String(value));
      });
      csvContent += rowData.join(',') + '\n'; 
    });

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const downloadUrl = window.URL.createObjectURL(blob);

    // // Set the download link
    setDownloadLink(downloadUrl);
    
    console.log('CSV file exported successfully.');
  }

  useEffect(() => {
    if (downloadLink) {
      // Trigger download and show notification
      const link = document.createElement('a');
      link.href = downloadLink;
      link.download = `exportedData_${getCurrentTimestamp()}.csv`;
      link.click();

      // Clean up
      setDownloadLink(null);
    }
  }, [downloadLink]);

  const onClickExport = async() : Promise<void> => {
      await showModal({
        title: 'exportModalDialog',
        content: <ExportKPIModal onAction={exportHandler} plantKPIs={plantKpis}/>,
        size: 'lg'
    });
  }

  return (
    <>
      <section>
        {
            isLoading && <IxSpinner className={`vertical-center`} size={'large'}></IxSpinner>
        }
      </section>
      <section className={`${styles.tabBox} d-flex flex-row justify-content-between align-items-center`}>
            <section className={`w-75 d-flex justify-content-between align-items-center`}>
              <div>
                {
                  !!categories.length && (
                    <IxTabs selected={selectedTab}>
                      <>
                        {
                          categories?.map((category: any, index: number) => {
                            return (
                              <IxTabItem key={category?.id} onClick={() => changeTab(index, category)}>{category.name}</IxTabItem>
                            )
                          })
                        }
                      </>
                    </IxTabs>
                  )
                }
              </div>
              <div className={`me-3`}>
                <IxIconButton class="m-1 export-kpis-data" icon="export" variant="Primary" onClick={() => onClickExport()}>Export</IxIconButton>
                <IxTooltip for=".export-kpis-data" placement="left">Export</IxTooltip>
              </div>
            </section>
      </section>
    </>
  );
};

export default Tabs;
